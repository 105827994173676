import { useEffect, useRef } from 'react';
import { Animated, Easing, Pressable, StyleSheet } from 'react-native';
import type { ChildrenProp, ViewStyle } from '../../types/components';
import { View } from '../View';
import { getScreenHeight, getScreenWidth } from '../../utils/utils.ui';
import { useModal } from './Modal.Provider';
import { modalStyles } from './Modal.styles';
import { useAppTheme, useColorFromTheme } from '../../theme';
import { isNativeMobile } from '../../utils/utils.helper';
import { ModalPopupContainer } from './Modal.Container';

export interface ModalBackdropProps {
  modalId: string;
  onClose: () => void;
  type: 'pop-up' | 'action-sheet';
  backdropStyle?: ViewStyle;
  modalStyle?: ViewStyle;
}

export const ModalBackdrop = ({
  children,
  modalId,
  onClose,
  type,
  backdropStyle,
  modalStyle,
}: ChildrenProp & ModalBackdropProps) => {
  const opacity = useRef(new Animated.Value(0)).current;
  const canPress = useRef(false);
  const { closeModal, modals } = useModal();
  const { components } = useAppTheme();
  const modalPopupStyle = components.modalPopup;
  const backdropColor = useColorFromTheme('backdrop');
  useEffect(() => {
    opacity.setValue(0);
    Animated.timing(opacity, {
      toValue: 1,
      duration: 150,
      useNativeDriver: true,
      easing: Easing.in(Easing.linear),
    }).start(() => {
      window.setTimeout(() => {
        canPress.current = true;
      }, 20);
    });
  }, [opacity]);

  const isTopModal = modals[modals.length - 1]?.key === modalId;

  return (
    <View
      style={[
        type === 'pop-up' && modalStyles.popupWrapper,
        type === 'action-sheet' && modalStyles.actionSheetWrapper,
        // type === "dropdown" && modalStyles.popupWrapper,
        backdropStyle,
      ]}
    >
      <Pressable
        accessibilityRole="button"
        style={modalStyles.pressable}
        onPress={() => {
          if (canPress.current) {
            closeModal(modalId);
            onClose?.();
          }
        }}
      >
        <Animated.View
          style={[
            {
              ...StyleSheet.absoluteFillObject,
              opacity: opacity.interpolate({
                inputRange: [0, 1],
                outputRange: [0, 0.6],
              }),
              backgroundColor: isTopModal ? backdropColor : undefined,
            },
          ]}
        />
      </Pressable>
      {type === 'pop-up' ? (
        isNativeMobile ? (
          <View
            style={[{ height: getScreenHeight(), width: getScreenWidth() }]}
          >
            <Pressable
              style={{ flex: 1 }}
              onPress={() => {
                if (canPress.current) {
                  closeModal(modalId);
                  onClose?.();
                }
              }}
            />
            <ModalPopupContainer>{children}</ModalPopupContainer>
            <Pressable
              style={{ flex: 1 }}
              onPress={() => {
                if (canPress.current) {
                  closeModal(modalId);
                  onClose?.();
                }
              }}
            />
          </View>
        ) : (
          <View
            style={[
              modalStyles.modal,
              modalPopupStyle,
              {
                maxHeight: window.innerHeight,
                maxWidth: window.innerWidth,
              },
              modalStyle,
            ]}
          >
            {children}
          </View>
        )
      ) : (
        <Animated.View
          style={[
            {
              transform: [
                {
                  translateY: opacity.interpolate({
                    inputRange: [0, 1],
                    outputRange: [getScreenHeight(), 0],
                  }),
                },
              ],
            },
            modalStyle,
          ]}
        >
          {children}
        </Animated.View>
      )}
    </View>
  );
};
