import { useAppTheme } from "../../theme";
import type { ChildrenProp } from "../../types/components";
import { HStack, StackProps } from "../Stack";

export interface ModalHeaderProps extends ChildrenProp {
  onPress?: () => void;
  title: string;
  subtitle?: string;
  divider?: boolean;
}

export const ModalHeaderContainer = (props: StackProps) => {
  const modalHeaderProps = useAppTheme().components.modalHeader;
  return (
    <HStack
      style={[{ alignSelf: "stretch" }, props.style]}
      {...modalHeaderProps.container}
      {...props}
    />
  );
};
