import { isWeb } from "../../utils/utils.helper";
import { getScreenHeight } from "../../utils/utils.ui";
import { AppViewProps, View } from "../View";

export const ModalPopupContainer = (props: AppViewProps) => {
  if (isWeb) {
    return <>{props.children}</>;
  }

  return (
    <View
      bg="white"
      br={12}
      style={[
        {
          maxHeight: getScreenHeight() * 0.9,
        },
        // isWeb && {
        //   maxHeight: window.innerHeight * 0.9,
        //   maxWidth: window.innerWidth * 0.7,
        // },
        props.style,
      ]}
      {...props}
    />
  );
};
