import { MarkerI } from "./Maps.types";

export function getInitialRegion(markers?: MarkerI[], scale = 2) {
  if (!markers || markers.length === 0) {
    return {
      latitude: 37.9838,
      latitudeDelta: 0.03,
      longitude: 23.7275,
      longitudeDelta: 0.03,
    };
  }
  const latitudeArray = markers.map((marker) => marker.lat);
  const longitudeArray = markers.map((marker) => marker.lng);
  const minLat = Math.min(...latitudeArray);
  const maxLat = Math.max(...latitudeArray);
  const minLong = Math.min(...longitudeArray);
  const maxLong = Math.max(...longitudeArray);
  return {
    latitude: minLat + (maxLat - minLat) / 3,
    latitudeDelta: Math.max((maxLat - minLat) * scale, 0.03),
    longitude: minLong + (maxLong - minLong) / 2,
    longitudeDelta: Math.max((maxLong - minLong) * scale, 0.03),
  };
}
