import { Pressable } from 'react-native';
import { useAppTheme } from '../../../theme';
import { Icon } from '../../Icon/Icon';
import { HStack } from '../../Stack';
import { Text } from '../../Text';
import { IconButton } from '../../Button';

export const SelectItem = ({
  children,
  onRemove,
  single,
  optional,
}: {
  children: string;
  onRemove?: () => void;
  single?: boolean;
  optional?: boolean;
}) => {
  const { spacing } = useAppTheme();
  const themeStyle = useAppTheme().components.multiSelectItem;

  if (single) {
    return (
      <HStack space="1" flex={1}>
        <Text.Body2Regular numberOfLines={1} style={{ flex: 1 }}>
          {children}
        </Text.Body2Regular>
        {onRemove && children && optional ? (
          <IconButton icon="close" color="monochrome" onPress={onRemove} />
        ) : null}
      </HStack>
    );
  }

  return (
    <Pressable
      onPress={onRemove}
      disabled={!onRemove}
      style={{
        marginVertical: spacing[1],
      }}
    >
      <HStack style={[themeStyle]} space="1">
        <Text.Body2Medium
          style={{ color: themeStyle?.color }}
          numberOfLines={single ? 1 : undefined}
        >
          {children}
        </Text.Body2Medium>
        {onRemove ? (
          <Icon
            icon="close"
            color="primary-mid"
            iconStyle={{ marginRight: -8 }}
          />
        ) : null}
      </HStack>
    </Pressable>
  );
};
