import type { FormValidationRegisterProp } from './Form.types';
import type { FormBuilderInputProps } from './FormBuilder/FormBuilder.types';

export const filterFormInputs = (
  inputs: FormValidationRegisterProp<any>[],
  keysToFetch: string[] = []
) => {
  return inputs.filter((input) => {
    if (input.id.startsWith('blank')) {
      return false;
    }

    if (input.type === 'blank') {
      return false;
    }

    return keysToFetch.length
      ? keysToFetch.some((keyToFetch) => keyToFetch === input.id)
      : true;
  });
};

export const getFormInputProps = <T>(
  props: FormBuilderInputProps<T, any> & { feedback: string }
): {
  disabled?: boolean;
  optional?: boolean;
  readonly?: boolean;
  label?: string;
  allowEmpty?: boolean;
  error?: boolean;
} => {
  return {
    disabled: props.disabled,
    error: !!props.feedback,
    label: props.config.label,
    optional: props.config.optional,
    readonly: props.readonly || props.config.readonly,
    allowEmpty: props.config.allowEmpty,
  };
};
