import React from "react";
import { ModalBackdrop } from "./Modal.Backdrop";
import type { ModalProps } from "./Modal.types";

export const ModalPopup = (props: ModalProps) => {
  return (
    <ModalBackdrop {...props} type="pop-up">
      {props.children}
    </ModalBackdrop>
  );
};
