import { useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';

import { t } from '../../../lang/lang';
import { inputHeight, useAppTheme } from '../../../theme';
import { isWeb } from '../../../utils/utils.helper';
import { InputButton } from '../../Button/Button.Input';
import type { ButtonInteractionState } from '../../Button/Button.types';
import { Icon } from '../../Icon/Icon';
import { LoadingIndicator } from '../../Loading';
import { HStack, VStack } from '../../Stack';
import { Text } from '../../Text';
import { showToast } from '../../Toast/Toast';
import { View } from '../../View';
import { useFileDragUploader } from './DocumentPicker.hook';
import type {
  DocumentPickerStateItem,
  FilePickerProps,
} from './DocumentPicker.types';
import { getFileFromFormData, onFilePicker } from './DocumentPicker.utils';

export const DocumentPicker = ({
  files,
  maxFileSize,
  deletable = false,
  error,
  showPreview = true,
  style,
  children,
  disabled,
  uploadFile,
  onRemoveFile,
}: FilePickerProps) => {
  const ref = useRef({
    nativeID: new Date().valueOf() + '',
  });

  const { palette } = useAppTheme();
  const [loadingStateFiles, setLoadingFiles] = useState<string[]>([]);

  const stopLoadingFile = (id: string) => {
    setLoadingFiles(loadingStateFiles?.filter((fileId) => fileId !== id));
  };

  const addFile = async (formData: FormData) => {
    const file = getFileFromFormData(formData);
    if (file) {
      if (typeof maxFileSize === 'number') {
        if (maxFileSize < file.size) {
          showToast(t('app.feedback.lessThanSize', { size: '5mb' }));
          return;
        }
      }
      setLoadingFiles([...loadingStateFiles, file.name]);
      await uploadFile(formData);

      stopLoadingFile(file.name);
    }
  };

  useEffect(() => {
    setLoadingFiles(
      loadingStateFiles.filter((name) => !files?.some((f) => f.name === name))
    );
  }, [files]);

  const { draggedOver } = useFileDragUploader({
    id: ref.current.nativeID,
    files: files || [],
    addFile,
  });

  const serverFiles: DocumentPickerStateItem[] = (files || []).map((f) => ({
    file: f,
    loading: false,
  }));
  const loadingFiles: DocumentPickerStateItem[] = loadingStateFiles.map(
    (name) => ({ file: { name, id: '' }, loading: true })
  );

  const documentList: DocumentPickerStateItem[] = [
    ...serverFiles,
    ...loadingFiles,
  ];

  const onPressPicker = () => onFilePicker({ addFile, files: files || [] });
  return (
    <VStack space="3" flex={1}>
      {documentList?.length && showPreview ? (
        <VStack
          row={isWeb}
          style={[isWeb && { flexWrap: 'wrap' }]}
          space="2"
          flex={1}
        >
          {documentList.map(({ file, loading }, ix) => {
            return (
              <Pressable
                disabled={deletable || loading}
                key={ix + file.name}
                onPress={() => {
                  onRemoveFile?.(file);
                }}
              >
                {({ hovered }: ButtonInteractionState) => {
                  return (
                    <VStack
                      flex={1}
                      space="1"
                      align="center"
                      justify="center"
                      p="3"
                      mb="1"
                      b={1}
                      bc={hovered ? 'primary-light' : 'monochrome-light'}
                      br={16}
                      style={[
                        { position: 'relative' },

                        hovered && { borderStyle: 'dashed' },
                      ]}
                    >
                      <View
                        style={{
                          position: 'absolute',
                          top: 0,
                          right: 0,
                          zIndex: 1,
                        }}
                      >
                        {hovered && !loading ? (
                          <Icon icon="close" color="error-mid" size="large" />
                        ) : null}
                      </View>

                      {loading ? (
                        <LoadingIndicator />
                      ) : (
                        <Icon
                          icon={hovered ? 'bin' : 'result'}
                          color={hovered ? 'primary-light' : 'monochrome-light'}
                          size="large"
                        />
                      )}
                      <Text.Small
                        color={hovered ? 'primary-mid' : 'monochrome-extraDark'}
                        align="center"
                      >
                        {file.name}
                      </Text.Small>
                    </VStack>
                  );
                }}
              </Pressable>
            );
          })}
        </VStack>
      ) : null}
      <View nativeID={ref.current.nativeID}>
        {children ? (
          <Pressable disabled={disabled} onPress={() => onPressPicker()}>
            {children}
          </Pressable>
        ) : (
          <InputButton
            disabled={disabled}
            onPress={() => onPressPicker()}
            style={[
              {
                borderStyle: 'dashed',
                flex: 1,
                minHeight: inputHeight,
                maxHeight: inputHeight * 2,
              },
              isWeb && { minWidth: 140, minHeight: 100 },
              error && {
                borderColor: palette.error.dark,
              },
              draggedOver && {
                borderStyle: 'solid',
                borderColor: palette.primary.mid,
              },
              style,
            ]}
          >
            <HStack
              flex={1}
              justify="center"
              // TODO
              pointerEvents={isWeb ? 'none' : 'auto'}
              // style={[isWeb && { pointerEvents: "none" }]}
            >
              <Icon
                icon="plus"
                color={draggedOver ? 'primary-mid' : 'monochrome-light'}
              />
            </HStack>
          </InputButton>
        )}
      </View>
    </VStack>
  );
};
