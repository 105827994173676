import {
    Optional,
    RoutePetValidation,
    RoutePricePetData,
    RoutePricePetV2,
    TripPricePetAccommodation,
} from '@naus-code/naus-client-types';
import { createError } from '../errors';
import { PetDetailsUpdatePriceReq, PetSelectTicketReq } from '../types/providers';
import { BasketManagerPassengerTickets } from './bm.6.pas.2.pricing';
import { ClientBasketState } from '../types/clientState';

export class BasketManagerPetTickets extends BasketManagerPassengerTickets {
    //

    private getTripPetTicket = (options: { tripKey: string; ticketCode: string }) => {
        const tripDic = this.getTripDictionary(options.tripKey);
        const ticketAcc = tripDic.petAccDic[options.ticketCode];
        if (!ticketAcc) {
            throw createError({ code: 'PASSENGER_TICKET_CODE_NOT_FOUND' });
        }
        return ticketAcc;
    };

    //---------------CHANGE_TICKET---------------//
    /**
     * Function to choose a ticket for a pet.
     * If extra information is required such as pet details or other pets to join
     * the functions passed to callbacks will be triggered.
     *
     * @options_callbacks_onAdditionalInfoRequired if the ticket requires extra information from
     * the user for the pet they are changing, this callback will be triggered and the props
     * will include the information that is missing and required as FieldInput[]
     */
    selectPetTicket = async (options: PetSelectTicketReq) => {
        const bmState = this.getBmState();
        const petData = this.petGetPetRoutePrice(options.petId);
        const ticketAcc = this.getTripPetTicket(options);

        const additionalInfo = this.getRequiredPetExtraFields(
            petData,
            ticketAcc.extraPetValidation,
        );

        if (additionalInfo.length) {
            const newFields = await options.callBacks.onAdditionalInfoRequired({
                fields: additionalInfo,
            });
            if (!newFields) {
                return;
            }
            if (newFields.additionalInfo.length !== additionalInfo.length) {
                //missing information
                return;
            }
            this.updatePetDetailsForPrice({
                petId: petData.id,
                //@ts-expect-error Field name not string
                data: newFields.additionalInfo,
                state: options.state,
            });
            //Add fields to pet data
            const petWithUpdatedFields = bmState.routePrice.pets[petData.index];

            return this.changeTicketCodeForPet(
                petWithUpdatedFields,
                options.tripKey,
                options.ticketCode,
                { state: options.state },
            );
        }

        //Change pet ticket
        this.changeTicketCodeForPet(petData, options.tripKey, options.ticketCode, {
            state: options.state,
        });
        this.dispatchPrices();
    };

    private getRequiredPetExtraFields = (
        petData: RoutePricePetV2,
        validation: RoutePetValidation | undefined,
    ) => {
        const additionalInfo = this.petGetAdditionalDetails(petData, validation);
        return additionalInfo.fieldOptionsArray.filter((item) => item.required);
    };

    private changeTicketCodeForPet = (
        petData: RoutePricePetV2,
        tripKey: string,
        ticketCode: string,
        options?: {
            state?: ClientBasketState;
        },
    ) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        const tripDic = this.getTripDictionary(tripKey);
        //
        const petDataReq = routePrice.trips[tripDic.index].petAccReq[petData.index];

        this.getTripPetTicket({
            tripKey,
            ticketCode: petDataReq.petCode,
        });

        const newPetDataReq: TripPricePetAccommodation = {
            ...petDataReq,
            petCode: ticketCode,
        };

        this.dispatchPet(petData, {
            state: options?.state,
            petReqPerTrip: { [tripDic.key]: newPetDataReq },
            selectiveUpdate: {
                pricing: true,
            },
        });
    };

    //---------------DETAILS_PRICE---------------//
    getPetDetailsForPrice = (petId: string) => {
        const bmState = this.getBmState();
        const petData = this.petGetPetRoutePrice(petId);

        let extraValidation: RoutePetValidation = {};

        for (const trip of bmState.routePrice.trips) {
            const petReq = trip.petAccReq[petData.index];
            const ticketAcc =
                bmState.tripDictionaries[trip.key].petAccDic[petReq.petCode];
            if (!ticketAcc.extraPetValidation) {
                continue;
            }
            extraValidation = {
                ...extraValidation,
                ...ticketAcc.extraPetValidation,
            };
        }
        const additionalInfo = this.getRequiredPetExtraFields(petData, extraValidation);
        if (additionalInfo.length === 0) {
            return;
        }
        return {
            fieldInputs: additionalInfo,
        };
    };

    updatePetDetailsForPrice = (options: PetDetailsUpdatePriceReq) => {
        this._log('updatePetDetailsForPrice');
        const petData = this.petGetPetRoutePrice(options.petId);

        const newExtraPriceData: Optional<RoutePricePetData> = {
            ...petData.extraPriceData,
        };

        for (const fieldData of options.data) {
            (newExtraPriceData as any)[fieldData.fieldId] = fieldData.value;
        }

        const updatedPet: RoutePricePetV2 = {
            ...petData,
            extraPriceData: newExtraPriceData,
        };

        this.dispatchPet(updatedPet, {
            disablePriceReset: true,
            selectiveUpdate: {
                pricing: true,
            },
        });
    };
}
