import { SvgProps } from "react-native-svg";
import { ViewStyle } from "../../types/components";
import { SafeAreaView } from "../SafeAreaView";
import { VStack } from "../Stack";
import { AppTextProps, Text } from "../Text";
import { ScrollView, View } from "../View";
import { Image } from "react-native";

export interface FeedbackLayoutProps {
  image: React.FC<SvgProps>;

  text1?: string;
  text1Props?: AppTextProps;
  text2?: string;
  text2Props?: AppTextProps;

  text3?: string;
  text3Props?: AppTextProps;

  style?: ViewStyle;
  children?: React.ReactNode;

  removeTopMargin?: boolean;
  footer?: React.ReactNode;
  noScroll?: boolean;
}

export const FeedbackLayout = (props: FeedbackLayoutProps) => {
  const ViewMode = props.noScroll ? View : ScrollView;
  return (
    <SafeAreaView>
      <ViewMode
        // @ts-expect-error
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{ flexGrow: 1 }}
      >
        <VStack
          flex={1}
          align="center"
          space="6"
          bg="white"
          style={props.style}
          px="6"
        >
          {props.removeTopMargin ? null : <View style={{ height: 60 }} />}
          {props.text1 ? (
            <Text.Body1Medium
              align="center"
              style={[{ maxWidth: 275 }, props.text1Props?.style]}
              {...props.text1Props}
            >
              {props.text1}
            </Text.Body1Medium>
          ) : null}

          <View
            style={{
              width: 180,
              height: 180,
              justifyContent: "center",
              alignItems: "center",
              overflow: "hidden",
            }}
          >
            <Image
              source={{ uri: props.image as any }}
              style={{ width: 180, height: 180 }}
              resizeMode="contain"
            />
          </View>
          {props.text2 ? (
            <Text.Body1Medium
              color={props.text2Props?.color || "monochrome-extraDark"}
              align="center"
              style={{ maxWidth: 275 }}
              {...props.text2Props}
            >
              {props.text2}
            </Text.Body1Medium>
          ) : null}
          {props.text3 ? (
            <FeedBackText3 align="center" {...props.text3Props}>
              {props.text3}
            </FeedBackText3>
          ) : null}
          {props.children}
        </VStack>
      </ViewMode>
      {props.footer ? (
        <VStack px="6" bg="white" pt="2">
          {props.footer}
        </VStack>
      ) : null}
    </SafeAreaView>
  );
};

export const FeedBackText3 = (props: AppTextProps) => {
  return (
    <Text.Body2Regular
      color="monochrome-mid"
      align="center"
      style={[
        { maxWidth: 327, alignSelf: "center", textAlign: "center" },
        props?.style,
      ]}
      {...props}
    >
      {props.children}
    </Text.Body2Regular>
  );
};
