import { isNativeMobile, isWeb } from "../../utils/utils.helper";
import { getScreenHeight, getScreenWidth } from "../../utils/utils.ui";
import { Modal, useModal } from "../Modal";
import { VStack } from "../Stack";
import { ShareButton } from "../UploadFiles/UploadFile.Common";
import { getFileLink } from "../UploadFiles/UploadFile.View";
import { CreateFileProps } from "../UploadFiles/UploadFiles.utils";
import { View } from "../View";
import { WebView } from "react-native-webview";

const ModalKey = "modalKey-fileViewer";

export interface FileClientCreatePublicTokenResponse extends CreateFileProps {
  token: string;
  fileName: string;
  baseUrl: string;
}

const FileViewer = (props: FileClientCreatePublicTokenResponse) => {
  const { closeModal } = useModal();

  const uri = getFileLink(props.baseUrl, props.token);
  return (
    <VStack>
      <Modal.Header
        title={props.fileName}
        onPress={() => {
          closeModal(ModalKey);
        }}
        divider
      >
        <ShareButton
          createFileHook={props.createFileHook}
          baseUrl={props.baseUrl}
          fileId={""}
          token={props.token}
        />
      </Modal.Header>
      <FileViewerBody uri={uri} />
    </VStack>
  );
};

export const FileViewerBody = ({
  uri,
  html,
}: {
  uri: string;
  html?: string;
}) => {
  const height = (getScreenHeight() - 64) * 0.85;

  return (
    <Modal.Body
      style={[
        isWeb && {
          height: height,
          width: getScreenWidth() * 0.95,
          maxWidth: 850,
          padding: 0,
          paddingHorizontal: 0,
        },
        isNativeMobile && {
          padding: 0,
          flex: 1,
        },
      ]}
    >
      <View flex={1} bg="white">
        {isWeb ? (
          <iframe
            srcDoc={html}
            src={html ? undefined : uri}
            height={"100%"}
            width={"100%"}
            style={{ border: "none" }}
          />
        ) : (
          <WebView
            allowFileAccessFromFileURLs
            allowUniversalAccessFromFileURLs
            originWhitelist={["*"]}
            renderError={() => <></>}
            source={html ? { html } : { uri }}
          />
        )}
      </View>
    </Modal.Body>
  );
};

export const useFileViewerWithToken = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: (props: FileClientCreatePublicTokenResponse) => {
      openModal(<FileViewer {...props} />, { key: ModalKey });
    },
    closeModal: () => closeModal(ModalKey),
  };
};
