import { useCallback } from 'react';
import { FlatList as FlatListRN, FlatListProps } from 'react-native';
import { useAppTheme } from '../../theme';
import { View } from '../View';
import Empty from '../../assets/Feedback/empty.svg';
import { VStack } from '../Stack';
import { Text } from '../Text';
import { Image } from 'react-native';

export interface ListProps<T> extends Omit<FlatListProps<T>, 'data'> {
  data: T[];
  idKey: string;
}

export const FlatList = <T,>(props: ListProps<T>) => {
  const { spacing } = useAppTheme();

  const keyExtractor: (item: T, index: number) => string = useCallback(
    (item) => item[props.idKey],
    []
  );

  return (
    <FlatListRN
      {...props}
      contentContainerStyle={[{ flexGrow: 1 }, props.contentContainerStyle]}
      keyExtractor={props.keyExtractor ?? keyExtractor}
      ListEmptyComponent={props.ListEmptyComponent ?? EmptyList}
      ItemSeparatorComponent={
        props.ItemSeparatorComponent ||
        (() => <View style={{ height: spacing[3], width: '100%' }} />)
      }
    />
  );
};

export const EmptyList = () => {
  return (
    <VStack flex={1} justify="center" align="center">
      <VStack space="3" justify="center" align="center">
        <Image
          source={{ uri: Empty as any }}
          style={{ width: 180, height: 180 }}
          resizeMode="contain"
        />
        <Text.Body2Regular color="monochrome-mid" align="center">
          Empty list
        </Text.Body2Regular>
      </VStack>
    </VStack>
  );
};
