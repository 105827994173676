import {
  EdgeInsets,
  useSafeAreaInsets as useSafeAreaInsetsBase,
} from "react-native-safe-area-context";
import { useAppTheme } from "../../theme";

export const useSafeAreaInsets = (): EdgeInsets => {
  const { spacing } = useAppTheme();
  try {
    const insets = useSafeAreaInsetsBase();
    return {
      ...insets,
      bottom: insets.bottom || spacing[3],
      top: insets.top || spacing[3],
    };
  } catch (error) {
    // Sentry.Native.captureException(error);
    return {
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
    };
  }
};
