import type { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { FormInputContainer } from '../FormInputs/FormInput.Container';
import { ColorPicker } from './ColorPicker';
import { useColorPickerForm } from './ColorPicker.hook';

export const ColorPickerInput = (
  props: CommonInputProps<string, 'color-picker'>
) => {
  const {
    value,
    setValue,
    feedback,
    isValid,
    error,
    allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = useColorPickerForm(props);

  return (
    <FormInputContainer
      disabled={disabled}
      error={error}
      focused={false}
      feedback={feedback}
      label={label}
      optional={optional}
      readonly={readonly}
      noFeedback={noFeedback}
      rowProps={props.rowProps}
    >
      <ColorPicker
        color={value}
        error={error}
        disabled={disabled || readonly}
        onColorChange={(c) => {
          setValue(c);
          isValid({ showFeedback: true });
        }}
        allowEmpty={allowEmpty}
      />
    </FormInputContainer>
  );
};
