import React, { useEffect, useRef, useState } from 'react';
import { Pressable } from 'react-native';
import ColorPickerRN from 'react-native-wheel-color-picker';
import { translate } from '../../../lang/lang';
import { Button } from '../../Button/Button';
import { Icon } from '../../Icon/Icon';
import { Modal } from '../../Modal';
import { useModal } from '../../Modal/Modal.Provider';
import { HStack, VStack } from '../../Stack';
import { Text } from '../../Text';
import { View } from '../../View';
import { BaseInput } from '../BaseInputs/BaseInput';
import { FormInput } from '../FormInputs/FormInput';
import { hexToRgb, isValidColor } from './ColorPicker.utils';

export interface ColorPickerProps {
  color?: string;
  onColorChange: (color?: string) => void;
  allowEmpty?: boolean;
  error?: boolean;
  disabled?: boolean;
}

export const ColorPicker = ({
  color,
  allowEmpty,
  error,
  disabled,
  onColorChange,
}: ColorPickerProps) => {
  const modalKey = 'ColorPicker';
  const { openModal, closeModal } = useModal();

  const initialValue = useRef({
    initialColor: undefined as string | undefined,
    initialized: 0,
    colorPicker: null as null | ColorPickerRN,
  });

  useEffect(() => {
    initialValue.current.initialColor = color;
  }, []);

  return (
    <Pressable
      disabled={disabled}
      onPress={() => {
        openModal(
          <ColorPickerContent
            allowEmpty={allowEmpty}
            initialColor={color}
            confirm={(newValue) => {
              onColorChange(newValue);
              closeModal(modalKey);
            }}
            onClose={() => {
              closeModal(modalKey);
            }}
          />,
          {
            key: modalKey,
          }
        );
      }}
    >
      <BaseInput
        error={error}
        disabled={disabled}
        value={color}
        iconEnd={
          <HStack mr="2">
            <View
              br={999}
              mr="1"
              style={{ height: 6, width: 16, backgroundColor: color }}
            />
            <Icon icon="paint" color="monochrome" />
          </HStack>
        }
      />
    </Pressable>
  );
};

const ColorPickerContent = ({
  initialColor,
  allowEmpty,
  onClose,
  confirm,
}: {
  initialColor?: string;
  allowEmpty?: boolean;
  confirm: (color: string | undefined) => void;
  onClose: () => void;
}) => {
  const [color = '', setColor] = useState<string | undefined>(initialColor);
  const ref = useRef({ userDrag: false });
  const validColor = isValidColor(color);
  return (
    <VStack space="5">
      <Modal.Header
        divider
        title={translate('app.inputs.selectColor')}
        onPress={() => {
          onClose();
        }}
      />
      <Modal.Body space="5" p="3">
        <HStack>
          <VStack justify="space-between" space="3" flex={1}>
            <Text.Body2Regular color="monochrome-mid">
              HEX:{' '}
              <Text.Body2Regular
                style={{ fontWeight: 'bold' }}
                color="monochrome-extraDark"
              >
                {!color && allowEmpty
                  ? translate('app.inputs.empty')
                  : validColor
                  ? color
                  : translate('app.inputs.invalid')}
              </Text.Body2Regular>
            </Text.Body2Regular>
            <Text.Body2Regular color="monochrome-mid">
              RGB:{' '}
              <Text.Body2Regular
                style={{ fontWeight: 'bold' }}
                color="monochrome-extraDark"
              >
                {hexToRgb(color)?.value ||
                  (allowEmpty
                    ? translate('app.inputs.empty')
                    : translate('app.inputs.invalid'))}
              </Text.Body2Regular>
            </Text.Body2Regular>
          </VStack>

          <Button
            fitToContent
            variant="text"
            onPress={() => {
              setColor(initialColor);
            }}
          >
            {translate('app.inputs.reset')}
          </Button>
        </HStack>
        <ColorPickerRN
          color={isValidColor(color) ? color : undefined}
          onColorChangeComplete={(newColor) => {
            if (ref.current.userDrag) {
              setColor(newColor);
              ref.current.userDrag = false;
            }
          }}
          onInteractionStart={() => {
            ref.current.userDrag = true;
          }}
          thumbSize={30}
          sliderSize={20}
          noSnap={true}
          row={false}
          swatches={false}
        />

        <FormInput
          noFeedback
          value={color}
          onChangeText={(txt) => {
            setColor(txt);
          }}
          iconEnd={
            <Button
              size="small"
              text="app.inputs.clear"
              variant="text"
              color="monochrome"
              style={{ marginRight: -8 }}
              onPress={() => {
                setColor('');
              }}
            />
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          disabled={!validColor || allowEmpty ? !!color : false}
          text="app.inputs.confirm"
          onPress={() => {
            confirm(!color ? (allowEmpty ? '' : undefined) : color);
          }}
        />
      </Modal.Footer>
    </VStack>
  );
};
