import {
  FormGroup,
  FormGroupItem,
  SeparatorType,
} from '@droplet-tech-code/core-common-types';
import React, { useCallback, useMemo } from 'react';
import {
  FlatList,
  ListRenderItem,
  SectionList,
  SectionListData,
  SectionListRenderItem,
} from 'react-native';
import { useAppTheme } from '../../../../theme';
import { isNativeMobile, isObject } from '../../../../utils/utils.helper';
import { Separator } from '../../../Divider/Divider';
import { HStack } from '../../../Stack';
import { Text } from '../../../Text';
import { View } from '../../../View';
import { FormBuilderListItem } from './FormBuilder.ListItem';
import type { FormListProp } from './FormBuilder.types';
import { ViewStyle } from '../../../../types/components';
import { getInitialConditionConfig } from '../../Inputs.utils';

export const FormList = <T,>(props: FormListProp<T>) => {
  const { spacing } = useAppTheme();

  const renderItem: ListRenderItem<FormGroupItem> = useCallback(
    ({ item: { items, style, type, hide, itemProps }, index }) => {
      const commonStyle: ViewStyle = [
        hide && {
          opacity: 0,
          height: 0,
          width: 0,
          maxHeight: 0,
          minHeight: 0,
          position: 'absolute',
        },
        style,
      ];

      if (type === 'separator') {
        return <Separator style={commonStyle} />;
      }

      return (
        <HStack flex={1} space="2.5" align="stretch" style={commonStyle}>
          {items.map((rootFormGroup) => {
            let value: any;
            let initialValue: any;

            const parentValue = props.value;
            const parentInitialValue = props.initialValue;

            const conditionalFormGroup = getInitialConditionConfig(
              {
                config: rootFormGroup,
                form: props.form,
              },
              parentValue ?? parentInitialValue
            );

            const formGroup = conditionalFormGroup ?? rootFormGroup;
            if (isObject(parentValue)) {
              if (formGroup.id in (parentValue as any)) {
                value = (parentValue as any)[formGroup.id];
              }
            }
            if (isObject(parentInitialValue)) {
              if (formGroup.id in (parentInitialValue as any)) {
                initialValue = (parentInitialValue as any)[formGroup.id];
              }
            }

            return (
              <FormBuilderListItem
                key={formGroup.id}
                index={index}
                form={props.form}
                config={formGroup}
                initialValue={initialValue}
                value={value}
                style={[props.itemStyle, formGroup.style]}
                readonly={props.readonly}
                extensions={props.extensions}
                rowProps={itemProps}
              />
            );
          })}
        </HStack>
      );
    },
    [props.value]
  );
  const keyExtractor = useCallback(
    (item: FormGroupItem, ix: number) => String(ix) + item.type + item.label,
    [props.configList]
  );
  return (
    <FlatList
      style={props.listStyle}
      contentContainerStyle={[
        { paddingHorizontal: spacing[3] },
        props.contentContainerStyle,
      ]}
      data={props.configList}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
};

export const FormSectionList = <T,>(props: FormListProp<T>) => {
  const { spacing } = useAppTheme();
  const renderItem: SectionListRenderItem<FormGroup> = useCallback(
    (sectionItem) => {
      const item = sectionItem.item;
      const config = sectionItem.section.config as
        | FormGroupItem
        | SeparatorType;

      if (config.type === 'separator') {
        return <Separator style={config.style} />;
      }

      const value = isObject(props.value)
        ? props.value?.[item.id]
        : item.initialValue;
      const initialValue = isObject(props.initialValue)
        ? props.initialValue?.[item.id]
        : item.initialValue;

      return (
        <FormBuilderListItem
          index={sectionItem.index}
          form={props.form}
          config={item}
          initialValue={initialValue}
          value={value}
          style={[
            config.hide && {
              opacity: 0,
              height: 0,
              width: 0,
              maxHeight: 0,
              minHeight: 0,
              position: 'absolute',
            },
            props.itemStyle,
            item.style,
          ]}
          rowProps={config.itemProps}
          readonly={props.readonly}
          extensions={props.extensions}
        />
      );
    },
    [props.value]
  );
  const keyExtractor = useCallback(
    (item: FormGroup, ix: number) => item.id + ix,
    []
  );

  const sections = useMemo(() => {
    return props.configList.map((config) => {
      if (config.type === 'separator') {
        return {
          data: [],
          config,
        };
      }

      return {
        data: config.items,
        label: config.label,
        config,
      };
    });
  }, [props.configList]);

  const renderSectionHeader: (info: {
    section: SectionListData<any>;
  }) => React.ReactElement | null = useCallback(({ section: { label } }) => {
    return label ? (
      <View mb="1">
        <Text.Body1Regular color="monochrome-mid">{label}</Text.Body1Regular>
      </View>
    ) : null;
  }, []);

  return (
    <SectionList
      contentContainerStyle={[
        { paddingHorizontal: spacing[3] },
        isNativeMobile && {
          paddingBottom: 60,
        },
        props.contentContainerStyle,
      ]}
      style={props.listStyle}
      sections={sections}
      renderSectionHeader={renderSectionHeader}
      renderItem={renderItem}
      keyExtractor={keyExtractor}
    />
  );
};
