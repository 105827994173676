import { Dimensions } from "react-native";
import * as Device from "expo-device";
import { ColorCategory, ColorKeys, ColorShades } from "../theme";

export const getColorShade = (
  color: ColorKeys | ColorCategory,
  shade: ColorShades
): ColorKeys => {
  const index = color.indexOf("-");
  return ((index !== -1 ? color.slice(0, index) : color) +
    "-" +
    shade) as ColorKeys;
};

export const getScreenHeight = () => Dimensions.get("window").height;
export const getScreenWidth = () => Dimensions.get("window").width;

export const TABLE_MAX_WITH = 800;

export const getDeviceType = async () => {
  return Device.getDeviceTypeAsync();
};

export const isTablet = async () => {
  const device = await getDeviceType();
  return device === Device.DeviceType.TABLET;
};
