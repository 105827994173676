import { BaseInputProps } from '../BaseInputs/BaseInput';
import { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { FormInput } from '../FormInputs/FormInput';
import { useTextForm } from './TextInput.hooks';
import { TextInputProps as RNTextInputProps } from 'react-native';

export interface TextInputProps extends CommonInputProps<string, 'text'> {}

export const TextInput = ({
  secureTextEntry,
  iconEnd,
  iconStart,
  textInputProps,
  rowProps,
  ...props
}: TextInputProps & {
  textInputProps?: RNTextInputProps;
  secureTextEntry?: BaseInputProps['secureTextEntry'];
  iconEnd?: BaseInputProps['iconEnd'];
  iconStart?: BaseInputProps['iconStart'];
}) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    error,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
    config,
  } = useTextForm(props);

  const multiline = props.config.multiline;
  return (
    <FormInput
      rowProps={rowProps}
      noFeedback={noFeedback}
      feedbackOnlyWithError={config.feedbackOnlyWithError}
      autoCorrect={false}
      autoCapitalize="none"
      value={value}
      feedback={feedback}
      error={error}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      onChangeText={(txt) => setValue(txt)}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
        // formProps.onBlur?.(e);
      }}
      numberOfLines={multiline}
      multiline={typeof multiline === 'number'}
      iconStart={iconStart}
      iconEnd={iconEnd}
      secureTextEntry={secureTextEntry}
      {...textInputProps}
    />
  );
};
