import NetInfo from "@react-native-community/netinfo";

class NetworkConnection {
  private _isConnected: boolean | null = null;

  constructor() {
    NetInfo.addEventListener((state) => {
      this._isConnected = state.isConnected;
    });
  }
  async initiate() {
    return NetInfo.fetch()
      .then(({ isConnected }) => {
        this._isConnected = isConnected;
      })
      .catch(() => null);
  }

  isConnected() {
    return this._isConnected;
  }

  isOfflineWithRtk<T>(data: T | undefined, isLoading: boolean) {
    return !data && !isLoading && !this._isConnected;
  }
}

export const networkConnection = new NetworkConnection();
