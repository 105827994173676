import { Pressable } from 'react-native';
import { IconCircle, IconCircleProps } from '../Icon/Icon';
import type { ButtonInteractionState } from './Button.types';
import { ViewStyle } from '../../types/components';

export interface IconButtonBaseProps {
  onPress?: () => void;
  style?: ViewStyle;
}
export type IconButtonProps = IconButtonBaseProps & IconCircleProps;

export const IconButton = ({
  onPress,
  disabled,
  style,
  ...iconCircleProps
}: IconButtonProps) => {
  return (
    <Pressable
      style={({ pressed }) => [{ opacity: pressed ? 0.8 : 1 }, style]}
      onPress={onPress}
      disabled={disabled}
      accessibilityRole="button"
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <IconCircle
            {...iconCircleProps}
            disabled={disabled}
            hovered={hovered}
          />
        );
      }}
    </Pressable>
  );
};
