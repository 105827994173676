export type IconsId =
  | "a-la-carte"
  | "air-conditioning"
  | "airport"
  | "alert-circle"
  | "alert-triangle"
  | "anchor"
  | "apple"
  | "area-v2"
  | "area"
  | "arrow-curve-right"
  | "arrow-down"
  | "arrow-left"
  | "arrow-right"
  | "arrow-up"
  | "bank-refund-receive"
  | "bank-refund"
  | "barcode-disable"
  | "basin"
  | "bed-cabin"
  | "bed-disable"
  | "bed"
  | "bell-active"
  | "bell"
  | "bicycle"
  | "bin"
  | "blacony"
  | "boarding-card"
  | "book"
  | "bookmark"
  | "bunkbed"
  | "bus"
  | "cabin-window"
  | "calendar-arrow-in"
  | "calendar-bell-active"
  | "calendar-bell"
  | "calendar-check"
  | "calendar-ferry"
  | "calendar-gear"
  | "calendar-spanner"
  | "calendar"
  | "calender"
  | "camper-side"
  | "camper"
  | "cancel"
  | "car-cog"
  | "car-park"
  | "car-side"
  | "card-disable"
  | "card"
  | "check-box-off"
  | "check-box-on"
  | "check-circle"
  | "check"
  | "chevron-down"
  | "chevron-left"
  | "chevron-right"
  | "chevron-up"
  | "circle-pause"
  | "circle-tick"
  | "clipboard-edit"
  | "clock"
  | "close"
  | "console"
  | "contact-book"
  | "contact"
  | "convert-open"
  | "copy"
  | "courier"
  | "covid-v1"
  | "covid-v2"
  | "cruise-speed"
  | "custom-amendable"
  | "custom-refundable"
  | "customer-support-v2"
  | "customer-support"
  | "data-gear"
  | "data"
  | "deck-seat"
  | "departure"
  | "direct"
  | "disconnected"
  | "document"
  | "dog"
  | "download-file"
  | "drink"
  | "e-ticket-disable"
  | "e-ticket"
  | "edit-info"
  | "edit"
  | "email"
  | "error-circle"
  | "euro-off"
  | "euro"
  | "exit"
  | "extra-cog"
  | "extra-plus"
  | "eye-disable"
  | "eye"
  | "facilities"
  | "favorite-disable"
  | "favorite"
  | "feedback"
  | "ferry-disable"
  | "ferry"
  | "filter"
  | "finish"
  | "floater"
  | "food"
  | "gates"
  | "google"
  | "hamburger-menu"
  | "history"
  | "home"
  | "hotel"
  | "id-card"
  | "image"
  | "info-disable"
  | "info"
  | "insurance"
  | "invoice-check"
  | "invoice-v2"
  | "invoice-v3"
  | "invoice"
  | "language"
  | "leaf"
  | "length"
  | "lightning-bolt"
  | "link"
  | "live-tracking"
  | "location-pin-cog"
  | "location-pin-double"
  | "location-pin-swaure"
  | "location-pin"
  | "lock"
  | "lounge"
  | "luggage-v2"
  | "luggage"
  | "luxury-cabin-old"
  | "luxury-cabin"
  | "man-walking"
  | "map-marker-check"
  | "map-marker-empty"
  | "map-marker-gear"
  | "map-marker-spanner"
  | "map-marker-thin"
  | "map-marker"
  | "mastercard"
  | "meal"
  | "menu"
  | "middle"
  | "minibus-side"
  | "minibus"
  | "minus"
  | "missing-info"
  | "mobile"
  | "more"
  | "motorcycle-side"
  | "motorcycle"
  | "multi-trip"
  | "navigation"
  | "non-amendable"
  | "non-refundable"
  | "numbered-seats"
  | "office-cog"
  | "office"
  | "onboard-facilities-v2"
  | "onboard-facilities"
  | "online-check-in-disable"
  | "online-check-in"
  | "paint"
  | "paper-check"
  | "passenger-capacity"
  | "passenger"
  | "pdf"
  | "pet-cabin"
  | "pet-cabins"
  | "pet-disable"
  | "pet-info"
  | "pet-paw"
  | "pets-allowed"
  | "phone"
  | "pin-disable-v2"
  | "pin-disable"
  | "pin-v2"
  | "pin"
  | "plus-circle"
  | "plus-square"
  | "plus"
  | "popout"
  | "popular-routes"
  | "price"
  | "printer"
  | "qr-code-disabled"
  | "qr-code"
  | "question-mark"
  | "rain"
  | "rating"
  | "receipt-download"
  | "receipt-email"
  | "receipt"
  | "refresh"
  | "reset"
  | "result"
  | "return"
  | "reward"
  | "route"
  | "search"
  | "seats"
  | "send"
  | "setting-gear"
  | "share"
  | "shopping-basket"
  | "sign-in"
  | "sign-out"
  | "single-seat"
  | "small-office"
  | "sort-by"
  | "spanner"
  | "specialneeds"
  | "speedometer"
  | "star-filled"
  | "star"
  | "start"
  | "steering-wheel"
  | "strikes-v1"
  | "strikes-v2"
  | "support-agent"
  | "support-bubble"
  | "support-closed"
  | "taxi"
  | "terms-of-use"
  | "thumbs-rating"
  | "thunder-storm"
  | "ticket-cancellation"
  | "ticket-email"
  | "ticket-number"
  | "ticket-offers"
  | "ticket-v2"
  | "ticket"
  | "todo-list"
  | "tornado"
  | "track"
  | "trailer-side"
  | "trailer"
  | "train"
  | "transactions"
  | "user-cog"
  | "user-email"
  | "user-support"
  | "user"
  | "vehicle"
  | "vehicles-disable"
  | "vehicles"
  | "verify"
  | "vessel-cog"
  | "vessel-many"
  | "vessel-type"
  | "visa"
  | "voucher-full"
  | "voucher-refund"
  | "voucher"
  | "wallet"
  | "warning"
  | "wc"
  | "weight"
  | "width"
  | "wifi-disable"
  | "wifi"
  | "wine-glass"
  | "world-wide";

export type IconsKey =
  | "ALaCarte"
  | "AirConditioning"
  | "Airport"
  | "AlertCircle"
  | "AlertTriangle"
  | "Anchor"
  | "Apple"
  | "AreaV2"
  | "Area"
  | "ArrowCurveRight"
  | "ArrowDown"
  | "ArrowLeft"
  | "ArrowRight"
  | "ArrowUp"
  | "BankRefundReceive"
  | "BankRefund"
  | "BarcodeDisable"
  | "Basin"
  | "BedCabin"
  | "BedDisable"
  | "Bed"
  | "BellActive"
  | "Bell"
  | "Bicycle"
  | "Bin"
  | "Blacony"
  | "BoardingCard"
  | "Book"
  | "Bookmark"
  | "Bunkbed"
  | "Bus"
  | "CabinWindow"
  | "CalendarArrowIn"
  | "CalendarBellActive"
  | "CalendarBell"
  | "CalendarCheck"
  | "CalendarFerry"
  | "CalendarGear"
  | "CalendarSpanner"
  | "Calendar"
  | "Calender"
  | "CamperSide"
  | "Camper"
  | "Cancel"
  | "CarCog"
  | "CarPark"
  | "CarSide"
  | "CardDisable"
  | "Card"
  | "CheckBoxOff"
  | "CheckBoxOn"
  | "CheckCircle"
  | "Check"
  | "ChevronDown"
  | "ChevronLeft"
  | "ChevronRight"
  | "ChevronUp"
  | "CirclePause"
  | "CircleTick"
  | "ClipboardEdit"
  | "Clock"
  | "Close"
  | "Console"
  | "ContactBook"
  | "Contact"
  | "ConvertOpen"
  | "Copy"
  | "Courier"
  | "CovidV1"
  | "CovidV2"
  | "CruiseSpeed"
  | "CustomAmendable"
  | "CustomRefundable"
  | "CustomerSupportV2"
  | "CustomerSupport"
  | "DataGear"
  | "Data"
  | "DeckSeat"
  | "Departure"
  | "Direct"
  | "Disconnected"
  | "Document"
  | "Dog"
  | "DownloadFile"
  | "Drink"
  | "ETicketDisable"
  | "ETicket"
  | "EditInfo"
  | "Edit"
  | "Email"
  | "ErrorCircle"
  | "EuroOff"
  | "Euro"
  | "Exit"
  | "ExtraCog"
  | "ExtraPlus"
  | "EyeDisable"
  | "Eye"
  | "Facilities"
  | "FavoriteDisable"
  | "Favorite"
  | "Feedback"
  | "FerryDisable"
  | "Ferry"
  | "Filter"
  | "Finish"
  | "Floater"
  | "Food"
  | "Gates"
  | "Google"
  | "HamburgerMenu"
  | "History"
  | "Home"
  | "Hotel"
  | "IdCard"
  | "Image"
  | "InfoDisable"
  | "Info"
  | "Insurance"
  | "InvoiceCheck"
  | "InvoiceV2"
  | "InvoiceV3"
  | "Invoice"
  | "Language"
  | "Leaf"
  | "Length"
  | "LightningBolt"
  | "Link"
  | "LiveTracking"
  | "LocationPinCog"
  | "LocationPinDouble"
  | "LocationPinSwaure"
  | "LocationPin"
  | "Lock"
  | "Lounge"
  | "LuggageV2"
  | "Luggage"
  | "LuxuryCabinOld"
  | "LuxuryCabin"
  | "ManWalking"
  | "MapMarkerCheck"
  | "MapMarkerEmpty"
  | "MapMarkerGear"
  | "MapMarkerSpanner"
  | "MapMarkerThin"
  | "MapMarker"
  | "Mastercard"
  | "Meal"
  | "Menu"
  | "Middle"
  | "MinibusSide"
  | "Minibus"
  | "Minus"
  | "MissingInfo"
  | "Mobile"
  | "More"
  | "MotorcycleSide"
  | "Motorcycle"
  | "MultiTrip"
  | "Navigation"
  | "NonAmendable"
  | "NonRefundable"
  | "NumberedSeats"
  | "OfficeCog"
  | "Office"
  | "OnboardFacilitiesV2"
  | "OnboardFacilities"
  | "OnlineCheckInDisable"
  | "OnlineCheckIn"
  | "Paint"
  | "PaperCheck"
  | "PassengerCapacity"
  | "Passenger"
  | "Pdf"
  | "PetCabin"
  | "PetCabins"
  | "PetDisable"
  | "PetInfo"
  | "PetPaw"
  | "PetsAllowed"
  | "Phone"
  | "PinDisableV2"
  | "PinDisable"
  | "PinV2"
  | "Pin"
  | "PlusCircle"
  | "PlusSquare"
  | "Plus"
  | "Popout"
  | "PopularRoutes"
  | "Price"
  | "Printer"
  | "QrCodeDisabled"
  | "QrCode"
  | "QuestionMark"
  | "Rain"
  | "Rating"
  | "ReceiptDownload"
  | "ReceiptEmail"
  | "Receipt"
  | "Refresh"
  | "Reset"
  | "Result"
  | "Return"
  | "Reward"
  | "Route"
  | "Search"
  | "Seats"
  | "Send"
  | "SettingGear"
  | "Share"
  | "ShoppingBasket"
  | "SignIn"
  | "SignOut"
  | "SingleSeat"
  | "SmallOffice"
  | "SortBy"
  | "Spanner"
  | "Specialneeds"
  | "Speedometer"
  | "StarFilled"
  | "Star"
  | "Start"
  | "SteeringWheel"
  | "StrikesV1"
  | "StrikesV2"
  | "SupportAgent"
  | "SupportBubble"
  | "SupportClosed"
  | "Taxi"
  | "TermsOfUse"
  | "ThumbsRating"
  | "ThunderStorm"
  | "TicketCancellation"
  | "TicketEmail"
  | "TicketNumber"
  | "TicketOffers"
  | "TicketV2"
  | "Ticket"
  | "TodoList"
  | "Tornado"
  | "Track"
  | "TrailerSide"
  | "Trailer"
  | "Train"
  | "Transactions"
  | "UserCog"
  | "UserEmail"
  | "UserSupport"
  | "User"
  | "Vehicle"
  | "VehiclesDisable"
  | "Vehicles"
  | "Verify"
  | "VesselCog"
  | "VesselMany"
  | "VesselType"
  | "Visa"
  | "VoucherFull"
  | "VoucherRefund"
  | "Voucher"
  | "Wallet"
  | "Warning"
  | "Wc"
  | "Weight"
  | "Width"
  | "WifiDisable"
  | "Wifi"
  | "WineGlass"
  | "WorldWide";

export enum Icons {
  ALaCarte = "a-la-carte",
  AirConditioning = "air-conditioning",
  Airport = "airport",
  AlertCircle = "alert-circle",
  AlertTriangle = "alert-triangle",
  Anchor = "anchor",
  Apple = "apple",
  AreaV2 = "area-v2",
  Area = "area",
  ArrowCurveRight = "arrow-curve-right",
  ArrowDown = "arrow-down",
  ArrowLeft = "arrow-left",
  ArrowRight = "arrow-right",
  ArrowUp = "arrow-up",
  BankRefundReceive = "bank-refund-receive",
  BankRefund = "bank-refund",
  BarcodeDisable = "barcode-disable",
  Basin = "basin",
  BedCabin = "bed-cabin",
  BedDisable = "bed-disable",
  Bed = "bed",
  BellActive = "bell-active",
  Bell = "bell",
  Bicycle = "bicycle",
  Bin = "bin",
  Blacony = "blacony",
  BoardingCard = "boarding-card",
  Book = "book",
  Bookmark = "bookmark",
  Bunkbed = "bunkbed",
  Bus = "bus",
  CabinWindow = "cabin-window",
  CalendarArrowIn = "calendar-arrow-in",
  CalendarBellActive = "calendar-bell-active",
  CalendarBell = "calendar-bell",
  CalendarCheck = "calendar-check",
  CalendarFerry = "calendar-ferry",
  CalendarGear = "calendar-gear",
  CalendarSpanner = "calendar-spanner",
  Calendar = "calendar",
  Calender = "calender",
  CamperSide = "camper-side",
  Camper = "camper",
  Cancel = "cancel",
  CarCog = "car-cog",
  CarPark = "car-park",
  CarSide = "car-side",
  CardDisable = "card-disable",
  Card = "card",
  CheckBoxOff = "check-box-off",
  CheckBoxOn = "check-box-on",
  CheckCircle = "check-circle",
  Check = "check",
  ChevronDown = "chevron-down",
  ChevronLeft = "chevron-left",
  ChevronRight = "chevron-right",
  ChevronUp = "chevron-up",
  CirclePause = "circle-pause",
  CircleTick = "circle-tick",
  ClipboardEdit = "clipboard-edit",
  Clock = "clock",
  Close = "close",
  Console = "console",
  ContactBook = "contact-book",
  Contact = "contact",
  ConvertOpen = "convert-open",
  Copy = "copy",
  Courier = "courier",
  CovidV1 = "covid-v1",
  CovidV2 = "covid-v2",
  CruiseSpeed = "cruise-speed",
  CustomAmendable = "custom-amendable",
  CustomRefundable = "custom-refundable",
  CustomerSupportV2 = "customer-support-v2",
  CustomerSupport = "customer-support",
  DataGear = "data-gear",
  Data = "data",
  DeckSeat = "deck-seat",
  Departure = "departure",
  Direct = "direct",
  Disconnected = "disconnected",
  Document = "document",
  Dog = "dog",
  DownloadFile = "download-file",
  Drink = "drink",
  ETicketDisable = "e-ticket-disable",
  ETicket = "e-ticket",
  EditInfo = "edit-info",
  Edit = "edit",
  Email = "email",
  ErrorCircle = "error-circle",
  EuroOff = "euro-off",
  Euro = "euro",
  Exit = "exit",
  ExtraCog = "extra-cog",
  ExtraPlus = "extra-plus",
  EyeDisable = "eye-disable",
  Eye = "eye",
  Facilities = "facilities",
  FavoriteDisable = "favorite-disable",
  Favorite = "favorite",
  Feedback = "feedback",
  FerryDisable = "ferry-disable",
  Ferry = "ferry",
  Filter = "filter",
  Finish = "finish",
  Floater = "floater",
  Food = "food",
  Gates = "gates",
  Google = "google",
  HamburgerMenu = "hamburger-menu",
  History = "history",
  Home = "home",
  Hotel = "hotel",
  IdCard = "id-card",
  Image = "image",
  InfoDisable = "info-disable",
  Info = "info",
  Insurance = "insurance",
  InvoiceCheck = "invoice-check",
  InvoiceV2 = "invoice-v2",
  InvoiceV3 = "invoice-v3",
  Invoice = "invoice",
  Language = "language",
  Leaf = "leaf",
  Length = "length",
  LightningBolt = "lightning-bolt",
  Link = "link",
  LiveTracking = "live-tracking",
  LocationPinCog = "location-pin-cog",
  LocationPinDouble = "location-pin-double",
  LocationPinSwaure = "location-pin-swaure",
  LocationPin = "location-pin",
  Lock = "lock",
  Lounge = "lounge",
  LuggageV2 = "luggage-v2",
  Luggage = "luggage",
  LuxuryCabinOld = "luxury-cabin-old",
  LuxuryCabin = "luxury-cabin",
  ManWalking = "man-walking",
  MapMarkerCheck = "map-marker-check",
  MapMarkerEmpty = "map-marker-empty",
  MapMarkerGear = "map-marker-gear",
  MapMarkerSpanner = "map-marker-spanner",
  MapMarkerThin = "map-marker-thin",
  MapMarker = "map-marker",
  Mastercard = "mastercard",
  Meal = "meal",
  Menu = "menu",
  Middle = "middle",
  MinibusSide = "minibus-side",
  Minibus = "minibus",
  Minus = "minus",
  MissingInfo = "missing-info",
  Mobile = "mobile",
  More = "more",
  MotorcycleSide = "motorcycle-side",
  Motorcycle = "motorcycle",
  MultiTrip = "multi-trip",
  Navigation = "navigation",
  NonAmendable = "non-amendable",
  NonRefundable = "non-refundable",
  NumberedSeats = "numbered-seats",
  OfficeCog = "office-cog",
  Office = "office",
  OnboardFacilitiesV2 = "onboard-facilities-v2",
  OnboardFacilities = "onboard-facilities",
  OnlineCheckInDisable = "online-check-in-disable",
  OnlineCheckIn = "online-check-in",
  Paint = "paint",
  PaperCheck = "paper-check",
  PassengerCapacity = "passenger-capacity",
  Passenger = "passenger",
  Pdf = "pdf",
  PetCabin = "pet-cabin",
  PetCabins = "pet-cabins",
  PetDisable = "pet-disable",
  PetInfo = "pet-info",
  PetPaw = "pet-paw",
  PetsAllowed = "pets-allowed",
  Phone = "phone",
  PinDisableV2 = "pin-disable-v2",
  PinDisable = "pin-disable",
  PinV2 = "pin-v2",
  Pin = "pin",
  PlusCircle = "plus-circle",
  PlusSquare = "plus-square",
  Plus = "plus",
  Popout = "popout",
  PopularRoutes = "popular-routes",
  Price = "price",
  Printer = "printer",
  QrCodeDisabled = "qr-code-disabled",
  QrCode = "qr-code",
  QuestionMark = "question-mark",
  Rain = "rain",
  Rating = "rating",
  ReceiptDownload = "receipt-download",
  ReceiptEmail = "receipt-email",
  Receipt = "receipt",
  Refresh = "refresh",
  Reset = "reset",
  Result = "result",
  Return = "return",
  Reward = "reward",
  Route = "route",
  Search = "search",
  Seats = "seats",
  Send = "send",
  SettingGear = "setting-gear",
  Share = "share",
  ShoppingBasket = "shopping-basket",
  SignIn = "sign-in",
  SignOut = "sign-out",
  SingleSeat = "single-seat",
  SmallOffice = "small-office",
  SortBy = "sort-by",
  Spanner = "spanner",
  Specialneeds = "specialneeds",
  Speedometer = "speedometer",
  StarFilled = "star-filled",
  Star = "star",
  Start = "start",
  SteeringWheel = "steering-wheel",
  StrikesV1 = "strikes-v1",
  StrikesV2 = "strikes-v2",
  SupportAgent = "support-agent",
  SupportBubble = "support-bubble",
  SupportClosed = "support-closed",
  Taxi = "taxi",
  TermsOfUse = "terms-of-use",
  ThumbsRating = "thumbs-rating",
  ThunderStorm = "thunder-storm",
  TicketCancellation = "ticket-cancellation",
  TicketEmail = "ticket-email",
  TicketNumber = "ticket-number",
  TicketOffers = "ticket-offers",
  TicketV2 = "ticket-v2",
  Ticket = "ticket",
  TodoList = "todo-list",
  Tornado = "tornado",
  Track = "track",
  TrailerSide = "trailer-side",
  Trailer = "trailer",
  Train = "train",
  Transactions = "transactions",
  UserCog = "user-cog",
  UserEmail = "user-email",
  UserSupport = "user-support",
  User = "user",
  Vehicle = "vehicle",
  VehiclesDisable = "vehicles-disable",
  Vehicles = "vehicles",
  Verify = "verify",
  VesselCog = "vessel-cog",
  VesselMany = "vessel-many",
  VesselType = "vessel-type",
  Visa = "visa",
  VoucherFull = "voucher-full",
  VoucherRefund = "voucher-refund",
  Voucher = "voucher",
  Wallet = "wallet",
  Warning = "warning",
  Wc = "wc",
  Weight = "weight",
  Width = "width",
  WifiDisable = "wifi-disable",
  Wifi = "wifi",
  WineGlass = "wine-glass",
  WorldWide = "world-wide",
}

export const ICONS_CODEPOINTS: { [key in Icons]: string } = {
  [Icons.ALaCarte]: "61697",
  [Icons.AirConditioning]: "61698",
  [Icons.Airport]: "61699",
  [Icons.AlertCircle]: "61700",
  [Icons.AlertTriangle]: "61701",
  [Icons.Anchor]: "61702",
  [Icons.Apple]: "61703",
  [Icons.AreaV2]: "61704",
  [Icons.Area]: "61705",
  [Icons.ArrowCurveRight]: "61706",
  [Icons.ArrowDown]: "61707",
  [Icons.ArrowLeft]: "61708",
  [Icons.ArrowRight]: "61709",
  [Icons.ArrowUp]: "61710",
  [Icons.BankRefundReceive]: "61711",
  [Icons.BankRefund]: "61712",
  [Icons.BarcodeDisable]: "61713",
  [Icons.Basin]: "61714",
  [Icons.BedCabin]: "61715",
  [Icons.BedDisable]: "61716",
  [Icons.Bed]: "61717",
  [Icons.BellActive]: "61718",
  [Icons.Bell]: "61719",
  [Icons.Bicycle]: "61720",
  [Icons.Bin]: "61721",
  [Icons.Blacony]: "61722",
  [Icons.BoardingCard]: "61723",
  [Icons.Book]: "61724",
  [Icons.Bookmark]: "61725",
  [Icons.Bunkbed]: "61726",
  [Icons.Bus]: "61727",
  [Icons.CabinWindow]: "61728",
  [Icons.CalendarArrowIn]: "61729",
  [Icons.CalendarBellActive]: "61730",
  [Icons.CalendarBell]: "61731",
  [Icons.CalendarCheck]: "61732",
  [Icons.CalendarFerry]: "61733",
  [Icons.CalendarGear]: "61734",
  [Icons.CalendarSpanner]: "61735",
  [Icons.Calendar]: "61736",
  [Icons.Calender]: "61737",
  [Icons.CamperSide]: "61738",
  [Icons.Camper]: "61739",
  [Icons.Cancel]: "61740",
  [Icons.CarCog]: "61741",
  [Icons.CarPark]: "61742",
  [Icons.CarSide]: "61743",
  [Icons.CardDisable]: "61744",
  [Icons.Card]: "61745",
  [Icons.CheckBoxOff]: "61746",
  [Icons.CheckBoxOn]: "61747",
  [Icons.CheckCircle]: "61748",
  [Icons.Check]: "61749",
  [Icons.ChevronDown]: "61750",
  [Icons.ChevronLeft]: "61751",
  [Icons.ChevronRight]: "61752",
  [Icons.ChevronUp]: "61753",
  [Icons.CirclePause]: "61754",
  [Icons.CircleTick]: "61755",
  [Icons.ClipboardEdit]: "61756",
  [Icons.Clock]: "61757",
  [Icons.Close]: "61758",
  [Icons.Console]: "61759",
  [Icons.ContactBook]: "61760",
  [Icons.Contact]: "61761",
  [Icons.ConvertOpen]: "61762",
  [Icons.Copy]: "61763",
  [Icons.Courier]: "61764",
  [Icons.CovidV1]: "61765",
  [Icons.CovidV2]: "61766",
  [Icons.CruiseSpeed]: "61767",
  [Icons.CustomAmendable]: "61768",
  [Icons.CustomRefundable]: "61769",
  [Icons.CustomerSupportV2]: "61770",
  [Icons.CustomerSupport]: "61771",
  [Icons.DataGear]: "61772",
  [Icons.Data]: "61773",
  [Icons.DeckSeat]: "61774",
  [Icons.Departure]: "61775",
  [Icons.Direct]: "61776",
  [Icons.Disconnected]: "61777",
  [Icons.Document]: "61778",
  [Icons.Dog]: "61779",
  [Icons.DownloadFile]: "61780",
  [Icons.Drink]: "61781",
  [Icons.ETicketDisable]: "61782",
  [Icons.ETicket]: "61783",
  [Icons.EditInfo]: "61784",
  [Icons.Edit]: "61785",
  [Icons.Email]: "61786",
  [Icons.ErrorCircle]: "61787",
  [Icons.EuroOff]: "61788",
  [Icons.Euro]: "61789",
  [Icons.Exit]: "61790",
  [Icons.ExtraCog]: "61791",
  [Icons.ExtraPlus]: "61792",
  [Icons.EyeDisable]: "61793",
  [Icons.Eye]: "61794",
  [Icons.Facilities]: "61795",
  [Icons.FavoriteDisable]: "61796",
  [Icons.Favorite]: "61797",
  [Icons.Feedback]: "61798",
  [Icons.FerryDisable]: "61799",
  [Icons.Ferry]: "61800",
  [Icons.Filter]: "61801",
  [Icons.Finish]: "61802",
  [Icons.Floater]: "61803",
  [Icons.Food]: "61804",
  [Icons.Gates]: "61805",
  [Icons.Google]: "61806",
  [Icons.HamburgerMenu]: "61807",
  [Icons.History]: "61808",
  [Icons.Home]: "61809",
  [Icons.Hotel]: "61810",
  [Icons.IdCard]: "61811",
  [Icons.Image]: "61812",
  [Icons.InfoDisable]: "61813",
  [Icons.Info]: "61814",
  [Icons.Insurance]: "61815",
  [Icons.InvoiceCheck]: "61816",
  [Icons.InvoiceV2]: "61817",
  [Icons.InvoiceV3]: "61818",
  [Icons.Invoice]: "61819",
  [Icons.Language]: "61820",
  [Icons.Leaf]: "61821",
  [Icons.Length]: "61822",
  [Icons.LightningBolt]: "61823",
  [Icons.Link]: "61824",
  [Icons.LiveTracking]: "61825",
  [Icons.LocationPinCog]: "61826",
  [Icons.LocationPinDouble]: "61827",
  [Icons.LocationPinSwaure]: "61828",
  [Icons.LocationPin]: "61829",
  [Icons.Lock]: "61830",
  [Icons.Lounge]: "61831",
  [Icons.LuggageV2]: "61832",
  [Icons.Luggage]: "61833",
  [Icons.LuxuryCabinOld]: "61834",
  [Icons.LuxuryCabin]: "61835",
  [Icons.ManWalking]: "61836",
  [Icons.MapMarkerCheck]: "61837",
  [Icons.MapMarkerEmpty]: "61838",
  [Icons.MapMarkerGear]: "61839",
  [Icons.MapMarkerSpanner]: "61840",
  [Icons.MapMarkerThin]: "61841",
  [Icons.MapMarker]: "61842",
  [Icons.Mastercard]: "61843",
  [Icons.Meal]: "61844",
  [Icons.Menu]: "61845",
  [Icons.Middle]: "61846",
  [Icons.MinibusSide]: "61847",
  [Icons.Minibus]: "61848",
  [Icons.Minus]: "61849",
  [Icons.MissingInfo]: "61850",
  [Icons.Mobile]: "61851",
  [Icons.More]: "61852",
  [Icons.MotorcycleSide]: "61853",
  [Icons.Motorcycle]: "61854",
  [Icons.MultiTrip]: "61855",
  [Icons.Navigation]: "61856",
  [Icons.NonAmendable]: "61857",
  [Icons.NonRefundable]: "61858",
  [Icons.NumberedSeats]: "61859",
  [Icons.OfficeCog]: "61860",
  [Icons.Office]: "61861",
  [Icons.OnboardFacilitiesV2]: "61862",
  [Icons.OnboardFacilities]: "61863",
  [Icons.OnlineCheckInDisable]: "61864",
  [Icons.OnlineCheckIn]: "61865",
  [Icons.Paint]: "61866",
  [Icons.PaperCheck]: "61867",
  [Icons.PassengerCapacity]: "61868",
  [Icons.Passenger]: "61869",
  [Icons.Pdf]: "61870",
  [Icons.PetCabin]: "61871",
  [Icons.PetCabins]: "61872",
  [Icons.PetDisable]: "61873",
  [Icons.PetInfo]: "61874",
  [Icons.PetPaw]: "61875",
  [Icons.PetsAllowed]: "61876",
  [Icons.Phone]: "61877",
  [Icons.PinDisableV2]: "61878",
  [Icons.PinDisable]: "61879",
  [Icons.PinV2]: "61880",
  [Icons.Pin]: "61881",
  [Icons.PlusCircle]: "61882",
  [Icons.PlusSquare]: "61883",
  [Icons.Plus]: "61884",
  [Icons.Popout]: "61885",
  [Icons.PopularRoutes]: "61886",
  [Icons.Price]: "61887",
  [Icons.Printer]: "61888",
  [Icons.QrCodeDisabled]: "61889",
  [Icons.QrCode]: "61890",
  [Icons.QuestionMark]: "61891",
  [Icons.Rain]: "61892",
  [Icons.Rating]: "61893",
  [Icons.ReceiptDownload]: "61894",
  [Icons.ReceiptEmail]: "61895",
  [Icons.Receipt]: "61896",
  [Icons.Refresh]: "61897",
  [Icons.Reset]: "61898",
  [Icons.Result]: "61899",
  [Icons.Return]: "61900",
  [Icons.Reward]: "61901",
  [Icons.Route]: "61902",
  [Icons.Search]: "61903",
  [Icons.Seats]: "61904",
  [Icons.Send]: "61905",
  [Icons.SettingGear]: "61906",
  [Icons.Share]: "61907",
  [Icons.ShoppingBasket]: "61908",
  [Icons.SignIn]: "61909",
  [Icons.SignOut]: "61910",
  [Icons.SingleSeat]: "61911",
  [Icons.SmallOffice]: "61912",
  [Icons.SortBy]: "61913",
  [Icons.Spanner]: "61914",
  [Icons.Specialneeds]: "61915",
  [Icons.Speedometer]: "61916",
  [Icons.StarFilled]: "61917",
  [Icons.Star]: "61918",
  [Icons.Start]: "61919",
  [Icons.SteeringWheel]: "61920",
  [Icons.StrikesV1]: "61921",
  [Icons.StrikesV2]: "61922",
  [Icons.SupportAgent]: "61923",
  [Icons.SupportBubble]: "61924",
  [Icons.SupportClosed]: "61925",
  [Icons.Taxi]: "61926",
  [Icons.TermsOfUse]: "61927",
  [Icons.ThumbsRating]: "61928",
  [Icons.ThunderStorm]: "61929",
  [Icons.TicketCancellation]: "61930",
  [Icons.TicketEmail]: "61931",
  [Icons.TicketNumber]: "61932",
  [Icons.TicketOffers]: "61933",
  [Icons.TicketV2]: "61934",
  [Icons.Ticket]: "61935",
  [Icons.TodoList]: "61936",
  [Icons.Tornado]: "61937",
  [Icons.Track]: "61938",
  [Icons.TrailerSide]: "61939",
  [Icons.Trailer]: "61940",
  [Icons.Train]: "61941",
  [Icons.Transactions]: "61942",
  [Icons.UserCog]: "61943",
  [Icons.UserEmail]: "61944",
  [Icons.UserSupport]: "61945",
  [Icons.User]: "61946",
  [Icons.Vehicle]: "61947",
  [Icons.VehiclesDisable]: "61948",
  [Icons.Vehicles]: "61949",
  [Icons.Verify]: "61950",
  [Icons.VesselCog]: "61951",
  [Icons.VesselMany]: "61952",
  [Icons.VesselType]: "61953",
  [Icons.Visa]: "61954",
  [Icons.VoucherFull]: "61955",
  [Icons.VoucherRefund]: "61956",
  [Icons.Voucher]: "61957",
  [Icons.Wallet]: "61958",
  [Icons.Warning]: "61959",
  [Icons.Wc]: "61960",
  [Icons.Weight]: "61961",
  [Icons.Width]: "61962",
  [Icons.WifiDisable]: "61963",
  [Icons.Wifi]: "61964",
  [Icons.WineGlass]: "61965",
  [Icons.WorldWide]: "61966",
};
