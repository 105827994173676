import { createContext, useEffect, useRef, useState } from "react";
import {
  LayoutRectangle,
  Pressable,
  PressableProps,
  StyleSheet,
} from "react-native";
import { isWeb } from "../../utils/utils.helper";
import { View } from "../View";
import { useModal } from "../Modal";

export const PopoverContext = createContext({
  setPopover: (_c: React.ReactNode) => {},
  closePopover: () => {},
});

class PopoverMethods {
  openPopover = (_c: React.ReactNode) => {};
  closePopover = () => {};
}
export const popoverControl = new PopoverMethods();

export const PopoverProvider = () => {
  const [popover, setPopover] = useState<React.ReactNode | null>(null);
  useEffect(() => {
    popoverControl.closePopover = () => setPopover(null);
    popoverControl.openPopover = (v) => setPopover(v);
    return () => {
      popoverControl.closePopover = () => {};
      popoverControl.openPopover = () => {};
    };
  }, []);

  if (!popover) {
    return null;
  }

  return (
    <Pressable
      onPress={() => setPopover(null)}
      style={[StyleSheet.absoluteFill]}
    >
      {popover}
    </Pressable>
  );
};

export const PopoverContainer = ({
  width = 300,
  maxHeight,
  offsetX = 0,
  offsetY = 0,
  Popover,
  isLoading,
  ...props
}: {
  offsetX?: number;
  offsetY?: number;
  width?: number;
  maxHeight?: number;
  isLoading?: boolean;
  Popover: React.ReactNode;
} & PressableProps) => {
  const { openModal } = useModal();

  const dropDownLocation = useRef<LayoutRectangle>({
    x: 0,
    y: 0,
    height: 0,
    width: 0,
  });
  const viewRef = useRef<HTMLElement | undefined>();
  const readjustPosition = () => {
    if (isWeb) {
      const layout = viewRef.current?.getBoundingClientRect?.();

      if (layout) {
        dropDownLocation.current = {
          width: layout.width,
          height: layout.height,
          x: layout.left + offsetX,
          y: layout.top + offsetY,
        };
      }
    }
  };

  return (
    <View
      onLayout={(e) => {
        if (e && isWeb) {
          viewRef.current = (e.nativeEvent as any).target;
          readjustPosition();
        }
      }}
    >
      <Pressable
        {...props}
        onPress={() => {
          if (isWeb) {
            readjustPosition();
            popoverControl.openPopover(
              <Pressable
                style={{
                  position: "fixed" as any,
                  top:
                    dropDownLocation.current.y +
                    dropDownLocation.current.height,
                  left:
                    dropDownLocation.current.x -
                    width +
                    dropDownLocation.current.width,
                }}
              >
                <View
                  bg="white"
                  br={12}
                  style={[
                    {
                      width,
                      maxHeight,
                      shadowOffset: { width: 2, height: 2 },
                      shadowOpacity: 0.25,
                      shadowRadius: 5,
                      elevation: 3,
                    },

                    isWeb && {
                      overflow: "hidden",
                    },
                  ]}
                >
                  {Popover}
                </View>
              </Pressable>
            );
          } else {
            openModal(<>{Popover}</>, {
              key: "openPopover",
            });
          }
        }}
      />
    </View>
  );
};
