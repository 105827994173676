import {
    CustomerClientPetV2,
    KeyVal,
    Optional,
    RoutePetValidation,
    RoutePricePetV2,
    RouteTripDictionaryClient,
    TicketPetCategory,
    TripPricePetAccommodation,
} from '@naus-code/naus-client-types';
import { BasketManagerPassengersDispatch } from './bm.3.pas.0.dispatch';
import { DispatchPetOptions } from '../types/providerCommon';
import {
    ClientBasketState,
    ClientStatePetTripDetail,
    PetListItem,
    SelectTicketEditPetItem,
    SelectTicketPetItem,
    SelectedTicket,
} from '../types/clientState';
import { createError } from '../errors';
import {
    FieldInput,
    PetFieldNames,
} from '../types/clientState/2.tipDetails/clientState.fieldInputs';
import { mapArrayToKeyValue } from '../utils/functions';

export class BasketManagerPetsDispatch extends BasketManagerPassengersDispatch {
    //

    petGetSavedDictionaryNoThrow = (petId: string): CustomerClientPetV2 | undefined => {
        const bmState = this.getBmState();
        return bmState.savedPetsDic[petId];
    };

    petGetSavedDictionary = (petId: string): CustomerClientPetV2 => {
        const bmState = this.getBmState();
        const pet = bmState.savedPetsDic[petId];
        if (!pet) {
            throw createError({ code: 'SAVED_PET_NOT_FOUND' });
        }
        return pet;
    };

    //

    private getDispatchPetReqPerTrip = (
        petData: RoutePricePetV2,
        options?: DispatchPetOptions,
    ): KeyVal<TripPricePetAccommodation> => {
        const bmState = this.getBmState();
        return (
            options?.petReqPerTrip ??
            mapArrayToKeyValue(
                bmState.routePrice.trips,
                (trip) => trip.key,
                (trip) => trip.petAccReq[petData.index],
            )
        );
    };

    dispatchPet = (petData: RoutePricePetV2, options?: DispatchPetOptions) => {
        const bmState = this.getBmState();
        const petIndex = petData.index;

        const petReqPerTrip = this.getDispatchPetReqPerTrip(petData, options);

        bmState.routePrice.pets[petIndex] = petData;

        bmState.routePrice.trips.forEach((trip) => {
            const includeTrip = !!petReqPerTrip?.[trip.key];
            if (includeTrip) {
                trip.petAccReq[petIndex] = petReqPerTrip[trip.key];
            }
        });

        if (!options?.disablePriceReset) {
            this.resetRoutePriceManagerState();
        }

        if (!options?.disableErrorReset) {
            this.resetRoutePriceErrorState();
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            if (!options?.disablePriceReset) {
                this.resetPrices({ state });
            }
            this.setPetQuoteOptions(state, petData, options);
            this.setPetPricing(state, petData, options);
            this.setPetDetails(state, petData, options);
            this.validateState(state);
        });
        this.dispatchManager();
        return petData;
    };

    //---------------QUOTE_OPTIONS---------------//
    private setPetQuoteOptions = (
        state: ClientBasketState,
        petData: RoutePricePetV2,
        options?: DispatchPetOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'quoteOptions')) {
            return;
        }
        const bmState = this.getBmState();
        const petItem = this.getQuotePetItem(petData);
        state.quoteOptions.pets.unselectedList = [...bmState.unselectedPets];
        state.quoteOptions.pets.list[petData.index] = petItem;
        state.quoteOptions.pets.canAddPet =
            bmState.routePrice.pets.length < bmState.basketDataProcessed.pets.limit;
        state.quoteOptions.pets.limit = bmState.basketDataProcessed.pets.limit;
    };

    private getQuotePetItem = (
        petData: RoutePricePetV2,
        // options?: DispatchPetOptions,
    ): PetListItem => {
        const passengerData = this.passengerGetPasRoutePrice(petData.passengerId);
        const savedPassenger = this.passengerGetSavedDictionaryNoThrow(passengerData.id);
        return {
            petId: petData.id,
            index: petData.index,
            passengerLabel: savedPassenger
                ? this.passengerGetNameLabel(savedPassenger)!
                : this.passengerGetIndex(passengerData),
            label: this.petGetCategoryLabel(petData),
        };
    };

    //---------------PRICING---------------//
    private setPetPricing = (
        state: ClientBasketState,
        petData: RoutePricePetV2,
        options?: DispatchPetOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'pricing')) {
            return;
        }
        const bmState = this.getBmState();

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const tripDic = this.getTripDictionary(trip.key);
            const skipTrip = options?.petReqPerTrip && !options.petReqPerTrip?.[trip.key];
            if (skipTrip) {
                continue;
            }
            //Error
            state.pricing.trips[tripIndex].validation.error =
                bmState.routePrice.trips[tripIndex].error;

            const petItem = this.getPricingPetItem(petData, options, tripDic);
            state.pricing.trips[tripIndex].pets[petData.index] = petItem;
        }
    };

    private getPricingPetItem = (
        petData: RoutePricePetV2,
        options: DispatchPetOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ): SelectTicketPetItem => {
        const { selectedTicket, availableTickets } = this.getPricingPetTicket(
            petData,
            options,
            tripDic,
        );
        const bmState = this.getBmState();
        const petItem: SelectTicketPetItem = {
            petId: petData.id,
            index: petData.index,
            //
            tripIndex: tripDic.index,
            tripKey: tripDic.key,
            //
            label: this.petGetIndex(petData),
            description: this.petGetNameOrCategory(petData),
            //
            selectedTicket,
            availableTickets,
            //
            // hasExtraData: !!Object.keys(petData.extraPriceData || {}).length,
            hasExtraData: false,
            requiresExtraData: false,
            //
            value: this.formatPrice(
                bmState.routePrice.trips[tripDic.index].accPrices[petData.id]?.total,
            ),
        };
        return petItem;
    };

    private getPricingPetTicket = (
        petData: RoutePricePetV2,
        _options: DispatchPetOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();
        const petReq = bmState.routePrice.trips[tripDic.index].petAccReq[petData.index];
        const petTickets = tripDic.petAcc;
        const petDic =
            tripDic.petCatAccDic[petData.petCategory].accommodationsDic[petReq.petCode];

        let selectedTicket: SelectedTicket = {
            code: petDic.code,
            //
            label: petDic.name,
            description: petDic.description,
            //
            customCancelPolicy: petDic.cancelPolicy,
            customModifyPolicy: petDic.modifyPolicy,
            nonRefundable: petDic.nonRefundable,
            nonAmendable: petDic.nonAmendable,
            applyToOthers: false,
        };
        const availableTickets: SelectTicketEditPetItem[] = [];
        for (const petTicket of petTickets) {
            const isSelected = petTicket.code === petReq.petCode;
            const mappedTicket: SelectTicketEditPetItem = {
                code: petTicket.code,
                //
                label: petTicket.name,
                description: petTicket.description,
                //
                isSelected,
                //
                customCancelPolicy: petTicket.cancelPolicy,
                customModifyPolicy: petTicket.modifyPolicy,
                nonRefundable: petTicket.nonRefundable,
                nonAmendable: petTicket.nonAmendable,
            };
            availableTickets.push(mappedTicket);
        }

        return { selectedTicket, availableTickets };
    };

    //---------------TRIP_DETAILS---------------//

    private setPetDetails = (
        state: ClientBasketState,
        petData: RoutePricePetV2,
        options?: DispatchPetOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'tripDetails')) {
            return;
        }
        const bmState = this.getBmState();
        const savedPet = this.petGetSavedDictionaryNoThrow(petData.id);

        const petDetail: ClientStatePetTripDetail = {
            petId: petData.id,
            details: {
                title: this.trns('app.passengerTicketSelection.pet', {
                    count: petData.index + 1,
                }),
                subTitle: savedPet?.name || this.petGetCategoryLabel(petData),
                complete: false,
            },
            name: this.generatePetFieldInput(petData, 'name', {})!,
            // passengerId: this.generatePetFieldInput(petData, 'passengerId', {})!,
            //
        };
        const validation: RoutePetValidation = {
            ...bmState.basketDataProcessed.pets.validation,
            ...bmState.routePrice.petExtraValidation,
        };

        const additional = this.petGetAdditionalDetails(petData, validation);

        const newPetDetails: ClientStatePetTripDetail = {
            ...petDetail,
            ...additional.petDetails,
        };

        const valid = this.validatePetDetails(additional.fieldOptionsArray);

        if (valid) {
            newPetDetails.details.complete = true;
        }

        state.details.pets[petData.index] = newPetDetails;
    };

    private validatePetDetails = (fieldInputs: FieldInput[]) => {
        return fieldInputs.every((item) => !item.required);
    };

    private generatePetFieldInput = (
        petData: RoutePricePetV2,
        fieldId: PetFieldNames,
        options: {
            required?: boolean;
            canBeRemoved?: boolean;
            validation?: keyof RoutePetValidation;
            isPricing?: boolean;
        },
    ): FieldInput | undefined => {
        const savedPet = this.petGetSavedDictionaryNoThrow(petData.id);
        const extraPetData = petData.extraPriceData;
        const { required, validation, canBeRemoved, isPricing } = options;
        switch (fieldId) {
            //Pet
            case 'name': {
                const value = extraPetData?.name ?? savedPet?.name ?? petData.name;
                return {
                    fieldId: 'name',
                    type: 'text',
                    label: this.trns('app.basketManager.name'),
                    value,
                    disabled: !!extraPetData?.name || !!savedPet?.name,
                    valueFromSaved: !!savedPet?.name,
                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: false,
                    optional: true,
                };
            }
            //Accompanying passenger
            // case 'passengerId': {
            // const bmState = this.getBmState();
            //     const value = petData.passengerId;
            //     return {
            //         fieldId: 'passengerId',
            //         type: 'list',
            //         listSelect: bmState.routePrice.passengers.map((pas) => ({
            //             id: pas.id,
            //             text: this.passengerGetLabelOrIndex(pas),
            //         })),
            //         label: this.trns('app.basketManager.accompanyPassenger'),
            //         value,
            //         disabled: !!extraPetData?.name || !!savedPet?.name,
            //         valueFromSaved: !!savedPet?.name,
            //         petValidation: canBeRemoved ? validation : undefined,
            //         canBeRemoved,
            //         required: !value,
            //     };
            // }
            case 'country': {
                const value =
                    extraPetData?.country || savedPet?.country || petData.country;
                return {
                    fieldId: 'country',
                    type: 'list',
                    label: this.trns('app.basketManager.selectCountry'),
                    placeholder: this.trns('app.basketManager.selectCountry'),
                    value,
                    disabled:
                        (!!extraPetData?.country && !isPricing) || !!savedPet?.country,

                    valueFromSaved: !!savedPet?.country,
                    valueFromPricing:
                        !!extraPetData?.country && !isPricing && !savedPet?.country,
                    //TODO Country list
                    listSelect: [],
                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'weight': {
                const value = extraPetData?.weight || savedPet?.weight || petData.weight;
                return {
                    fieldId: 'weight',
                    type: 'number',
                    label: this.trns('app.basketManager.weight', { unit: 'm' }),
                    placeholder: this.trns('app.basketManager.weight', { unit: 'm' }),
                    value,
                    disabled:
                        (!!extraPetData?.weight && !isPricing) || !!savedPet?.weight,
                    min: petData.minWeight,
                    max: petData.maxWeight,
                    valueFromSaved: !!savedPet?.weight,
                    valueFromPricing:
                        !!extraPetData?.weight && !isPricing && !savedPet?.weight,
                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'identityDocType': {
                const value =
                    extraPetData?.identityDocType ||
                    savedPet?.identityDocType ||
                    petData.identityDocType;
                return {
                    fieldId: 'identityDocType',
                    type: 'list',
                    label: this.trns('app.basketManager.documentType'),
                    value,
                    disabled:
                        (!!extraPetData?.identityDocType && !isPricing) ||
                        !!savedPet?.identityDocType,
                    valueFromSaved: !!savedPet?.identityDocType,
                    valueFromPricing:
                        !!extraPetData?.identityDocType &&
                        !isPricing &&
                        !savedPet?.identityDocType,
                    listSelect: [
                        {
                            id: 'passport',
                            text: this.trns('app.basketManager.passport'),
                        },
                        {
                            id: 'id',
                            text: this.trns('app.basketManager.medId'),
                        },
                    ],
                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'identityDocNum': {
                const value =
                    extraPetData?.identityDocNum ||
                    savedPet?.identityDocNum ||
                    petData.identityDocNum;
                return {
                    fieldId: 'identityDocNum',
                    type: 'text',
                    label: this.trns('app.basketManager.documentNumber'),
                    value,
                    disabled:
                        (!!extraPetData?.identityDocNum && !isPricing) ||
                        !!savedPet?.identityDocNum,
                    valueFromSaved: !!savedPet?.identityDocNum,
                    valueFromPricing:
                        !!extraPetData?.identityDocNum &&
                        !isPricing &&
                        !savedPet?.identityDocNum,

                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'passportDoc': {
                const value =
                    extraPetData?.passportDoc ||
                    savedPet?.passportDoc ||
                    petData.passportDoc;
                return {
                    fieldId: 'passportDoc',
                    type: 'text',
                    label: this.trns('app.basketManager.passport'),
                    value,
                    disabled:
                        (!!extraPetData?.passportDoc && !isPricing) ||
                        !!savedPet?.passportDoc,
                    valueFromSaved: !!savedPet?.passportDoc,
                    valueFromPricing:
                        !!extraPetData?.passportDoc &&
                        !isPricing &&
                        !savedPet?.passportDoc,

                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'medicalDoc': {
                const value =
                    extraPetData?.medicalDoc ||
                    savedPet?.medicalDoc ||
                    petData.medicalDoc;
                return {
                    fieldId: 'medicalDoc',
                    type: 'text',
                    label: this.trns('app.basketManager.medId'),
                    value,
                    disabled:
                        (!!extraPetData?.medicalDoc && !isPricing) ||
                        !!savedPet?.medicalDoc,
                    valueFromSaved: !!savedPet?.medicalDoc,
                    valueFromPricing:
                        !!extraPetData?.medicalDoc && !isPricing && !savedPet?.medicalDoc,
                    petValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            default:
                return undefined;
        }
    };

    petGetAdditionalDetails = (
        petData: RoutePricePetV2,
        validation: RoutePetValidation | undefined,
        options?: { isPricing?: boolean },
    ) => {
        if (!validation) {
            return {
                petDetails: {},
                fieldOptionsArray: [],
            };
        }
        const bmState = this.getBmState();
        const petExtraValidation = bmState.routePrice.petExtraValidation || {};

        const petDetails: Optional<ClientStatePetTripDetail> = {};

        const fieldOptionsArray: FieldInput[] = [];

        if (validation.country) {
            const fieldInput = this.generatePetFieldInput(petData, 'country', {
                validation: 'weight',
                required: true,
                canBeRemoved: petExtraValidation['country'],
                ...options,
            });
            if (fieldInput) {
                petDetails.country = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        if (validation.weight) {
            const fieldInput = this.generatePetFieldInput(petData, 'weight', {
                required: true,
                validation: 'weight',
                canBeRemoved: petExtraValidation['weight'],
                ...options,
            });
            if (fieldInput) {
                petDetails.weight = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        if (validation.passportOrMedicalDoc) {
            if (petData.passportDoc) {
                const fieldInput = this.generatePetFieldInput(petData, 'passportDoc', {
                    required: true,
                    canBeRemoved: petExtraValidation['passportDoc'],
                    ...options,
                });
                if (fieldInput) {
                    petDetails.passportDoc = fieldInput;
                    fieldOptionsArray.push(fieldInput);
                }
            } else if (petData.medicalDoc) {
                const fieldInput = this.generatePetFieldInput(petData, 'medicalDoc', {
                    required: true,
                    canBeRemoved: petExtraValidation['medicalDoc'],
                    ...options,
                });
                if (fieldInput) {
                    petDetails.medicalDoc = fieldInput;
                    fieldOptionsArray.push(fieldInput);
                }
            } else {
                const identityDocTypeFieldInput = this.generatePetFieldInput(
                    petData,
                    'identityDocType',
                    {
                        required: true,
                        canBeRemoved: petExtraValidation['passportOrMedicalDoc'],
                        ...options,
                    },
                );
                if (identityDocTypeFieldInput) {
                    petDetails.identityDocType = identityDocTypeFieldInput;
                    fieldOptionsArray.push(identityDocTypeFieldInput);
                }
                const identityDocNumFieldInput = this.generatePetFieldInput(
                    petData,
                    'identityDocNum',
                    {
                        required: true,
                        canBeRemoved: petExtraValidation['passportOrMedicalDoc'],
                        ...options,
                    },
                );
                if (identityDocNumFieldInput) {
                    petDetails.identityDocNum = identityDocNumFieldInput;
                    fieldOptionsArray.push(identityDocNumFieldInput);
                }
            }
        } else if (validation.passportDoc) {
            const fieldInput = this.generatePetFieldInput(petData, 'passportDoc', {
                required: true,
                canBeRemoved: petExtraValidation['passportDoc'],
                ...options,
            });
            if (fieldInput) {
                petDetails.passportDoc = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        } else if (validation.medicalDoc) {
            const fieldInput = this.generatePetFieldInput(petData, 'medicalDoc', {
                required: true,
                canBeRemoved: petExtraValidation['medicalDoc'],
                ...options,
            });
            if (fieldInput) {
                petDetails.medicalDoc = fieldInput;
                fieldOptionsArray.push(fieldInput);
            }
        }
        return {
            petDetails,
            fieldOptionsArray,
        };
    };

    //---------------UTILS---------------//

    petGetNameOrCategory = (petData: RoutePricePetV2) => {
        if (petData.name) {
            return petData.name;
        }
        return this.petGetCategoryLabel(petData);
    };

    petGetIndex = (petData: RoutePricePetV2) => {
        return this.trns('app.passengerTicketSelection.pet', {
            count: petData.index + 1,
        });
    };

    petGetCategoryLabel = (petData: { petCategory: TicketPetCategory }) => {
        switch (petData.petCategory) {
            case 'dog':
                return this.trns('app.basketManager.dog');
            case 'cat':
                return this.trns('app.basketManager.cat');
            case 'guide-dog':
                return this.trns('app.basketManager.guideDog');
            default:
                return this.trns('app.tripDetails.pet');
        }
    };
}
