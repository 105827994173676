import React, { useEffect } from "react";
import { compose, withProps } from "recompose";
import {
  GoogleMap,
  Polyline,
  withScriptjs,
  withGoogleMap,
  OverlayView,
} from "react-google-maps";

import { getInitialRegion } from "./Maps.utils";
import { GoogleMapsProps } from "./Maps.types";
import { MAP_THEME_WEB } from "./Maps.theme";
import { View } from "../View";
import { AppThemeProvider, useAppTheme } from "../../theme";
import { VStack } from "../Stack";
import { Text } from "../Text";
import { Icon } from "../Icon";

if (!GoogleMap) {
  throw new Error("Please 'yarn add react-google-maps'");
}

export type IInjectedProps = GoogleMapsProps;
export type IHocProps<T> = Omit<T, keyof IInjectedProps>;

const withCompose = <T extends GoogleMapsProps>(
  Component: React.ComponentType<T>
): React.ComponentType<IHocProps<T>> => {
  return compose<T, IHocProps<T>>(
    withProps((props: GoogleMapsProps) => {
      return {
        googleMapURL: props.webApiKey,
        loadingElement: <div style={{ height: "100%" }} />,
        containerElement: (
          <div
            style={{
              // width: `${props.height || 400}px`,
              height: `${props.height || 400}px`,
            }}
          />
        ),
        mapElement: <div style={{ height: "100%" }} />,
      };
    }),
    withScriptjs,
    withGoogleMap
  )(Component as any);
};

const getPixelPositionOffset = (width: number, height: number) => {
  return {
    x: -(width / 2),
    y: -height,
  };
};

export const __DONT__IMPORT__GoogleMaps = withCompose((props) => {
  const {
    // id,
    markers,
    polylines,
    // // width,
    // // height,
    // containerStyle,
    // theme,
    // onMarkerCreate,
    onFitToBoundsCallBack,
    containerProps,
  } = props;
  const initialPosition = getInitialRegion(markers, 2);
  const ref = React.useRef<{ map: GoogleMap | null }>({ map: null });
  const theme = useAppTheme();
  useEffect(() => {
    const fitToBounds = () => {
      // @ts-expect-error
      if (google && polylines) {
        // @ts-expect-error
        const bounds = new google.maps.LatLngBounds();
        const coords = polylines.flatMap((polyline) => polyline.path);
        for (let i = 0; i < coords.length; i++) {
          bounds.extend({ lng: coords[i].lng, lat: coords[i].lat });
        }
        ref.current.map?.fitBounds(bounds);
      }
    };
    onFitToBoundsCallBack?.(fitToBounds);
    // fitToBounds();
  }, [polylines]);

  return (
    <View {...containerProps}>
      {
        // @ts-expect-error
        <GoogleMap
          defaultZoom={Math.round(
            Math.log(360 / initialPosition.longitudeDelta) / Math.LN2
          )}
          defaultCenter={{
            lat: initialPosition.latitude,
            lng: initialPosition.longitude,
          }}
          options={{
            streetViewControl: false,
            fullscreenControl: false,
            mapTypeControl: false,
            styles: MAP_THEME_WEB,
            draggableCursor: "default",

            // maxZoom: 12,
            // minZoom: 12,
          }}
          onRightClick={(e) => {
            const coords = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            };

            props.onPress?.(coords);
          }}
          onClick={(e: any) => {
            const coords = {
              lat: e.latLng.lat(),
              lng: e.latLng.lng(),
            };

            props.onPress?.(coords);
          }}
          ref={(googleRef) => {
            ref.current.map = googleRef;
          }}
        >
          {markers
            ? markers.map((marker, index) => {
                return (
                  <OverlayView
                    key={index}
                    mapPaneName={OverlayView.OVERLAY_LAYER}
                    position={{ lat: marker.lat, lng: marker.lng }}
                    getPixelPositionOffset={getPixelPositionOffset}
                  >
                    <AppThemeProvider theme={theme}>
                      <VStack
                        style={[
                          { alignItems: "center", zIndex: marker.zIndex },
                          marker.opacity === undefined
                            ? undefined
                            : { opacity: marker.opacity },
                        ]}
                      >
                        {marker.label ? (
                          <View
                            bg={marker.labelColor || "primary-dark"}
                            br={8}
                            p="2"
                          >
                            <Text.Body2Regular
                              color="white"
                              bold
                              align="center"
                            >
                              {marker.label}
                            </Text.Body2Regular>
                          </View>
                        ) : null}
                        <Icon
                          color={marker.iconColor || "primary-dark"}
                          icon={marker.icon || "location-pin"}
                          size={marker.iconSize}
                          iconStyle={marker.iconStyle}
                        />
                      </VStack>
                    </AppThemeProvider>
                  </OverlayView>
                );
              })
            : null}
          {polylines
            ? polylines.map((polyline, index) => {
                const options = polyline.dashed
                  ? {
                      strokeOpacity: 0,
                      fillOpacity: 0,
                      zIndex: polyline.zIndex,

                      icons: [
                        {
                          icon: {
                            path: "M 0,-1 0, 0",
                            strokeOpacity: polyline.opacity || 1,
                            scale: 5,
                            strokeColor: polyline.color,
                          },
                          offset: "0",
                          repeat: "15px",
                        },
                      ],
                      strokeWeight: polyline.weight,
                    }
                  : {
                      strokeWeight: polyline.weight,
                      strokeColor: polyline.color,
                      strokeOpacity: polyline.opacity,
                      zIndex: polyline.zIndex,
                    };

                return (
                  <Polyline
                    key={index + "-" + polyline.path.length}
                    path={polyline.path}
                    options={options}
                  />
                );
              })
            : null}
        </GoogleMap>
      }
    </View>
  );
}) as any as (props: GoogleMapsProps) => JSX.Element;
