import { isNativeMobile } from '../../../../utils/utils.helper';
import { FormBuilderImport } from './FormBuilder.Import';
import { FormList, FormSectionList } from './FormBuilder.List';
import type { FormBuilderProps } from './FormBuilder.types';

export const FormBuilder = <T,>(props: FormBuilderProps<T>) => {
  const { data, form, initialValue, value } = props;
  const sectionListMode = props.flatView || isNativeMobile;
  return sectionListMode ? (
    <FormSectionList
      configList={data}
      form={form}
      initialValue={initialValue}
      value={value}
      readonly={props.readonly}
      extensions={props.extensions}
      listStyle={props.listStyle}
      itemStyle={props.itemStyle}
      contentContainerStyle={props.contentContainerStyle}
    />
  ) : (
    <FormList
      configList={data}
      form={form}
      initialValue={initialValue}
      value={value}
      readonly={props.readonly}
      extensions={props.extensions}
      listStyle={props.listStyle}
      itemStyle={props.itemStyle}
      contentContainerStyle={props.contentContainerStyle}
    />
  );
};

FormBuilderImport.FormBuilder = FormBuilder;
