import { ReactNode } from "react";
import { isWeb } from "../../utils/utils.helper";
import { ActionSheetFullModal, ActionSheetModal } from "./Modal.ActionSheet";
import { ModalPopup } from "./Modal.Popup";
import { modalSelector } from "./Modal.selector";
import type { ModalCreateOptions } from "./Modal.types";

const selectModalBasedOnOptions = (
  modal: ReactNode,
  closeModal: (_key: string) => void,
  { key, type, modalStyle }: ModalCreateOptions
) => {
  const ModalPopupComponent = (
    <ModalPopup
      onClose={() => closeModal(key)}
      modalId={key}
      modalStyle={modalStyle}
    >
      {modal}
    </ModalPopup>
  );

  switch (type) {
    case "action-sheet": {
      if (isWeb) {
        return ModalPopupComponent;
      }

      return (
        <ActionSheetModal
          onClose={() => closeModal(key)}
          modalId={key}
          modalStyle={modalStyle}
        >
          {modal}
        </ActionSheetModal>
      );
    }
    case "action-sheet-full": {
      if (isWeb) {
        return ModalPopupComponent;
      }
      return (
        <ActionSheetFullModal
          onClose={() => closeModal(key)}
          modalId={key}
          modalStyle={modalStyle}
        >
          {modal}
        </ActionSheetFullModal>
      );
    }

    case "pop-up":
    default: {
      return ModalPopupComponent;
    }
  }
};

modalSelector.selectModalBasedOnOptions = selectModalBasedOnOptions;
