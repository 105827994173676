import React from "react";
import { networkConnection } from "../../utils/network/network.connection";
import { translate } from "../../lang/lang";
import { FeedbackLayout } from "./Feedback.Layout";
import { VStack } from "../Stack";
import { LoadingIndicator } from "../Loading";
import UnexpectedError from "../../assets/Feedback/unexpected-error.svg";
import Offline from "../../assets/Feedback/offline.svg";

export const ContentFeedbackHandler = <T,>({
  data,
  isLoading,
  dataEmpty,
  EmptyDataFeedBack,
  LoadingFeedback,
  noLoader,
  children,
  removeTopMargin,
  ErrorFeedBack,
  feedback,
}: {
  data: T | undefined;
  isLoading: boolean;
  dataEmpty?: boolean;
  EmptyDataFeedBack?: JSX.Element;
  children: React.ReactNode;
  LoadingFeedback?: JSX.Element;
  noLoader?: boolean;
  removeTopMargin?: boolean;
  ErrorFeedBack?: JSX.Element;
  feedback?: string;
}): JSX.Element | null => {
  if (networkConnection.isOfflineWithRtk(data, isLoading)) {
    return (
      <FeedbackLayout
        removeTopMargin={removeTopMargin}
        image={Offline}
        text2={translate("app.connection.youAreOffline")}
        text3={translate("app.connection.checkYourInternet")}
      />
    );
  }

  if (data) {
    if (dataEmpty) {
      return EmptyDataFeedBack || null;
    }
    return <>{children}</>;
  }

  if (!data && !isLoading) {
    if (ErrorFeedBack) {
      return ErrorFeedBack;
    }
    return (
      <FeedbackLayout
        removeTopMargin={removeTopMargin}
        image={UnexpectedError}
        text2={feedback || translate("app.feedback.unexpectedError")}
        // text3={translate("app.feedback.pleaseTryAgain")}
      />
    );
  }

  if (noLoader) {
    return null;
  }

  if (LoadingFeedback) {
    return LoadingFeedback;
  }

  return (
    <VStack align="center" justify="center" flex={1}>
      <LoadingIndicator size={24} />
    </VStack>
  );
};
