import {
  SafeAreaView as SafeAreaViewRN,
  StyleSheet,
  ViewProps,
} from "react-native";
import { useSafeAreaInsets } from "./SafeAreaView.utils";

export const SafeAreaView = (
  props: ViewProps & { disableBottom?: boolean; disable?: boolean }
) => {
  const insets = useSafeAreaInsets();

  if (props.disable) {
    return <>{props.children}</>;
  }
  return (
    <SafeAreaViewRN
      {...props}
      style={[
        {
          paddingTop: insets.top,
          paddingBottom: insets.bottom,
        },
        props.disableBottom && { paddingBottom: 0 },
        styles.container,
        props.style,
      ]}
    />
  );
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: "white",
    flex: 1,
    alignItems: "stretch",
    justifyContent: "flex-start",
  },
});
