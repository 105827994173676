import { BasketManager } from '@naus-code/naus-basket-manager';
import { createSlice } from '@reduxjs/toolkit';
import React from 'react';
import { Store } from 'redux';

export function createBasketSlice(basketManager: BasketManager) {
  return createSlice({
    name: 'basketSlice',
    initialState: basketManager.getInitialState(),
    reducers: {
      setBasket: basketManager.setBasketStateListener,
    },
  });
}

export type BasketManagerV2Args = Omit<
  ConstructorParameters<typeof BasketManager>['0'],
  'dispatchBasketAction' | 'dispatchManager'
>;

export interface ConfiguredBasketManager {
  basketStoreRef: React.MutableRefObject<Store | null>;
  basketManager: BasketManager;
}
export function createBasketManager(
  args: BasketManagerV2Args,
  store: React.MutableRefObject<Store | null>,
): ConfiguredBasketManager {
  return {
    basketStoreRef: store,
    basketManager: new BasketManager({
      dispatchBasketAction: () => {
        if (!store.current) {
          return;
        }
        store.current.dispatch({ type: 'basketSlice/setBasket' });
      },
      ...args,
      dispatchManager: () => {},
    }),
  };
}
