import { BasketManagerVehicleDetails } from './bm.7.veh.details';

export class BasketManagerInternal extends BasketManagerVehicleDetails {
    continueToPrice = (): boolean => {
        this._log('continueToPrice');
        const { routePrice } = this.getBmState();
        const { passengers, pets, vehicles } = routePrice;
        this.dispatchBasket((state) => {
            for (let tripIndex = 0; tripIndex < state.pricing.trips.length; tripIndex++) {
                state.pricing.trips[tripIndex].validation.error =
                    routePrice.trips[tripIndex].error;
            }
            this.dispatchTariff({
                state,
                disablePriceReset: true,
                disableErrorReset: true,
                selectiveUpdate: {
                    pricing: true,
                },
            });
            for (
                let passengerIndex = 0;
                passengerIndex < passengers.length;
                passengerIndex++
            ) {
                const passenger = routePrice.passengers[passengerIndex];
                this.dispatchPassenger(passenger, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
            for (let petIndex = 0; petIndex < pets.length; petIndex++) {
                const pet = routePrice.pets[petIndex];
                this.dispatchPet(pet, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
            for (let vehicleIndex = 0; vehicleIndex < vehicles.length; vehicleIndex++) {
                const vehicle = routePrice.vehicles[vehicleIndex];
                this.dispatchVehicle(vehicle, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
        });
        return true;
    };

    continueToDetails = (): boolean => {
        this._log('continueToDetails');
        const { routePrice } = this.getBmState();
        const { passengers, pets, vehicles } = routePrice;
        this.dispatchBasket((state) => {
            for (let tripIndex = 0; tripIndex < state.pricing.trips.length; tripIndex++) {
                state.pricing.trips[tripIndex].validation.error =
                    routePrice.trips[tripIndex].error;
            }
            this.dispatchTariff({
                state,
                disablePriceReset: true,
                disableErrorReset: true,
                selectiveUpdate: {
                    tripDetails: true,
                },
            });
            for (
                let passengerIndex = 0;
                passengerIndex < passengers.length;
                passengerIndex++
            ) {
                const passenger = routePrice.passengers[passengerIndex];
                this.dispatchPassenger(passenger, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
            for (let petIndex = 0; petIndex < pets.length; petIndex++) {
                const pet = routePrice.pets[petIndex];
                this.dispatchPet(pet, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
            for (let vehicleIndex = 0; vehicleIndex < vehicles.length; vehicleIndex++) {
                const vehicle = routePrice.vehicles[vehicleIndex];
                this.dispatchVehicle(vehicle, {
                    state,
                    disablePriceReset: true,
                    disableErrorReset: true,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
        return true;
    };
}
