import { translate } from "../../../lang/lang";
import type { FormHookProp } from "../Form/Form.types";
import { useInputForm } from "../Inputs.utils";
import {
  convertNumberToString,
  convertStringToNumber,
  removeCommas,
} from "./NumberInput.utils";

const multipleOfValue = (
  value: number | undefined,
  props: FormHookProp<string, "number">,
  multiply: boolean
) => {
  const multipleOf = props.config.multipleOf;
  if (typeof multipleOf === "number") {
    if (typeof value === "number") {
      if (Number.isNaN(value)) {
        return undefined;
      }
      return multiply ? value * multipleOf : value / multipleOf;
    }
  }
  return value;
};

export const useNumberForm = (props: FormHookProp<string, "number">) => {
  return useInputForm({
    ...props,
    initialValue: convertNumberToString(
      multipleOfValue(
        (props.initialValue ?? props.config.initialValue) as number | undefined,
        props,
        false
      )
    ),
    value: convertNumberToString(
      multipleOfValue(props.value as number | undefined, props, false)
    ),
    test: (numberText) => {
      return !Number.isNaN(Number(removeCommas(numberText)));
    },
    formatValue: (v) => {
      return convertNumberToString(multipleOfValue(v, props, false));
    },
    errorFeedback: translate("app.inputs.invalidNumber"),
    getValue: (txt) => {
      const newNumber =
        txt === "" || txt === undefined
          ? undefined
          : convertStringToNumber(removeCommas(txt));
      return multipleOfValue(newNumber, props, true);
    },
  });
};
