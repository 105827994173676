import { onlyUnique } from '../../../utils/utils.helper';
import type {
  FormHandlerProps,
  FormValidationRegisterProp,
  GlobalFormActions,
  ShowFeedback,
} from './Form.types';
import { filterFormInputs } from './Form.utils';

export class FormHandler {
  private _inputs: FormValidationRegisterProp<any>[] = [];

  private _parentListeners: {
    [key: string]: FormValidationRegisterProp<any>[] | undefined;
  } = {};

  private _onInputChange?: <T>(id: string, arg: T, fullValue: any) => void;
  onMount?: () => void;

  globals: GlobalFormActions;

  constructor(props?: FormHandlerProps) {
    this._onInputChange = props?.onChange;
    this.onMount = props?.onMount;
    this.globals = props?.globals || {};
  }

  registerInput = <T>(props: FormValidationRegisterProp<T>) => {
    this.unregisterInput(props.id);
    this._inputs.unshift(props);
    this._subscribeToCondition(props);
  };

  private _subscribeToCondition = <T>(props: FormValidationRegisterProp<T>) => {
    // Condition mapping
    props.conditions?.forEach((c) => {
      const uniqueValues = c.values.map((cv) => cv.listenTo).filter(onlyUnique);
      uniqueValues.forEach((uv) => {
        const inputs = this._parentListeners[uv];
        if (inputs) {
          inputs.push(props);
        } else {
          this._parentListeners[uv] = [props];
        }
      });
    });
  };

  private _unsubscribeFromCondition = (id: string) => {
    const formInput = this._inputs.find((input) => input.id === id);
    if (formInput) {
      formInput.conditions?.forEach((c) => {
        const uniqueValues = c.values
          .map((cv) => cv.listenTo)
          .filter(onlyUnique);

        uniqueValues.forEach((listenToId) => {
          const inputs = this._parentListeners[listenToId];
          if (inputs) {
            this._parentListeners[listenToId] = inputs.filter(
              (inp) => inp.id !== id
            );
            if (!this._parentListeners[listenToId]?.length) {
              delete this._parentListeners[listenToId];
            }
          }
        });
      });
    }
  };

  unregisterInput = (id: string) => {
    this._unsubscribeFromCondition(id);
    this._inputs = this._inputs.filter((input) => input.id !== id);
  };

  getInputs = () => {
    return this._inputs;
  };

  setInputValue = (id: string, value: any) => {
    const index = this._inputs.findIndex((item) => item.id === id);
    const input = this._inputs[index]?.input;
    if (input && index !== -1) {
      input.setValue(value);
    }
  };

  isFormValid = (
    props: { keysToValidate?: string[]; showFeedback?: boolean } = {}
  ) => {
    const { keysToValidate = [], showFeedback = false } = props;
    const inputsToValidate = filterFormInputs(this._inputs, keysToValidate);

    if (!inputsToValidate.length) {
      return true;
    }

    if (showFeedback) {
      for (const input of inputsToValidate) {
        input.input.isValid({ showFeedback: true });
      }
    }

    return inputsToValidate.every(({ input }) => {
      return input.isValid();
    });
  };

  private _subscribersToValueChanges = new Set<
    (id: string, arg: any) => void
  >();

  onInputChange = <T>(id: string, arg: T) => {
    this._subscribersToValueChanges.forEach((cb) => cb?.(id, arg));
    this._onInputChange?.(
      id,
      arg,
      this.getValue({ showFeedback: false, validate: false })
    );
  };

  subscribeToValueChange = (callback: (id: string, arg: any) => void) => {
    this._subscribersToValueChanges.add(callback);
    return () => {
      this.unsubscribeToValueChange(callback);
    };
  };
  unsubscribeToValueChange = (callback: (id: string, arg: any) => void) => {
    return this._subscribersToValueChanges.delete(callback);
  };

  onParentChange = <T>(parentId: string, parentValue: T) => {
    const childInputs = this._parentListeners[parentId];
    if (childInputs) {
      for (const childInput of childInputs) {
        childInput.input?.onParentChange?.(parentId, parentValue);
      }
    }
  };

  getValue = <T>(options: ShowFeedback = {}) => {
    const { showFeedback = true, validate = true } = options;
    const constructedValue = {};
    this._inputs.forEach(({ input, id }) => {
      if (id.startsWith('blank')) {
        return;
      }
      constructedValue[id] = input.getValue();
    });
    let valid = false;
    if (validate) {
      valid = this.isFormValid({ showFeedback });
    }
    return {
      valid,
      value: constructedValue as T,
    };
  };

  getSingleValue = (options: {
    id: string;
    validate?: boolean;
    showFeedback?: boolean;
  }) => {
    const index = this._inputs.findIndex((item) => item.id === options.id);
    const input = this._inputs[index]?.input;

    if (!input) {
      console.error('Could not find input of ', options.id);
      return;
    }

    if (options.validate) {
      const isValid = input.isValid({ showFeedback: options.showFeedback });
      if (!isValid) {
        return;
      }
    }
    return input?.getValue();
  };
}
