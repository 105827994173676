import {
    CustomerClientVehicleV2,
    RoutePricePassengerV2,
    RoutePriceVehicleV2,
    TicketVehicleCategory,
    TicketVehicleEngineCategory,
} from '@naus-code/naus-client-types';
import { createError } from '../errors';
import { BasketManagerSetup } from './bm.1.setup';
import { ClientBasketState } from '../types/clientState';

export class BasketManagerCommon extends BasketManagerSetup {
    //---------------RESETS---------------//

    resetRoutePriceManagerState = () => {
        const bmState = this.getBmState();
        bmState.routePrice.trips.forEach((trip) => {
            trip.totalPrice = undefined;
            trip.accPrices = {};
        });
    };

    resetRoutePriceErrorState = () => {
        const bmState = this.getBmState();
        bmState.routePrice.trips.forEach((trip) => {
            trip.error = undefined;
        });
    };

    resetPrices = (options?: { state?: ClientBasketState }) => {
        const { routePrice } = this.getBmState();
        // const { passengers, pets, vehicles } = routePrice;
        this.resetRoutePriceManagerState();
        this.resetRoutePriceErrorState();
        this.dispatchBasketWrapper(options?.state, (state) => {
            state.pricing.value = undefined;
            state.pricing.validation.status = 'verify';
            for (let tripIndex = 0; tripIndex < state.pricing.trips.length; tripIndex++) {
                const trip = state.pricing.trips[tripIndex];
                trip.validation.error = routePrice.trips[tripIndex].error;
                trip.value = undefined;
                if (trip.tariff) {
                    trip.tariff!.value = undefined;
                }
                for (
                    let passengerIndex = 0;
                    passengerIndex < trip.passengers.length;
                    passengerIndex++
                ) {
                    trip.passengers[passengerIndex].value = undefined;
                }
                for (let petIndex = 0; petIndex < trip.pets.length; petIndex++) {
                    trip.pets[petIndex].value = undefined;
                }
                for (
                    let vehicleIndex = 0;
                    vehicleIndex < trip.vehicles.length;
                    vehicleIndex++
                ) {
                    trip.vehicles[vehicleIndex].value = undefined;
                }
            }
        });
    };

    //---------------PASSENGER_UTILS---------------//

    passengerGetNameLabel = (data?: { name?: string; lastName?: string }) => {
        return data?.name ? `${data.name} ${data.lastName}` : undefined;
    };

    passengerGetIndex = (passengerData: RoutePricePassengerV2) => {
        return this.trns('app.passengerTicketSelection.passenger', {
            count: passengerData.index + 1,
        });
    };

    passengerGetLabelOrIndex = (passengerData: RoutePricePassengerV2) => {
        if (passengerData?.name) {
            return `${passengerData.name} ${passengerData.lastName || ''}`;
        }
        return this.trns('app.passengerTicketSelection.passenger', {
            count: passengerData.index + 1,
        });
    };

    passengerGetLabelOrAgeGroup = (passengerData: RoutePricePassengerV2) => {
        if (passengerData?.name) {
            return `${passengerData.name} ${passengerData.lastName || ''}`;
        }
        return this.passengerGetAgeGroupLabel(passengerData);
    };

    passengerGetPasRoutePrice = (passengerId: string) => {
        const bmState = this.getBmState();
        const passengerData = bmState.routePrice.passengers.find(
            (pas) => pas.id === passengerId,
        );
        if (!passengerData) {
            throw createError({
                code: 'PASSENGER_ROUTE_PRICE_NOT_FOUND',
                data: { passengerId },
            });
        }
        return passengerData;
    };

    petGetPetRoutePrice = (petId: string) => {
        const bmState = this.getBmState();
        const petData = bmState.routePrice.pets.find((pas) => pas.id === petId);
        if (!petData) {
            throw createError({
                code: 'PET_ROUTE_PRICE_NOT_FOUND',
                data: { petId },
            });
        }
        return petData;
    };

    //---------------VEHICLE_UTILS---------------//
    //
    vehicleGetSavedDictionaryNoThrow = (
        vehicleId: string | undefined,
    ): CustomerClientVehicleV2 | undefined => {
        if (!vehicleId) {
            return undefined;
        }
        const bmState = this.getBmState();
        return bmState.savedVehiclesDic[vehicleId];
    };

    vehicleGetSavedDictionary = (vehicleId: string): CustomerClientVehicleV2 => {
        const vehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleId);
        if (!vehicle) {
            throw createError({ code: 'SAVED_VEHICLE_NOT_FOUND' });
        }
        return vehicle;
    };

    vehicleGetIndex = (vehicleData: RoutePriceVehicleV2) => {
        return this.trns('app.passengerTicketSelection.vehicle', {
            count: vehicleData.index + 1,
        });
    };

    vehicleGetLabelOrIndex = (vehicleData: RoutePriceVehicleV2) => {
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);
        if (savedVehicle?.plateNumber) {
            const modelName = this.vehicleGetModelNameFromCache(vehicleData, true);
            if (modelName) {
                return `${modelName} [${savedVehicle.plateNumber}]`;
            }
            return `${savedVehicle.plateNumber}`;
        }
        const modelName = this.vehicleGetModelNameFromCache(vehicleData);
        if (modelName) {
            return modelName;
        }
        return this.trns('app.passengerTicketSelection.vehicle', {
            count: vehicleData.index + 1,
        });
    };

    vehicleMapSizeLabel = (data: { length: number; height: number }) => {
        return `${data.length / 100}m x ${data.height / 100}m`;
    };

    vehicleMapEngineLabel = (data: { engineType: TicketVehicleEngineCategory }) => {
        switch (data.engineType) {
            case 'diesel':
                return this.trns('app.basketManager.quote.diesel');
            case 'petrol':
                return this.trns('app.basketManager.quote.petrol');
            case 'hybrid':
                return this.trns('app.basketManager.quote.hybrid');
            case 'plug-in':
                return this.trns('app.basketManager.quote.plug-in');
            case 'gas':
                return this.trns('app.basketManager.quote.gas');
            default:
                return data.engineType;
        }
    };

    vehicleGetLabelOrCategory = (vehicleData: RoutePriceVehicleV2) => {
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);
        if (savedVehicle?.plateNumber) {
            const modelName = this.vehicleGetModelNameFromCache(vehicleData, true);
            if (modelName) {
                return `${modelName} [${savedVehicle.plateNumber}]`;
            }
            return `${savedVehicle.plateNumber}`;
        }
        const modelName = this.vehicleGetModelNameFromCache(vehicleData);
        if (modelName) {
            return modelName;
        }
        return this.vehicleGetCategoryLabel(vehicleData.category);
    };

    vehicleGetLabelOrCategoryWithDetails = (vehicleData: RoutePriceVehicleV2) => {
        const savedVehicle = this.vehicleGetSavedDictionaryNoThrow(vehicleData.id);
        if (savedVehicle?.plateNumber) {
            const modelName = this.vehicleGetModelNameFromCache(vehicleData, true);
            if (modelName) {
                return `${modelName} [${savedVehicle.plateNumber}]`;
            }
            return `${savedVehicle.plateNumber}`;
        }
        const modelName = this.vehicleGetModelNameFromCache(vehicleData);
        if (modelName) {
            return modelName;
        }
        const sizeText = vehicleData.size
            ? ` (${this.vehicleMapSizeLabel(vehicleData.size)})`
            : '';
        const categoryText = this.vehicleGetCategoryLabel(vehicleData.category);
        return `${categoryText}${sizeText}`;
    };

    vehicleGetModelNameFromCache = (
        vehicleData: { category: TicketVehicleCategory; model?: { id: string } },
        modelOnly?: boolean,
    ) => {
        if (!vehicleData.model) {
            return undefined;
        }
        const cachedModel = this.getVehicleModelFromCache(
            vehicleData.category,
            vehicleData.model.id,
        );
        if (!cachedModel) {
            return undefined;
        }
        if (modelOnly) {
            return `${cachedModel.label}`;
        }
        return `${cachedModel.makeLabel} - ${cachedModel.label}`;
    };

    vehicleGetCategoryLabel = (category: TicketVehicleCategory) => {
        switch (category) {
            case 'car':
                return this.trns('app.passengerTicketSelection.car');
            case 'moto':
                return this.trns('app.passengerTicketSelection.moto');
            case 'bike':
                return this.trns('app.passengerTicketSelection.bike');
            case 'trike':
                return this.trns('app.passengerTicketSelection.trike');
            case 'quad':
                return this.trns('app.passengerTicketSelection.quad');
            case 'camper':
                return this.trns('app.passengerTicketSelection.camper');
            case 'trailer':
                return this.trns('app.passengerTicketSelection.trailer');
            case 'bus':
                return this.trns('app.passengerTicketSelection.bus');
            default:
                return this.trns('app.passengerTicketSelection.vehicle');
        }
    };

    vehicleGetVehRoutePriceNoThrow = (
        vehicleId: string,
    ): RoutePriceVehicleV2 | undefined => {
        const bmState = this.getBmState();
        const vehicleData = bmState.routePrice.vehicles.find(
            (veh) => veh.id === vehicleId,
        );
        return vehicleData;
    };

    vehicleGetVehRoutePrice = (vehicleId: string) => {
        const vehicleData = this.vehicleGetVehRoutePriceNoThrow(vehicleId);
        if (!vehicleData) {
            throw createError({
                code: 'VEHICLE_ROUTE_PRICE_NOT_FOUND',
                data: { vehicleId },
            });
        }
        return vehicleData;
    };

    DRIVER_MIN_AGE = 16;

    vehicleIsDriverEligible = (
        passenger: RoutePricePassengerV2,
        vehicleCategory: TicketVehicleCategory,
    ) => {
        switch (vehicleCategory) {
            case 'bike':
                return true;
            default:
                return passenger.maxAge >= this.DRIVER_MIN_AGE;
        }
    };

    //---------------PRICE_UTILS---------------//
    formatPrice = (price?: number) => {
        if (price === undefined) {
            return undefined;
        }
        return price;
    };
}
