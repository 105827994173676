import { AxiosError } from 'axios';
import { translate } from '../../lang/lang';
import { isJSON } from '../utils.helper';
import { getFirebaseError } from './firebase.error';
import { FetchBaseQueryError } from '@reduxjs/toolkit/dist/query';
import { SerializedError } from '@reduxjs/toolkit';
import { showToast } from '../../ui/Toast';

export interface ErrorObject {
  code: string;
  message: string;
}

export type ErrorResponse =
  | FetchBaseQueryError
  | SerializedError
  | ErrorObject
  | { error: unknown };

export interface RTKError {
  status: number;
  data: ErrorObject;
}
export interface RTKErrorResponse {
  error: unknown;
}

export function isRTKErrorResponse(res: any): res is RTKErrorResponse {
  return typeof res === 'object' && typeof res.error === 'object';
}

export function isRTKError(error: any): error is RTKError {
  return (
    typeof error.data === 'object' &&
    typeof error.status === 'number' &&
    isErrorResponse(error.data)
  );
}

export const isErrorResponse = (data: any): data is ErrorResponse => {
  const IS_SUCCESS_CODE = '-110';
  const response = getErrorResponse(data, undefined, IS_SUCCESS_CODE);
  if (response.code === IS_SUCCESS_CODE) {
    return false;
  }
  return true;
};

export interface ErrorProps {
  defaultMessage: string;
  error?: any;
}

export function isAxiosFetchError(error: any): error is AxiosError {
  return isJSON(error) && error?.hasOwnProperty('response');
}

const hasNestedErrorObj = (error: any): error is { error: any } => {
  return (
    isJSON(error) && error?.hasOwnProperty('error') && !!(error as any).error
  );
};

export const UNKNOWN_CODE = '-100';

export function isCodeMessageObject(error: any): error is ErrorObject {
  return (
    isJSON(error) &&
    error?.hasOwnProperty('code') &&
    error?.hasOwnProperty('message')
  );
}

const isFetchBaseQuery = (error: any): error is FetchBaseQueryError => {
  return (
    isJSON(error) &&
    error?.hasOwnProperty('status') &&
    error?.hasOwnProperty('data') &&
    !!(error as any).data
  );
};

export const getErrorResponse = (
  error: any,
  fallbackMessage?: string,
  isSuccessCode?: string
): ErrorObject => {
  const defaultMessage =
    fallbackMessage ?? translate('app.feedback.genericError');
  if (!error) {
    return { message: defaultMessage, code: isSuccessCode ?? UNKNOWN_CODE };
  }
  if (hasNestedErrorObj(error)) {
    return getErrorResponse(error.error, defaultMessage, isSuccessCode);
  }

  if (isAxiosFetchError(error)) {
    return getErrorResponse(
      error.response?.data,
      defaultMessage,
      isSuccessCode
    );
  }
  if (isCodeMessageObject(error)) {
    const codeMessageError = getFirebaseError(error);
    return { code: codeMessageError.code, message: codeMessageError.message };
  }
  if (isFetchBaseQuery(error)) {
    const data = error.data;
    return getErrorResponse(data, defaultMessage, isSuccessCode);
  }

  return {
    message: defaultMessage,
    code: isSuccessCode ?? UNKNOWN_CODE,
  };
};

export interface HandleResponseProps<T> {
  response: T | ErrorResponse;
  onSuccess?: (_: T) => void;
  onError?: (_: ErrorObject) => void;
  disableSuccessToast?: boolean;
  disableErrorToast?: boolean;
  successMessage?: string;
}

export const handleResponse = <T>(props: HandleResponseProps<T>) => {
  if (isErrorResponse(props.response)) {
    const errorRes = getErrorResponse(props.response);

    if (!props?.disableErrorToast) {
      showToast(errorRes.message, { type: 'error' });
    }

    props.onError?.(errorRes);
    return;
  }

  if (props.onSuccess) {
    if (!props?.disableSuccessToast) {
      showToast(props?.successMessage ?? translate('app.common.success'));
    }
    props.onSuccess(props.response);
  }
};
