/* eslint-disable @typescript-eslint/no-var-requires */
import type * as allTypes from "./Maps.web";
const { __DONT__IMPORT__GoogleMaps: GoogleMaps } =
  require(`./Maps${__PLATFORM__}`) as typeof allTypes;

if (!__PLATFORM__) {
  throw new Error(
    "To use GoogleMaps you must has platform set __PLATFORM__ set in webpack/metro bundler'"
  );
}

export { GoogleMaps };
