import { dayjs } from '../../../utils/utils.date';
import { CalenderDatePicker } from '../../Calender/Calender.DatePicker';
import type { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { FormInput } from '../FormInputs/FormInput';
import { useDateForm } from './DateInput.hook';

export interface DateInputProps extends CommonInputProps<string, 'date'> {
  calender?: boolean;
}

export const DateInput = ({ calender, ...inputProps }: DateInputProps) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    error,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
    config,
  } = useDateForm(inputProps);
  const dateFormat = inputProps.config?.format || 'YYYY-MM-DD';

  return (
    <FormInput
      noFeedback={noFeedback}
      feedbackOnlyWithError={config.feedbackOnlyWithError}
      autoCorrect={false}
      autoCapitalize="none"
      value={value}
      onChangeText={(txt) => setValue(txt)}
      feedback={feedback}
      error={error}
      disabled={disabled}
      label={label}
      optional={optional}
      readonly={readonly}
      rowProps={inputProps.rowProps}
      onBlur={() => {
        isValid({ showFeedback: true, onBlur: true });
      }}
      iconEnd={
        calender ? (
          <CalenderDatePicker
            calenderOptions={{
              dateFormat,
              minDate: dayjs().format(dateFormat),
              maxDate: dayjs().add(2, 'years').format(dateFormat),
              numberOfSelectiveDates: 1,
              doubleView: true,
              onDone: (dates) => {
                setValue(dates[0]);
                isValid({ showFeedback: true });
              },
            }}
          />
        ) : undefined
      }
    />
  );
};
