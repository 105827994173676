import React, { Children, ReactNode } from "react";
import { View as RNView, ViewProps } from "react-native";
import { Spacing, useAppTheme } from "../../theme";
import { BaseViewProps, View } from "../View";
import { isNativeMobile } from "../../utils/utils.helper";

export interface Props {
  space?: keyof Spacing;
}

export type StackProps = Props & BaseViewProps & ViewProps;

const addGapToChildren = (children: ReactNode, gap: number) => {
  const [first, ...rest] = Children.toArray(children);
  return (
    <>
      {first}
      {rest.map((child, ix) => (
        <React.Fragment key={ix}>
          <RNView
            style={{ backgroundColor: "transparent", width: gap, height: gap }}
          />
          {child}
        </React.Fragment>
      ))}
    </>
  );
};

export const VStack = React.forwardRef((props: StackProps, forwardedRef) => {
  const { space, children, ...viewProps } = props;
  const theme = useAppTheme();
  const gap = space ? theme.spacing[space] : 0;

  return (
    <View {...viewProps} ref={forwardedRef as any}>
      {gap === 0 ? children : addGapToChildren(children, gap)}
    </View>
  );
});
VStack.displayName = "VStack";

export const HStack = React.forwardRef((props: StackProps, forwardedRef) => {
  const { space, children, ...viewProps } = props;
  const theme = useAppTheme();
  const gap = space ? theme.spacing[space] : 0;

  return (
    <View
      row
      {...viewProps}
      ref={forwardedRef as any}
      align={props.align || "center"}
    >
      {gap === 0 ? children : addGapToChildren(children, gap)}
    </View>
  );
});
HStack.displayName = "HStack";

export const SwitchStack = (props: StackProps) => {
  return <VStack {...props} row={props.row ?? !isNativeMobile} />;
};

SwitchStack.displayName = "SwitchStack";
