import type { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { SelectInputBase } from './SelectInput';
import { useSelectSingleInput } from './SelectInput.hook';

export interface SelectSingleInputProps
  extends CommonInputProps<string, 'single-select'> {}

export const SelectSingleInput = (props: SelectSingleInputProps) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    // error,
    allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    config,
  } = useSelectSingleInput(props);

  return (
    <SelectInputBase
      rowProps={props.rowProps}
      readonly={readonly}
      optional={optional}
      options={config.list}
      feedbackOnlyWithError={config.feedbackOnlyWithError}
      disabled={disabled}
      label={label}
      feedback={feedback}
      allowEmpty={allowEmpty}
      values={value ? [value] : undefined}
      setValues={(v) => {
        setValue(v ? v[0] : v);
        isValid({ showFeedback: true });
      }}
      isSearchable={config.isSearchable}
      isClearable={config.isClearable}
      type="single-select"
    />
  );
};
