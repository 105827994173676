import { useState } from 'react';
import { BaseInput, BaseInputProps } from '../BaseInputs/BaseInput';
import { FormInputContainer } from './FormInput.Container';
import { FormGroupItem } from '@droplet-tech-code/core-common-types';

export interface FormInputBaseProps {
  label?: string;
  feedback?: string;
  optional?: boolean;
  readonly?: boolean;
  valid?: boolean;
  noFeedback?: boolean;
  feedbackOnlyWithError?: boolean;
  rowProps?: FormGroupItem['itemProps'];
}

export type FormInputProps = FormInputBaseProps & BaseInputProps;

export const FormInput = ({
  label,
  feedback,
  readonly,
  valid,
  children,
  noFeedback,
  feedbackOnlyWithError,
  ...inputProps
}: FormInputProps) => {
  const disabled = inputProps.disabled;
  const error = inputProps.error;
  const optional = inputProps.optional;
  const [focused, setFocused] = useState(false);
  return (
    <FormInputContainer
      disabled={disabled}
      error={error}
      focused={focused}
      feedback={feedback}
      label={label}
      optional={optional}
      readonly={readonly}
      noFeedback={noFeedback}
      feedbackOnlyWithError={feedbackOnlyWithError}
      rowProps={inputProps.rowProps}
    >
      <BaseInput
        {...inputProps}
        error={inputProps.error}
        editable={!readonly}
        onFocus={(ev) => {
          setFocused(true);
          inputProps.onFocus?.(ev);
        }}
        onBlur={(ev) => {
          setFocused(false);
          inputProps.onBlur?.(ev);
        }}
      />
      {children}
    </FormInputContainer>
  );
};
