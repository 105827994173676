import { useState } from "react";
import { Pressable } from "react-native";
import { translate } from "../../lang/lang";
import {
  CalenderDataItem,
  CalenderManagerProps,
  CalenderProvider,
  DayItem,
  useCalenderDayItemManager,
  useCalenderManager,
  useCalenderSelectiveIndexHeader,
} from "../../manager/Calender";
import { ColorKeys, useAppTheme } from "../../theme";
import { isNativeMobile } from "../../utils/utils.helper";
import { Button } from "../Button/Button";
import { IconButton, IconButtonProps } from "../Button/Button.Icon";
import type { ButtonInteractionState } from "../Button/Button.types";
import { Modal } from "../Modal";
import { useModal } from "../Modal/Modal.Provider";
import { HStack, VStack } from "../Stack";
import { Text } from "../Text";
import { View } from "../View";

export interface CalenderDatePickerProps {
  iconProps?: IconButtonProps;
  calenderOptions: CalenderManagerProps;
}

export const CalenderModalKey = "calender-modal";

export const CalenderDatePicker = ({
  calenderOptions,
  ...props
}: CalenderDatePickerProps) => {
  const { openModal } = useModal();

  return (
    <IconButton
      icon="calender"
      color="monochrome"
      {...props}
      onPress={() => {
        openModal(
          <CalenderProvider calenderOptions={calenderOptions}>
            <Modal.Body>
              <CalenderDatePickerContent />
            </Modal.Body>
          </CalenderProvider>,
          {
            key: CalenderModalKey,
            type: "pop-up",
          }
        );
      }}
    />
  );
};

const WeekDayItem = ({ children }: { children: string }) => (
  <Text.Body2Regular
    style={{ flex: 1, textAlign: "center" }}
    color="monochrome"
  >
    {children}
  </Text.Body2Regular>
);

const CalenderWeekDayRow = (
  <HStack>
    <WeekDayItem>Su</WeekDayItem>
    <WeekDayItem>Mo</WeekDayItem>
    <WeekDayItem>Tu</WeekDayItem>
    <WeekDayItem>We</WeekDayItem>
    <WeekDayItem>Th</WeekDayItem>
    <WeekDayItem>Fr</WeekDayItem>
    <WeekDayItem>Sa</WeekDayItem>
  </HStack>
);

export const CalenderDayItem = ({ dayItem }: { dayItem: DayItem }) => {
  const { calenderManager, calenderOptions } = useCalenderManager();
  const { palette } = useAppTheme();
  const { closeModal } = useModal();
  const { onDateSelect, markedDateOptions, isBeforeToday, isToday } =
    useCalenderDayItemManager({
      dateString: dayItem.dateString,
      calenderManager,
    });

  const roundBorder = 999;
  const primaryBase = palette.primary.base;
  const primaryMain = palette.primary.mid;
  const primaryExtraLight = palette.primary.extraLight;

  const dateNumberColor = isBeforeToday
    ? "monochrome-light"
    : markedDateOptions.selectedIndexes?.length
    ? "white"
    : markedDateOptions.between
    ? "primary-mid"
    : (undefined as ColorKeys | undefined);

  const header =
    markedDateOptions.selectedIndexes?.map((index) => index + 1).join(",") ||
    "";

  const selected = markedDateOptions.selectedIndexes;

  return (
    <Pressable
      style={{
        flex: 1,
        alignItems: "center",
        marginTop: 19,
      }}
      onPress={() => {
        if (!isBeforeToday) {
          onDateSelect();

          if (calenderOptions.numberOfSelectiveDates === 1) {
            closeModal(CalenderModalKey);
            calenderOptions.onDone?.(
              calenderManager.getFormattedSelectedDates()
            );
          }
        }
      }}
    >
      {({ hovered }: ButtonInteractionState) => {
        return (
          <View
            style={{
              alignSelf: "stretch",
              alignItems: "center",
              backgroundColor: markedDateOptions.between
                ? primaryBase
                : "transparent",
            }}
          >
            {markedDateOptions.startDate ? (
              <View
                style={{
                  position: "absolute",
                  width: "50%",
                  height: "100%",
                  top: 0,
                  right: 0,
                  backgroundColor: primaryBase,
                }}
              />
            ) : null}
            {markedDateOptions.endDate ? (
              <View
                style={{
                  position: "absolute",
                  width: "50%",
                  height: "100%",
                  top: 0,
                  left: 0,
                  backgroundColor: primaryBase,
                }}
              />
            ) : null}
            <View
              align="center"
              justify="center"
              br={0}
              style={[
                {
                  width: 36,
                  height: 36,
                  backgroundColor: "transparent",
                },
                isToday && {
                  borderRadius: roundBorder,
                  backgroundColor: palette.primary.base,
                  borderWidth: 1,
                  borderColor: palette.primary.extraLight,
                },
                selected && {
                  borderRadius: roundBorder,
                  backgroundColor: primaryMain,
                },
                hovered && {
                  borderRadius: roundBorder,
                  backgroundColor: selected
                    ? palette.primary.dark
                    : palette.monochrome.base,
                },
              ]}
            >
              <Text.Body2Regular
                style={[
                  {
                    textAlign: "center",
                    lineHeight: 27,
                  },
                ]}
                color={dateNumberColor}
              >
                {dayItem.dateNumber ?? ""}
              </Text.Body2Regular>
            </View>
            {header && calenderOptions.numberOfSelectiveDates > 1 ? (
              <View
                style={{
                  backgroundColor: primaryExtraLight,
                  borderRadius: roundBorder,
                  alignItems: "center",
                  position: "absolute",
                  top: -8,
                  right: 3,
                  height: 20,
                  width: 20,
                }}
              >
                <Text.Small
                  style={{
                    textAlign: "center",
                    lineHeight: 22,
                    fontSize: 10,
                  }}
                  numberOfLines={1}
                  color="black"
                >
                  {header}
                </Text.Small>
              </View>
            ) : null}
          </View>
        );
      }}
    </Pressable>
  );
};

const CalenderDaySection = ({
  month,
}: {
  month: CalenderDataItem;
  index: number;
}) => {
  const monthKey = month.monthDate.format("YYYY-MM");
  const DaysInMonth = month.monthItem.map((daysInMonthInWeek, index) => {
    return (
      <HStack key={index + `${monthKey}`}>
        {daysInMonthInWeek.map((dayInMonth, jIndex) => {
          return (
            <CalenderDayItem key={`${index}${jIndex}`} dayItem={dayInMonth} />
          );
        })}
      </HStack>
    );
  });
  return <View flex={1}>{DaysInMonth}</View>;
};

const CalenderMonthSection = ({
  month,
  index,
}: {
  month: CalenderDataItem;
  index: number;
}) => {
  return (
    <View flex={1} style={{ minHeight: 371 }}>
      {CalenderWeekDayRow}
      <CalenderDaySection month={month} index={index} />
    </View>
  );
};

const DateHeader = ({
  month,
  months,
  next,
  previous,
  index,
}: {
  months: number;
  month: CalenderDataItem;
  next?: () => void;
  previous?: () => void;
  index: number;
}) => {
  const fullMonth = month.monthDate.format("MMMM");
  const fullYear = month.monthDate.format("YYYY");

  return (
    <HStack justify="space-between" align="center" flex={1}>
      {previous ? (
        <IconButton
          icon="chevron-left"
          onPress={() => previous()}
          disabled={index === 0}
        />
      ) : null}
      <Text.Body1Medium style={{ textAlign: "center" }} fontWeight="500">
        {fullMonth}, {fullYear}
      </Text.Body1Medium>
      {next ? (
        <IconButton
          icon="chevron-right"
          onPress={() => next()}
          disabled={months - 1 === index}
        />
      ) : null}
    </HStack>
  );
};

const CalenderDatePickerContent = () => {
  const { calenderData, calenderOptions } = useCalenderManager();
  const [index, setIndex] = useState(0);
  const month = calenderData[index];
  const nextMonth = calenderData[index + 1];
  const doubleView = calenderOptions.doubleView && !isNativeMobile;
  return (
    <VStack p="3">
      <>
        {doubleView ? (
          <HStack>
            <DateHeader
              months={calenderData.length}
              index={index}
              month={month}
              previous={() => setIndex(index - 1)}
            />
            <View flex={1} />
            {nextMonth ? (
              <DateHeader
                months={calenderData.length}
                index={index + 1}
                month={nextMonth}
                next={() => setIndex(index + 1)}
              />
            ) : null}
          </HStack>
        ) : (
          <DateHeader
            months={calenderData.length}
            index={index}
            month={month}
            next={() => setIndex(index + 1)}
            previous={() => setIndex(index - 1)}
          />
        )}
      </>
      <HStack space="6" mt="3">
        <CalenderMonthSection month={calenderData[index]} index={index} />
        {nextMonth && doubleView ? (
          <CalenderMonthSection month={nextMonth} index={index + 1} />
        ) : null}
      </HStack>
      <CalenderButtonConfirm />
    </VStack>
  );
};

const CalenderButtonConfirm = () => {
  const { calenderOptions, calenderManager } = useCalenderManager();
  const { selectedDates } = useCalenderSelectiveIndexHeader({
    calenderManager,
  });
  const { spacing } = useAppTheme();
  const { closeModal } = useModal();
  if (calenderOptions.numberOfSelectiveDates <= 1) {
    return null;
  }

  return (
    <HStack justify="flex-end" space="3">
      <Button
        style={{
          paddingHorizontal: spacing[8],
        }}
        size="small"
        fitToContent
        variant="outline"
        disabled={selectedDates.length === 0}
        onPress={() => {
          calenderManager.clearDates();
        }}
      >
        {translate("app.inputs.clear")}
      </Button>
      <Button
        style={{
          paddingHorizontal: spacing[8],
        }}
        disabled={
          selectedDates.length !== calenderOptions.numberOfSelectiveDates
        }
        size="small"
        fitToContent
        onPress={() => {
          closeModal(CalenderModalKey);
          calenderOptions.onDone?.(calenderManager.getFormattedSelectedDates());
        }}
      >
        {translate("app.inputs.done")}
      </Button>
    </HStack>
  );
};
