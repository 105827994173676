import { BaseQueryFn } from "@reduxjs/toolkit/dist/query";
import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import { ErrorObject, getErrorResponse, isCodeMessageObject } from "../error";

import type {
  BaseFetchProps,
  FetchDeviceId,
  FetchLang,
  FetchPushId,
  FetchUserId,
  FetchUserToken,
  FetchUtilProps,
  PostFetchProps,
  SessionId,
} from "./network.types";
import { createPause, generateRandomString } from "../utils.helper";
export class FetchManager {
  private _getUserToken: FetchUserToken;
  private _getUserId: FetchUserId;
  private _getDeviceId: FetchDeviceId;
  private _getPushId?: FetchPushId;
  private _getLang: FetchLang;

  // private _getCustomHeaders?: () => { [key: string]: string };

  private _appVersion: string;
  private _getBaseUrl: () => string;
  private _debug?: boolean;
  private _defaultErrorMessage: string;
  private _platform: "native" | "browser";

  private _appendAdditionalHeaders?: () => { [key: string]: string };
  private _onLog?: (message: object) => void;
  private _onError?: (error: object) => void;
  private _getSessionId?: SessionId;

  constructor(props: FetchUtilProps) {
    this._getUserToken = props.getUserToken;
    this._getUserId = props.getUserId;
    this._getDeviceId = props.getDeviceId;
    this._getPushId = props.getPushId;
    this._getLang = props.getLang;
    // this._getCustomHeaders = props.getHeaders;
    this._appVersion = props.appVersion;
    this._getBaseUrl = props.getBaseUrl;
    this._debug = props.debug;
    this._defaultErrorMessage = props.defaultErrorMessage;
  }

  getSyncHeaders = () => {
    return {
      version: this._appVersion,
      "other-toke": new Date().valueOf() + "",
      platform: this._platform,
      "fetch-id": generateRandomString(5).toLocaleUpperCase(),
      ...this._appendAdditionalHeaders?.(),
    };
  };

  getHeaders = async () => {
    let userToken: string;
    for (;;) {
      userToken = await this._getUserToken();
      if (userToken) {
        break;
      }

      await createPause(100);
    }

    const userId = await this._getUserId();
    const deviceId = await this._getDeviceId();
    const pushId = (await this._getPushId?.()) || "";
    const lang = await this._getLang();
    const sessionId = await this._getSessionId?.();
    return {
      lang: lang,
      "push-id": pushId,
      fuid: userId,
      "device-id": deviceId,
      "auth-token": userToken,
      sessionId: sessionId || "",
    };
  };

  getBaseUrl() {
    return this._getBaseUrl();
  }

  axiosBaseQuery: AxiosBaseQuery = async ({ url, method, data, params }) => {
    return this.request({
      endPoint: url,
      method: method as "POST" | "GET",
      data,
      params,
    })
      .then((data) => {
        if (isCodeMessageObject(data)) {
          return {
            error: {
              status: 400,
              data,
            },
          };
        }
        return { data };
      })
      .catch((err) => {
        const errorData = getErrorResponse(err, this._defaultErrorMessage);
        return {
          error: {
            status: 400,
            data: errorData,
          },
        };
      });
  };

  request = async <T>(
    props: BaseFetchProps & { data?: any; method: "POST" | "GET" }
  ): Promise<T | ErrorObject> => {
    const {
      endPoint,
      params = "",
      signal,
      timeout,
      errorMessage,
      method,
      data,
      headers,
    } = props;
    const url = this._getBaseUrl() + endPoint + params;
    const time = new Date().toJSON();
    // const staticHeaders = this.getHeaders();
    const staticHeaders = this.getSyncHeaders();

    try {
      const additionalHeaders = await this.getHeaders();
      const response = await axios({
        headers: {
          // ...staticHeaders,
          ...additionalHeaders,
          ...headers,
        },
        method,
        url,
        cancelToken: signal && signal.token,
        timeout,
        data,
      });

      this._onLog?.({
        id: staticHeaders["fetch-id"],
        method,
        url,
        request: data,
        response: response,
      });

      if (this._debug) {
        console.info(
          `\n[Start]--------${time}--------`,
          `\n[${staticHeaders["fetch-id"]}][${method}] ${url}`,
          "\n[Body] ",
          data,
          `\n[${typeof response.data}]`,
          response.data,
          "\n[End]------------------------\n"
        );
      }

      return response.data as T;
    } catch (error) {
      this._onError?.({
        id: staticHeaders["fetch-id"],
        method,
        url,
        error,
        props,
      });

      if (this._debug) {
        console.info(
          `\n--------${time}--------`,
          `\n[Error][${staticHeaders["fetch-id"]}][${method}] ${url}`,
          "\n",
          error,
          "\n",
          props,
          "\n------------------------\n"
        );
      }
      return getErrorResponse(error, errorMessage || this._defaultErrorMessage);
    }
  };

  get = async <T>(props: BaseFetchProps): Promise<T | ErrorObject> => {
    return this.request<T>({ ...props, method: "GET" });
  };

  post = async <T>(props: PostFetchProps): Promise<T | ErrorObject> => {
    return this.request<T>({ ...props, method: "POST" });
  };

  stream = async <T>(
    props: BaseFetchProps & {
      subscribeToStream: (data: T | ErrorObject) => () => void;
    }
  ): Promise<void> => {
    const response = await this.request<AxiosResponse<any, any>["data"]>({
      ...props,
      method: "GET",
      responseType: "stream",
    });

    if (isCodeMessageObject(response)) {
      props.subscribeToStream(response);
      return;
    }
    response.on("data", (data: T) => {
      props.subscribeToStream(data);
    });
  };

  setOnLog = (onLog: (message: object) => void) => {
    this._onLog = onLog;
  };

  setOnError = (onError: (error: object) => void) => {
    this._onError = onError;
  };
}

export type AxiosBaseQuery = BaseQueryFn<
  {
    url: string;
    method: AxiosRequestConfig["method"];
    data?: AxiosRequestConfig["data"];
    params?: AxiosRequestConfig["params"];
  },
  unknown,
  unknown
>;
