export default {
  app: {
    common: {
      success: "Success",
      retry: "Retry",
    },
    button: {
      continue: "Continue",
      regular: "Regular",
      large: "Large",
      small: "Small",
    },
    inputs: {
      email: "Email",
      invalidInput: "Invalid field",
      invalidEmail: "Please enter a valid email",
      invalidDate: "Date must be in format of {{format}}",
      invalidPhoneNumber: "Phone number is invalid",
      invalidNumber: "Must be a valid number",
      invalidLangText: "Field must have 'en' included",
      requiredField: "Field is required",
      editAcceptedLocale: "Edit accepted locale",
      startByAddingALocale: "Start by adding a locale",
      selectColor: "Select a color",
      invalidColor: "Invalid color",
      addLocale: "Add locale",
      englishEn: "English (en)",
      empty: "<empty>",
      items: "[Items x{{count}}]",
      cancel: "Cancel",
      confirm: "Confirm",
      save: "Save",
      done: "Done",
      clear: "Clear",
      search: "Search",
      reset: "Reset",
      invalid: "<invalid>",
      errorImage: "Error uploading image",
      couldNotUploadFile: "Error uploading file",
      noData: "No data",
      createList: "Create list",
      add: "Add",
      valueExistsAlready: "Value exists already",

      emailInUse: "This email is already in use",
      weakPassword: "Password is weak, please use more numbers and characters",
      invalidUser:
        "Invalid user, please make sure your user is spelt correctly.",
      disable:
        "You have been disabled from the system. Please contact support.",
      invalidPassword: "Invalid password.",
      invalidVerificationCode: "Invalid verification code",

      errorLogSupport: "Error logging in. Please contact support.",
    },
    modal: {
      verify: "Verify",
      areYouSure: "Do you wish to continue?",
      confirm: "Confirm",
      cancel: "Cancel",
    },
    feedback: {
      genericError: "Unknown Error",
      lessThanSize: "Must be less than {{size}}",
      copiedToClipboard: "Success! Copied to your clipboard",
      unexpectedError: "An unexpected error has occurred",
      noFiles: "Could not find file",
      download: "Download",
      uploadFiles: "Upload files",
      attachFiles: "Attach files",
      pleaseTryAgain:
        "Please try again. If this error persists, you can contact tech support.",
      youAreOffline: "Your are currently offline",
      networkError: "Network error",
      checkYourInternet: "Please check your internet connection",
      error: {
        somethingWentWrong: "Something went wrong!",
        unexpectedError: "Unexpected error!",
      },
    },

    connection: {
      youAreOffline: "You are offline",
      checkYourInternet: "Please check your internet connection and try again.",
    },
  },
};
