import { MutationDefinition } from "@reduxjs/toolkit/dist/query";
import { UseMutation } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { AxiosBaseQuery } from "../../utils/network";

export interface FileItem {
  name: string;
  id: string;
}
export interface FileItemStored extends FileItem {
  contentType?: string;
}

export const getFileUrlPublic = (token: string) => `file-public/get/${token}`;

export interface CreateFileProps {
  createFileHook: UseMutation<
    MutationDefinition<
      string,
      AxiosBaseQuery,
      any,
      {
        token: string;
      },
      "rootApi"
    >
  >;
}

// TODO
// export const uploadFile = async (file: FormData) => {
//   const response = await fetchManager.post<{
//     token: string;
//     expiry: string;
//   }>({
//     endPoint: "file/upload/v1",
//     body: file,
//   });
//   return response;
// };

// @Get('/settings/get-img/v1/')
//     getCompanyImage(@ResponseDeco() res: Response): Promise<any> {
//         return this._companyService.getCompanyImage(res);
//     }

//     @Post('/settings/edit-img/v1/')
//     editCompanyImage(@RequestDeco() body: Request): Promise<any> {
//         return this._companyService.editCompanyImage(body);
//     }
