import { useEffect, useRef, useState } from 'react';

import { translate } from '../../../lang/lang';
import { useAppTheme } from '../../../theme';
import { InputButton } from '../../Button/Button.Input';
import { Icon } from '../../Icon/Icon';

import { useModal } from '../../Modal/Modal.Provider';
import { HStack, VStack } from '../../Stack';
import { Text } from '../../Text';
import { FormHandler } from '../Form/Form.handler';
import { FormBuilderImport } from '../Form/FormBuilder/FormBuilder.Import';
import type { FormBuilderProps } from '../Form/FormBuilder/FormBuilder.types';
import type { NestedInputProps } from './NestedInput.types';
import {
  getLabelAndValueFromConfig,
  nestedFormSubmit,
} from './NestedInput.utils';
import isEqual from 'lodash/isEqual';
import { Modal } from '../../Modal/Modal.Components';

export interface NestedFormItemProps<T>
  extends Omit<FormBuilderProps<T>, 'data' | 'form'> {
  config: NestedInputProps['config'];
  onSubmit: (_: T) => void;
  error?: boolean;
  modalKey: string;
  parentForm: FormHandler;
  isFormValidRef?: React.MutableRefObject<() => boolean>;
}

const NestedFormContent = <T,>(
  props: Omit<NestedFormItemProps<T>, 'error'>
) => {
  const { closeModal } = useModal();
  const formHandler = useRef(
    new FormHandler({
      onChange: (inputId, inputValue) => {
        if (props.parentForm.globals) {
          const actionForInput = props.parentForm.globals[props.config.id];
          if (actionForInput) {
            actionForInput?.({
              inputId,
              inputValue,
              currentForm: formHandler.current,
              parentForm: props.parentForm,
            });
          }
        }
      },
      globals: props.parentForm.globals,
    })
  );

  return (
    <Modal.Container>
      <Modal.Header
        title={props.config.label ?? props.modalKey}
        subtitle={props.config.subtitle}
        onPress={() => closeModal(props.modalKey)}
        divider
      />
      <Modal.Body scroll p="0">
        <FormBuilderImport.FormBuilder
          nested
          form={formHandler.current}
          data={props.config.config}
          extensions={props.extensions}
          initialValue={props.config.initialValue}
          value={props.value}
          flatView={props.flatView}
          readonly={props.readonly}
        />
      </Modal.Body>

      {props.config.readonly ? (
        <Modal.Footer
          cancel={translate('app.inputs.done')}
          onCancel={() => closeModal(props.modalKey)}
          divider
        />
      ) : (
        <Modal.Footer
          onCancel={() => closeModal(props.modalKey)}
          onConfirm={() => {
            const { valid, value } = formHandler.current.getValue<T>({
              showFeedback: true,
              validate: true,
            });
            if (valid) {
              props.onSubmit(nestedFormSubmit(value, props.config));
              closeModal(props.modalKey);
            }
          }}
          divider
        />
      )}
    </Modal.Container>
  );
};

const NoPreviewForm = <T,>(props: NestedFormItemProps<T>) => {
  const formHandler = useRef(
    new FormHandler({
      onChange: (inputId, inputValue) => {
        const { value } = formHandler.current.getValue<T>({
          validate: false,
          showFeedback: false,
        });
        props.onSubmit(nestedFormSubmit(value, props.config));

        if (props.parentForm.globals) {
          const actionForInput = props.parentForm.globals[props.config.id];
          if (actionForInput) {
            actionForInput?.({
              inputId,
              inputValue,
              currentForm: formHandler.current,
              parentForm: props.parentForm,
            });
          }
        }
      },
      globals: props.parentForm.globals,
    })
  );

  const [initialConfig, setInitialConfig] = useState(props.config.config);

  useEffect(() => {
    if (props.isFormValidRef) {
      props.isFormValidRef.current = () => {
        return formHandler.current.isFormValid({
          showFeedback: true,
        });
      };
    }
  }, [formHandler, props.isFormValidRef]);

  useEffect(() => {
    const hasConditions =
      !props.config.config.length && !!props.config.conditions?.length;
    const condition = hasConditions
      ? props.config.conditions!.find((c) =>
          c.values.every((cValue) => {
            return isEqual(
              cValue.value,
              props.parentForm.getSingleValue({ id: cValue.listenTo })
            );
          })
        )
      : false;

    if (condition && condition.formGroup.type === 'form-item') {
      setInitialConfig(condition.formGroup.config);
    }
  }, []);

  return (
    <VStack space="1" flex={1}>
      <FormBuilderImport.FormBuilder
        nested
        form={formHandler.current}
        data={initialConfig}
        initialValue={props.value}
        extensions={props.extensions}
        itemStyle={{
          marginVertical: 0,
        }}
      />
    </VStack>
  );
};

export const NestedInputPreviewComponent = <T,>(
  props: NestedFormItemProps<T>
) => {
  const disableButton = props.readonly ?? props.config.readonly;
  if (!props.config.preview.length) {
    return <NoPreviewForm {...props} />;
  }

  return (
    <InputButton
      disabled={disableButton}
      style={{ flex: 1 }}
      onPress={() => {}}
    >
      <VStack space="1" flex={1}>
        {props.config.preview.map((previewItem) => {
          const foundConfig = getLabelAndValueFromConfig(
            props.config.config,
            previewItem.id,
            props?.value
          );
          const label = foundConfig.config?.label;
          const textValue = foundConfig.value ?? translate('app.inputs.empty');
          return (
            <HStack
              key={previewItem.id}
              flex={1}
              space="3"
              style={previewItem.style}
            >
              <Text.Body2Regular
                style={{ flex: 1, minWidth: 50 }}
                color="monochrome-mid"
                numberOfLines={1}
              >
                {label ?? ' '}
              </Text.Body2Regular>
              <Text.Body2Regular color="monochrome-extraDark" numberOfLines={1}>
                {textValue + ''}
              </Text.Body2Regular>
            </HStack>
          );
        })}
      </VStack>
    </InputButton>
  );
};

export const AddNestedItemButton = <T,>(props: NestedFormItemProps<T>) => {
  const { openModal } = useModal();
  const theme = useAppTheme();
  return (
    <InputButton
      onPress={() => {
        openModal(<NestedFormContent {...props} />, {
          key: props.modalKey,
          type: 'action-sheet-full',
        });
      }}
      style={[
        {
          borderStyle: 'dashed',
          minWidth: 200,
        },
        props.error && { borderColor: theme.palette.error.dark },
      ]}
    >
      <HStack flex={1} justify="center">
        <Icon icon="plus" color="monochrome-light" />
      </HStack>
    </InputButton>
  );
};
