import React from "react";
import { Pressable } from "react-native";
import { ScrollView } from "../View";
import { ButtonInteractionState } from "../Button";
import { VStack } from "../Stack";
import { Divider } from "../Divider/Divider";

export const PopoverList = ({
  list,
}: {
  list: { item: React.ReactNode; onPress?: () => void }[];
}) => {
  return (
    <ScrollView contentContainerStyle={{ flexGrow: 1 }}>
      {list.map(({ item, onPress }, ix) => {
        return (
          <React.Fragment key={ix}>
            <Pressable onPress={onPress}>
              {({ hovered }: ButtonInteractionState) => {
                return (
                  <VStack
                    space="1"
                    bg={hovered ? "primary-base" : "white"}
                    p="4"
                  >
                    {item}
                  </VStack>
                );
              }}
            </Pressable>
            <Divider />
          </React.Fragment>
        );
      })}
    </ScrollView>
  );
};
