import {
    RoutePassengerValidation,
    RoutePricePassengerV2,
} from '@naus-code/naus-client-types';
import { PasUpdateDetailsReq } from '../types/providers';
import { BasketManagerTariffTickets } from './bm.6.vTariff.2.pricing';

export class BasketManagerPassengerDetails extends BasketManagerTariffTickets {
    //
    updatePassengerDetails = (options: PasUpdateDetailsReq) => {
        this._log('updatePassengerDetails');
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const updatedPassenger: RoutePricePassengerV2 = {
            ...passengerData,
        };

        this.dispatchBasketWrapper(undefined, (state) => {
            for (const fieldData of options.fieldData) {
                const { value } = fieldData;
                const fieldId = fieldData.fieldId as any;
                //@ts-expect-error
                if (passengerData.extraPriceData?.[fieldId]) {
                    continue;
                }
                //@ts-expect-error
                updatedPassenger[fieldId] = value;
            }
            this.dispatchPassenger(updatedPassenger, {
                disablePriceReset: true,
                state,
                selectiveUpdate: {
                    tripDetails: true,
                },
            });
        });
    };

    extraPassengerDetailsFields = (): (keyof RoutePassengerValidation)[] => {
        const bmState = this.getBmState();
        const additionalValidation = bmState.routePrice.passengerExtraValidation || {};

        const listThatCanBeAdded: (keyof RoutePassengerValidation)[] = [
            'nationality',
            'birthDate',
            'birthPlace',
            'idOrPassportDoc',
        ];

        const remainingKeys = listThatCanBeAdded.filter(
            (key) =>
                !bmState.basketDataProcessed.passengers.validation[key] &&
                !additionalValidation[key],
        );
        return remainingKeys;
    };

    enablePassengerDetail = (passengerValidation: keyof RoutePassengerValidation) => {
        const bmState = this.getBmState();
        bmState.routePrice.passengerExtraValidation = {
            ...bmState.routePrice.passengerExtraValidation,
            [passengerValidation]: true,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let passengerIndex = 0;
                passengerIndex < bmState.routePrice.passengers.length;
                passengerIndex++
            ) {
                const passenger = bmState.routePrice.passengers[passengerIndex];
                this.dispatchPassenger(passenger, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };

    disablePassengerDetail = (passengerValidation: keyof RoutePassengerValidation) => {
        if (!passengerValidation) {
            return;
        }
        const bmState = this.getBmState();
        bmState.routePrice.passengerExtraValidation = {
            ...bmState.routePrice.passengerExtraValidation,
            [passengerValidation]: false,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let passengerIndex = 0;
                passengerIndex < bmState.routePrice.passengers.length;
                passengerIndex++
            ) {
                const passenger = bmState.routePrice.passengers[passengerIndex];
                this.dispatchPassenger(passenger, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };
}
