import { KeyVal } from '@naus-code/naus-client-types';
import groupBy from 'lodash.groupby';
import clone from 'lodash.clonedeep';

export function groupByFunc<T>(
    array: T[],
    func: (item: T) => string,
): { [key: string]: T[] } {
    return groupBy(array, (item: any) => func(item));
}

export function cloneDeep<T extends Object>(obj: T): T {
    return clone(obj);
}

export function onlyUnique(
    value: string | number,
    index: number,
    self: (string | number)[],
) {
    return self.indexOf(value) === index;
}

export function mapArrayToKeyValue<T>(
    array: T[],
    func: (item: T, index: number) => string,
): KeyVal<T>;
export function mapArrayToKeyValue<T, K>(
    array: T[],
    func: (item: T, index: number) => string,
    mapFunc: (item: T, index: number) => K,
): KeyVal<K>;
export function mapArrayToKeyValue<T, K>(
    array: T[],
    func: (item: T, index: number) => string,
    mapFunc?: (item: T, index: number) => K,
): KeyVal<T | K> {
    return array.reduce((cache: KeyVal<T | K>, item, index) => {
        const key = func(item, index);
        cache[key] = mapFunc ? mapFunc(item, index) : item;
        return cache;
    }, {});
}

export const sortLargestFirst =
    <T>(func: (item: T) => number) =>
    (a: T, b: T) => {
        return func(b) - func(a);
    };

export const sortSmallestFirst =
    <T>(func: (item: T) => number) =>
    (a: T, b: T) => {
        return func(a) - func(b);
    };

export function generateRandomString(length = 10): string {
    let randomString = '';
    const possibleText = 'abcdefghijklmnopqrstuvwxyz1234567890';
    for (let i = 0; i < length; i++) {
        randomString += possibleText.charAt(
            Math.floor(Math.random() * possibleText.length),
        );
    }
    return randomString;
}

export function getKeysOfObject<T>(obj: T): (keyof T)[] {
    return Object.keys(obj as any) as (keyof T)[];
}

export function existsIn(ids: string | string[] | undefined, id: string): boolean {
    return ids ? ids.indexOf(id) !== -1 : false;
}

export function findIndexFromLast<T>(
    array: Array<T>,
    predicate: (value: T, index: number, obj: T[]) => boolean,
): number {
    let l = array.length;
    while (l--) {
        if (predicate(array[l], l, array)) return l;
    }
    return -1;
}

export function findFromLast<T>(
    array: T[],
    predicate: (value: T, index: number, obj: T[]) => boolean,
): T | undefined {
    const index = findIndexFromLast(array, predicate);
    if (index === -1) return undefined;
    return array[index];
}

export function notEmpty<t>(value: t | null | undefined): value is t {
    return value !== null && value !== undefined;
}

export function mapKeyValueToArray<T>(object: { [key: string]: T | undefined }): T[];
export function mapKeyValueToArray<T, K extends keyof T>(
    object: { [key: string]: T | undefined },
    options: { subKey: K },
): T[K][];
export function mapKeyValueToArray<T, K>(
    object: { [key: string]: T | undefined },
    options: { mapFunc: (item: T) => K },
): K[];
export function mapKeyValueToArray<T, K extends keyof T>(
    object: { [key: string]: T | undefined },
    options?: { subKey?: K; mapFunc?: (item: T) => K },
) {
    let arr = Object.keys(object).map((key) =>
        options?.subKey ? object[key]?.[options?.subKey] : object[key],
    );
    if (options?.mapFunc) {
        arr = arr.map((item) => options?.mapFunc!(item as T) as any);
    }
    return arr.filter(notEmpty) as any;
}

export interface ErrorObject {
    code: string;
    message: string;
}

export function isJSON(error: any): error is object {
    return error !== null && typeof error === 'object' && !Array.isArray(error);
}
export function isCodeMessageObject(error: any): error is ErrorObject {
    return (
        isJSON(error) && error?.hasOwnProperty('code') && error?.hasOwnProperty('message')
    );
}
