import { useAppTheme } from '../../../theme';
import { Toggle } from '../../Toggle/Toggle';
import { BaseInput } from '../BaseInputs/BaseInput';
import { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import {
  BLANK_LABEL,
  FormInputContainer,
} from '../FormInputs/FormInput.Container';
import { useToggleInput } from './ToggleInput.hook';

export interface ToggleInputProps extends CommonInputProps<boolean, 'toggle'> {}

export const ToggleInput = (props: ToggleInputProps) => {
  const {
    value = false,
    setValue,
    disabled,
    label,
    readonly,
    noFeedback,
  } = useToggleInput(props);

  const { palette } = useAppTheme();

  return (
    <FormInputContainer
      noFeedback={noFeedback}
      disabled={disabled}
      focused={false}
      // feedback={feedback}
      label={BLANK_LABEL}
      optional
      readonly={readonly}
      rowProps={props.rowProps}
    >
      <BaseInput
        inputStyle={{
          color: value ? palette.black : palette.monochrome.mid,
          minWidth: 0,
        }}
        editable={false}
        value={label}
        iconEnd={
          <Toggle
            value={value}
            color="primary"
            size="medium"
            onChange={(nv) => setValue(nv)}
            disabled={disabled}
            readonly={props.readonly}
          />
        }
      />
    </FormInputContainer>
  );
};
