import {
    KeyVal,
    Optional,
    PriceClientVerifyLoyaltyRequest,
    RoutePassengerAccommodationCabinClient,
    RoutePassengerValidation,
    RoutePricePassengerDataV2,
    RoutePricePassengerV2,
    TripPricePassengerAccommodationV2,
} from '@naus-code/naus-client-types';
import {
    PasDetailsUpdatePriceReq,
    PasSelectDiscountReq,
    PasSelectExtraReq,
    PasSelectLoyaltyReq,
    PasSelectLoyaltyRes,
    PasSelectTicketReq,
} from '../types/providers';
import { createError } from '../errors';
import { ClientBasketState } from '../types/clientState';
import {
    generateRandomString,
    isCodeMessageObject,
    onlyUnique,
} from '../utils/functions';
import dayjs from 'dayjs';
import { BasketManagerPassengersQuotes } from './bm.5.pas.1.quote';

export class BasketManagerPassengerTickets extends BasketManagerPassengersQuotes {
    //

    private getTripPasTicket = (options: { tripKey: string; ticketCode: string }) => {
        const tripDic = this.getTripDictionary(options.tripKey);
        const ticketAcc = tripDic.passengerAccDic[options.ticketCode];
        if (!ticketAcc) {
            throw createError({ code: 'PASSENGER_TICKET_CODE_NOT_FOUND' });
        }
        return ticketAcc;
    };

    //---------------CHANGE_TICKET---------------//
    /**
     * Function to choose a ticket for a passenger.
     * If extra information is required such as passenger details or other passengers to join
     * the functions passed to callbacks will be triggered.
     *
     * @options_callbacks_onPassengersRequired props include the passengers available
     * for for the user to select to add to their cabin. If full capacity of cabin needs to be
     * filled, then mustFillQty:true and user must fill the qty.
     *
     * @options_callbacks_onAdditionalInfoRequired if the ticket requires extra information from
     * the user for the passenger they are changing, this callback will be triggered and the props
     * will include the information that is missing and required as FieldInput[]
     */
    selectPassengerTicket = async (options: PasSelectTicketReq) => {
        const bmState = this.getBmState();
        const tripDic = this.getTripDictionary(options.tripKey);
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const ticketAcc = this.getTripPasTicket(options);

        //1.If trip only allows same passenger tickets
        if (tripDic.tripValidation.samePassengerTickets) {
            return this.selectTicketForAll(options);
        }

        //2.If user asked to apply to all passengers
        if (options.applyToAll) {
            const canBeApplied = this.passengerGetAppliesToOther(passengerData, tripDic);
            if (!canBeApplied) {
                return;
            }
            return this.selectTicketForAll(options);
        }

        //3.If user asked to book a cabin
        if (ticketAcc.type === 'cabin' && bmState.routePrice.passengers.length > 1) {
            return this.selectCabinTicket(options, ticketAcc);
        }

        const additionalInfo = this.getRequiredExtraFields(
            passengerData,
            ticketAcc.extraPasValidation,
        );

        if (additionalInfo.length) {
            const newFields = await options.callBacks.onAdditionalInfoRequired({
                fields: additionalInfo,
            });
            if (!newFields) {
                return;
            }
            if (newFields.additionalInfo.length !== additionalInfo.length) {
                //missing information
                return;
            }
            this.updatePassengerDetailsForPrice({
                passengerId: passengerData.id,
                //@ts-expect-error Field name not string
                data: newFields.additionalInfo,
                state: options.state,
            });
            //Add fields to passenger data
            const passengerWithUpdatedFields =
                bmState.routePrice.passengers[passengerData.index];
            return this.changeTicketCodeForPassenger(
                passengerWithUpdatedFields,
                options.tripKey,
                options.ticketCode,
                {
                    state: options.state,
                },
            );
        }

        //TODO what happens to other passengers who's apply to other hasn't re-rendered?
        //Change passenger ticket
        this.dispatchBasketWrapper(options.state, (state) => {
            this.releasePassengersFromCabin(passengerData, options.tripKey, { state });
            this.changeTicketCodeForPassenger(
                passengerData,
                options.tripKey,
                options.ticketCode,
                {
                    state,
                },
            );
        });
        this.dispatchPrices();
    };

    private releasePassengersFromCabin = (
        passengerData: RoutePricePassengerV2,
        tripKey: string,
        options?: {
            state?: ClientBasketState;
        },
    ) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        const tripDic = this.getTripDictionary(tripKey);
        const routeTrip = routePrice.trips[tripDic.index];
        const passengerDataReq = routeTrip.passengerAccReq[passengerData.index];

        const currentAccommodation = this.getTripPasTicket({
            tripKey,
            ticketCode: passengerDataReq.accCode,
        });
        const isWholeCabin =
            currentAccommodation.type === 'cabin' && currentAccommodation.wholeUse;

        if (!isWholeCabin || !passengerDataReq.cabinId) {
            return;
        }

        const passengersInCabin = routeTrip.passengerAccReq.filter(
            (pas) => pas.cabinId === passengerDataReq.cabinId,
        );

        for (const cabinPassengerDataReq of passengersInCabin) {
            const cabinPassenger = this.passengerGetPasRoutePrice(
                cabinPassengerDataReq.passengerData.passengerId,
            );

            const category = this.findPassengerCategory(tripDic, cabinPassenger);
            const categoryKey = this.getPassengerCatKey(category.cat);
            const categoryDic = tripDic.passengerCatAccDic[categoryKey];

            const newPassengerDataReq: TripPricePassengerAccommodationV2 = {
                ...cabinPassengerDataReq,
                cabinId: undefined,
                accCode: categoryDic.defaultAcc.code,
            };

            this.dispatchPassenger(cabinPassenger, {
                state: options?.state,
                passengerReqPerTrip: { [tripDic.key]: newPassengerDataReq },
                resetExtraData: true,
                disablePriceReset: true,
                selectiveUpdate: {
                    pricing: true,
                },
            });
        }
    };

    private selectTicketForAll = (options: PasSelectTicketReq) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        const tripDic = this.getTripDictionary(options.tripKey);

        for (const passengerData of routePrice.passengers) {
            const passengerDataReq =
                routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];
            passengerDataReq.toggle = false;
        }

        this.dispatchBasketWrapper(options.state, (state) => {
            for (let pIndex = 0; pIndex < routePrice.passengers.length; pIndex++) {
                const passengerData = routePrice.passengers[pIndex];
                this.changeTicketCodeForPassenger(
                    passengerData,
                    options.tripKey,
                    options.ticketCode,
                    { state },
                );
            }
        });
        this.dispatchPrices();
    };

    private changeTicketCodeForPassenger = (
        passengerData: RoutePricePassengerV2,
        tripKey: string,
        ticketCode: string,
        options?: {
            state?: ClientBasketState;
        },
    ) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        const tripDic = this.getTripDictionary(tripKey);
        //
        const passengerDataReq =
            routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];
        passengerDataReq.toggle = false;

        this.getTripPasTicket({
            tripKey,
            ticketCode: passengerDataReq.accCode,
        });

        const newPassengerDataReq: TripPricePassengerAccommodationV2 = {
            ...passengerDataReq,
            cabinId: undefined,
            accCode: ticketCode,
            passengerData: {
                ...passengerDataReq.passengerData,
            },
        };

        // const passengerReq: KeyVal<TripPricePassengerAccommodationV2> = {
        //     [tripDic.key]: {
        //         ...passengerDataReq,
        //         cabinId: undefined,
        //         accCode: options.ticketCode,
        //         passengerData: {
        //             ...passengerDataReq.passengerData,
        //             // discountCode: shouldKeepDiscount
        //             //     ? passengerDataReq.passengerData.discountCode
        //             //     : undefined,
        //             // accDiscountData: shouldKeepDiscount
        //             //     ? passengerDataReq.passengerData.accDiscountData
        //             //     : undefined,
        //         },
        //     },
        // };

        this.dispatchPassenger(passengerData, {
            state: options?.state,
            passengerReqPerTrip: { [tripDic.key]: newPassengerDataReq },
            resetExtraData: true,
            selectiveUpdate: {
                pricing: true,
            },
        });
    };

    private selectCabinTicket = async (
        options: PasSelectTicketReq,
        cabinAcc: RoutePassengerAccommodationCabinClient,
    ) => {
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const bmState = this.getBmState();
        const { routePrice } = bmState;

        const tripDic = this.getTripDictionary(options.tripKey);

        const otherPassengers = routePrice.passengers
            .filter((pas) => pas.id !== passengerData.id)
            .map((pas) => ({
                passengerId: pas.id,
                index: pas.index,
                label: this.passengerGetLabelOrIndex(pas),
            }));

        const passengerList =
            cabinAcc.wholeUse && cabinAcc.capacity > 1
                ? await options.callBacks.onPassengersRequired({
                      acc: this.passengerMapCabinAccAvailableTicket(cabinAcc),
                      tripKey: options.tripKey,
                      qty: cabinAcc.capacity,
                      mustFillQty: cabinAcc.wholeUse,
                      selectAllByDefault:
                          cabinAcc.wholeUse &&
                          routePrice.passengers.length === cabinAcc.capacity,
                      //
                      mainPassenger: {
                          passengerId: passengerData.id,
                          index: passengerData.index,
                          label: this.passengerGetLabelOrIndex(passengerData),
                      },
                      passengerList: otherPassengers,
                  })
                : {
                      passengerIds: [],
                  };

        if (!passengerList) {
            return;
        }

        const uniquePassengerIds = [
            passengerData.id,
            ...passengerList.passengerIds,
        ].filter(onlyUnique);

        if (cabinAcc.wholeUse && uniquePassengerIds.length !== cabinAcc.capacity) {
            throw createError({ code: 'CABIN_PASSENGERS_MISMATCH' });
        }

        const cabinId = generateRandomString();
        bmState.routePrice.trips[tripDic.index].cabinAccGroupReq.push({
            cabinId,
            accCode: options.ticketCode,
        });

        for (const cabinPassengerId of uniquePassengerIds) {
            const cabinPassenger = this.passengerGetPasRoutePrice(cabinPassengerId);
            const passengerDataReq =
                routePrice.trips[tripDic.index].passengerAccReq[cabinPassenger.index];
            passengerDataReq.toggle = false;
        }
        this.dispatchBasketWrapper(options.state, (state) => {
            this.releasePassengersFromCabin(passengerData, options.tripKey, { state });
            for (const cabinPassengerId of uniquePassengerIds) {
                const cabinPassenger = this.passengerGetPasRoutePrice(cabinPassengerId);
                const passengerDataReq =
                    routePrice.trips[tripDic.index].passengerAccReq[cabinPassenger.index];
                passengerDataReq.toggle = false;
                const newPassengerDataReq: TripPricePassengerAccommodationV2 = {
                    ...passengerDataReq,
                    cabinId,
                    accCode: options.ticketCode,
                    passengerData: {
                        ...passengerDataReq.passengerData,
                    },
                };
                this.dispatchPassenger(cabinPassenger, {
                    state,
                    passengerReqPerTrip: { [tripDic.key]: newPassengerDataReq },
                    resetExtraData: true,
                    selectiveUpdate: {
                        pricing: true,
                    },
                });
            }
        });
        this.dispatchPrices();
    };

    private getRequiredExtraFields = (
        passengerData: RoutePricePassengerV2,
        validation: RoutePassengerValidation | undefined,
    ) => {
        const additionalInfo = this.passengerGetAdditionalDetails(
            passengerData,
            validation,
        );
        return additionalInfo.fieldOptionsArray.filter((item) => item.required);
    };

    //---------------DETAILS_PRICE---------------//
    getPassengerDetailsForPrice = (passengerId: string) => {
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(passengerId);

        let extraValidation: RoutePassengerValidation = {};

        for (const trip of bmState.routePrice.trips) {
            const passengerReq = trip.passengerAccReq[passengerData.index];
            const ticketAcc =
                bmState.tripDictionaries[trip.key].passengerAccDic[passengerReq.accCode];
            if (!ticketAcc.extraPasValidation) {
                continue;
            }
            extraValidation = {
                ...extraValidation,
                ...ticketAcc.extraPasValidation,
            };
        }
        const additionalInfo = this.getRequiredExtraFields(
            passengerData,
            extraValidation,
        );
        if (additionalInfo.length === 0) {
            return;
        }
        return {
            fieldInputs: additionalInfo,
        };
    };

    updatePassengerDetailsForPrice = (options: PasDetailsUpdatePriceReq) => {
        this._log('updatePassengerDetailsForPrice');
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);

        const newExtraPriceData: Optional<RoutePricePassengerDataV2> = {
            ...passengerData.extraPriceData,
        };

        for (const fieldData of options.data) {
            (newExtraPriceData as any)[fieldData.fieldId] = fieldData.value;
        }

        const updatedPassenger: RoutePricePassengerV2 = {
            ...passengerData,
            extraPriceData: newExtraPriceData,
        };

        this.dispatchPassenger(updatedPassenger, {
            disablePriceReset: true,
            selectiveUpdate: {
                pricing: true,
            },
        });
    };

    //---------------DISCOUNTS---------------//
    /**
     * Function to choose a discount for a passenger.
     * If extra information is required such as the passenger's loyalty number or other
     * discount data, the functions passed to callbacks will be triggered.
     *
     * @options_callbacks_onLoyaltyDataRequired props include verifyLoyalty which fetches server
     * for loyalty data, use it once user enters passenger's loyalty number and then return the response
     * as the response for onLoyaltyDataRequired
     */
    selectPassengerDiscount = async (options: PasSelectDiscountReq) => {
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const tripDic = this.getTripDictionary(options.tripKey);

        let passengerAccReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];
        const catAccDic =
            tripDic.passengerCatAccDic[passengerAccReq.passengerData.catKey];

        if (options.discountCode === this.STANDARD_FARE) {
            const passengerReq: KeyVal<TripPricePassengerAccommodationV2> = {
                [options.tripKey]: {
                    ...passengerAccReq,
                    passengerData: {
                        ...passengerAccReq.passengerData,
                        discountCode: undefined,
                    },
                },
            };

            this.dispatchPassenger(passengerData, {
                passengerReqPerTrip: passengerReq,
                selectiveUpdate: {
                    pricing: true,
                },
            });
            this.dispatchPrices();
            return;
        }

        const discountDic = catAccDic.discountsDic[options.discountCode];
        if (!discountDic) {
            console.log('DISCOUNT NOT FOUND');
            return;
        }

        //1.Check if requires loyalty
        const requiresLoyalty =
            discountDic.requiresLoyalty && !passengerAccReq.passengerData.loyalty;

        if (requiresLoyalty) {
            const loyaltyRes = await options.callbacks.onLoyaltyDataRequired({
                verifyLoyalty: async (loyaltyNumber) =>
                    this.selectPassengerLoyalty({
                        passengerId: options.passengerId,
                        tripKey: options.tripKey,
                        loyaltyNumber,
                    }),
            });
            this._log('loyaltyRes', loyaltyRes);
            if (!loyaltyRes || loyaltyRes.error) {
                return;
            }
            //Check if loyalty is now in bmstate
            passengerAccReq =
                bmState.routePrice.trips[tripDic.index].passengerAccReq[
                    passengerData.index
                ];
            const hasLoyalty = !!passengerAccReq.passengerData.loyalty;
            if (!hasLoyalty) {
                return;
            }
            //Continue to setting discount
        }

        //2.Check if requires discount data
        const savedDiscountData =
            passengerData.discountCode?.[tripDic.companyId]?.[discountDic.companyCode];
        const requiresDiscountData =
            discountDic.requiresData &&
            !discountDic.requiresLoyalty &&
            !savedDiscountData?.number;

        let discountData: string | undefined;
        if (requiresDiscountData) {
            discountData = await options.callbacks.onDiscountDataRequired();
            if (!discountData) {
                return;
            }
        }

        const passengerReqPerTrip: KeyVal<TripPricePassengerAccommodationV2> = {
            [options.tripKey]: {
                ...passengerAccReq,
                passengerData: {
                    ...passengerAccReq.passengerData,
                    discountCode: options.discountCode,
                    accDiscountData: discountData,
                },
            },
        };

        this.dispatchPassenger(passengerData, {
            passengerReqPerTrip,
            selectiveUpdate: {
                pricing: true,
            },
        });
        this.dispatchPrices();
    };

    // removePassengerDiscount = (options: { passengerId: string; tripKey: string }) => {
    //     const bmState = this.getBmState();
    //     const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
    //     const tripDic = this.getTripDictionary(options.tripKey);

    //     const passengerAccReq =
    //         bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

    //     const passengerReq: KeyVal<TripPricePassengerAccommodationV2> = {
    //         [options.tripKey]: {
    //             ...passengerAccReq,
    //             passengerData: {
    //                 ...passengerAccReq.passengerData,
    //                 discountCode: undefined,
    //                 accDiscountData: undefined,
    //             },
    //         },
    //     };

    //     this.dispatchPassenger(passengerData, {
    //         tripKeys: [options.tripKey],
    //         passengerReqPerTrip: passengerReq,
    //         selectiveUpdate: {
    //             pricing: true,
    //         },
    //     });
    //     this.dispatchPrices();
    // };

    //---------------LOYALTY---------------//

    selectPassengerLoyalty = async (
        options: PasSelectLoyaltyReq,
    ): Promise<PasSelectLoyaltyRes> => {
        this._log('selectPassengerLoyalty', options);
        //TODO Company does not Loyalty API
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const tripDic = this.getTripDictionary(options.tripKey);

        const loyaltyRes = await this.fetchLoyalty({
            companyId: tripDic.companyId,
            loyaltyId: options.loyaltyNumber,
        });

        if (loyaltyRes.error) {
            return loyaltyRes;
        }

        const pasLoyaltyData = loyaltyRes.loyaltyData?.passengerData;
        if (!pasLoyaltyData) {
            return {
                error: this.trns('app.error.notFound'),
            };
        }

        //1.Test matching names
        const name = passengerData.name?.toLocaleLowerCase();
        const loyaltyName = pasLoyaltyData.name?.toLowerCase();
        if (loyaltyName && name && name.indexOf(loyaltyName) === -1) {
            return {
                loyaltyData: loyaltyRes.loyaltyData,
                error: this.trns('app.error.incorrectName'),
            };
        }

        //2.Test matching last names
        const lastName = passengerData.lastName?.toLocaleLowerCase();
        const loyaltyLastName = pasLoyaltyData.lastName?.toLowerCase();
        if (loyaltyLastName && lastName && lastName.indexOf(loyaltyLastName) === -1) {
            return {
                loyaltyData: loyaltyRes.loyaltyData,
                error: this.trns('app.error.incorrectLastName'),
            };
        }

        //3.Test birthday
        const birthDate = passengerData.birthDate;
        const loyaltyBirthDate = pasLoyaltyData.birthDate;
        if (loyaltyBirthDate) {
            if (birthDate) {
                if (birthDate !== loyaltyBirthDate) {
                    return {
                        loyaltyData: loyaltyRes.loyaltyData,
                        error: this.trns('app.error.incorrectBirthDate'),
                    };
                }
            } else {
                const age = dayjs().diff(loyaltyBirthDate, 'years');
                const valid = passengerData.minAge <= age && age <= passengerData.maxAge;
                if (!valid) {
                    return {
                        loyaltyData: loyaltyRes.loyaltyData,
                        error: this.trns('app.error.incorrectBirthDate'),
                    };
                }
            }
        }

        //4.Test nationality
        if (
            pasLoyaltyData.nationality &&
            passengerData.nationality &&
            passengerData.nationality !== pasLoyaltyData.nationality
        ) {
            return {
                loyaltyData: loyaltyRes.loyaltyData,
                error: this.trns('app.error.incorrectNationality'),
            };
        }

        //5.Test gender
        const currentSex = passengerData.sex || passengerData.extraPriceData?.sex;
        if (currentSex && pasLoyaltyData.sex && currentSex !== pasLoyaltyData.sex) {
            return {
                loyaltyData: loyaltyRes.loyaltyData,
                error: this.trns('app.error.incorrectSex'),
            };
        }

        //6.Apply data
        const newPassengerData: RoutePricePassengerV2 = {
            ...passengerData,
            loyalty: {
                ...passengerData.loyalty,
                [tripDic.companyId]: options.loyaltyNumber,
            },
            loyaltyData: {
                ...passengerData.loyaltyData,
                [tripDic.companyId]: pasLoyaltyData,
            },
        };

        const passengerReqPerTrip: KeyVal<TripPricePassengerAccommodationV2> = {};
        const vehiclesWithLoyaltyOfPassenger: string[] = [];

        for (let tIndex = 0; tIndex < bmState.selectedTrips.length; tIndex++) {
            const trip = bmState.selectedTrips[tIndex];
            if (trip.companyId !== tripDic.companyId) {
                continue;
            }
            const passengerAccReq =
                bmState.routePrice.trips[tIndex].passengerAccReq[passengerData.index];
            passengerReqPerTrip[trip.key] = {
                ...passengerAccReq,
                passengerData: {
                    ...passengerAccReq.passengerData,
                    loyalty: options.loyaltyNumber,
                },
            };
            bmState.routePrice.trips[tIndex].vehicleAccReq.forEach((veh) => {
                if (veh.passengerId === passengerData.id) {
                    vehiclesWithLoyaltyOfPassenger.push(veh.vehicleData.vehicleId);
                }
            });
        }

        this.dispatchBasket((state) => {
            this.dispatchPassenger(newPassengerData, {
                state,
                passengerReqPerTrip,
                selectiveUpdate: {
                    pricing: true,
                },
            });
            //TODO Vehicle Loyalty
            // if (vehiclesWithLoyaltyOfPassenger.length) {
            //     this.managerState.routePrice.vehicles.forEach((vehicle, index) => {
            //       if (existsIn(vehiclesWithLoyaltyOfPassenger, vehicle.id)) {
            //         this.dispatchVehicle(vehicle, index, {
            //           state,
            //           log: true,
            //           tripKeys: tripsWithLoyalty,
            //           disableGenerate: {
            //             ...stateDisabled,
            //             passengersAndVehiclesEdit: undefined,
            //             selectTickets: undefined,
            //             selectTicketsEdit: {
            //               allowLoyaltyUpdate: true,
            //             },
            //           },
            //         });
            //       }
            //     });
            //   }
        });
        this.dispatchPrices();
        return {
            loyaltyData: loyaltyRes.loyaltyData,
        };
    };

    private fetchLoyalty = async (
        data: PriceClientVerifyLoyaltyRequest,
    ): Promise<PasSelectLoyaltyRes> => {
        if (this.devOptions?.disablePriceFetch) {
            return {
                loyaltyData: {
                    companyId: data.companyId,
                    loyaltyId: data.loyaltyId,
                    passengerData: {
                        name: 'GEORGE',
                        lastName: 'FARHAT',
                        sex: 'M',
                        //
                        birthDate: '1993-12-01',
                        nationality: 'GR',
                        //
                        specialNeeds: false,
                        //
                    },
                },
            };
        }
        const loyaltyData = await this.verifyLoyalty(data)
            .then((res) => {
                if (isCodeMessageObject(res)) {
                    throw res;
                }
                return {
                    loyaltyData: res,
                };
            })
            .catch((res) => {
                if (isCodeMessageObject(res)) {
                    const loyaltyRes: PasSelectLoyaltyRes = {
                        error: res.message,
                    };
                    return loyaltyRes;
                }
                const loyaltyRes: PasSelectLoyaltyRes = {
                    error: this.trns('app.error.notFound'),
                };
                return loyaltyRes;
            });
        return loyaltyData;
    };

    //---------------EXTRAS---------------//

    addExtra = async (options: PasSelectExtraReq) => {
        //TODO Add extra to all passengers
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const tripDic = this.getTripDictionary(options.tripKey);

        const passengerAccReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

        const accDic =
            tripDic.passengerCatAccDic[passengerAccReq.passengerData.catKey].extrasDic;
        const extraDic = accDic?.[options.extraCode];

        if (!extraDic) {
            //TODO Extra Dictionary not found
            console.log('EXTRA NOT FOUND');
            return;
        }

        const extraReqs = passengerAccReq.extras || {};

        if (!extraReqs[extraDic.code]) {
            extraReqs[extraDic.code] = {
                extraId: generateRandomString(),
                code: extraDic.code,
                qty: 0,
                toggle: true,
            };
        }

        if (extraReqs[extraDic.code].qty >= extraDic.limit) {
            return;
        }

        if (extraDic.mode === 'single' && extraReqs[extraDic.code].qty > 0) {
            return;
        }
        extraReqs[extraDic.code].qty++;
        extraReqs[extraDic.code].toggle = false;

        const newPassengerReq: TripPricePassengerAccommodationV2 = {
            ...passengerAccReq,
            extras: extraReqs,
        };

        this.dispatchPassenger(passengerData, {
            passengerReqPerTrip: { [tripDic.key]: newPassengerReq },
            selectiveUpdate: {
                pricing: true,
            },
        });

        this.dispatchPrices();
    };

    removeExtra = (options: PasSelectExtraReq) => {
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(options.passengerId);
        const tripDic = this.getTripDictionary(options.tripKey);

        const passengerAccReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

        const accDic =
            tripDic.passengerCatAccDic[passengerAccReq.passengerData.catKey].extrasDic;
        const extraDic = accDic?.[options.extraCode];

        if (!extraDic) {
            //TODO Extra Dictionary not found
            console.log('EXTRA NOT FOUND');
            return;
        }

        let extraReqs = passengerAccReq.extras || {};

        if (!extraReqs[extraDic.code]) {
            return;
        }

        if (extraReqs[extraDic.code].qty < 2) {
            extraReqs = {
                ...passengerAccReq.extras,
                [extraDic.code]: undefined as any,
            };
        } else {
            extraReqs[extraDic.code].qty--;
        }

        const newPassengerReq: TripPricePassengerAccommodationV2 = {
            ...passengerAccReq,
            extras: extraReqs,
        };

        this.dispatchPassenger(passengerData, {
            passengerReqPerTrip: { [tripDic.key]: newPassengerReq },
            selectiveUpdate: {
                pricing: true,
            },
        });

        this.dispatchPrices();
    };
}
