import React, { useState, useCallback, useRef } from 'react';
import {
  Animated,
  Pressable,
  StyleProp,
  StyleSheet,
  View,
  ViewStyle,
} from 'react-native';
import { useAppTheme } from '../theme';
import { Icon } from './Icon';

export interface BouncyIconBoxProps {
  checked: boolean;
  size: number;
  borderRadius: number;
  color: string;
  borderColor: string;
  iconComponent: React.ReactNode;
  textComponent?: React.ReactNode;
  disabled?: boolean;
  onPress?: (checked: boolean) => void;
  innerContainerStyle?: ViewStyle;
  style?: StyleProp<ViewStyle>;
}

const bounceIn = {
  value: 0.8,
  velocity: 0.1,
  bounciness: 20,
};

const bounceOut = {
  value: 1,
  velocity: 0.4,
  bounciness: 20,
};

const styles = StyleSheet.create<any>({
  iconContainer: (
    size: number,
    borderRadius: number,
    color: string,
    borderColor: string
  ) => ({
    width: size,
    height: size,
    borderRadius: borderRadius,
    borderWidth: 1.3,
    borderColor: borderColor,
    backgroundColor: color,
    alignItems: 'center',
    justifyContent: 'center',
  }),
  pressableContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
  textContainer: {
    marginLeft: 16,
  },
  iconInnerContainer: (size: number, borderRadius: number) => ({
    width: size,
    height: size,
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: borderRadius,
    backgroundColor: 'transparent',
  }),
  icon: (color: string) => ({
    height: 8,
    width: 8,
    borderRadius: 4,
    backgroundColor: color,
  }),
});

export const CheckBox = ({
  checked,
  size = 24,
  onPress,
  disabled,
  style,
}: {
  checked: boolean;
  size?: number;
  onPress?: (checked: boolean) => void;
  disabled?: boolean;
  style?: StyleProp<ViewStyle>;
}) => {
  return (
    <CheckBoxBase
      color="transparent"
      iconComponent={
        <Icon
          icon={checked ? 'check-box-on' : 'check-box-off'}
          color={checked ? 'primary-mid' : 'monochrome-mid'}
        />
      }
      onPress={onPress}
      checked={checked}
      size={size}
      borderRadius={0}
      borderColor={'transparent'}
      disabled={disabled}
      style={style}
    />
  );
};

export const CheckBoxBase = (props: BouncyIconBoxProps) => {
  const {
    checked,
    size,
    borderRadius,
    color,
    borderColor,
    iconComponent,
    textComponent,
    disabled,
    onPress,
    innerContainerStyle,
    style,
  } = props;
  const { pressableContainer, iconContainer, iconInnerContainer } = styles;

  const handlePress = useCallback(() => {
    onPress?.(!checked);
  }, [checked, onPress]);

  const bounceValue = useRef(new Animated.Value(1)).current;
  const bounceEffect = useCallback(
    ({
      value,
      velocity,
      bounciness,
    }: {
      value: number;
      velocity: number;
      bounciness: number;
    }) => {
      Animated.spring(bounceValue, {
        toValue: value,
        velocity,
        bounciness,
        useNativeDriver: true,
      }).start();
    },
    [bounceValue]
  );

  return (
    <Pressable
      style={[pressableContainer, style]}
      disabled={disabled}
      onPressIn={disabled ? undefined : () => bounceEffect(bounceIn)}
      onPressOut={disabled ? undefined : () => bounceEffect(bounceOut)}
      onPress={disabled ? undefined : handlePress}
    >
      <View style={iconContainer(size, borderRadius, color, borderColor)}>
        <Animated.View
          style={[
            iconInnerContainer(size, borderRadius),
            innerContainerStyle,
            { transform: [{ scale: bounceValue }] },
          ]}
        >
          {iconComponent}
        </Animated.View>
      </View>
      {textComponent && textComponent}
    </Pressable>
  );
};

export const RadioButton = (props: {
  value: boolean;
  onChange?: (_: boolean) => void;
  disabled?: boolean;
}) => {
  const { disabled, value, onChange } = props;
  const { icon } = styles;

  const { palette } = useAppTheme();
  const disabledColor = palette.monochrome.light;
  const mainColor = palette.monochrome.dark;
  const colorStr = disabled ? disabledColor : mainColor;

  return (
    <CheckBoxBase
      checked={value}
      disabled={disabled}
      onPress={(newValue) => onChange && onChange(newValue)}
      size={16}
      borderRadius={8}
      color={'transparent'}
      borderColor={colorStr}
      iconComponent={<View style={icon(colorStr)} />}
    />
  );
};

export const RadioButtonStateful = ({
  initialValue,
  onSetRef,
}: {
  initialValue?: boolean;
  onSetRef: (_: boolean) => void;
}) => {
  const [selected, setSelected] = useState(!!initialValue);
  return (
    <RadioButton
      value={selected}
      onChange={() => {
        const newValue = !selected;
        onSetRef(newValue);
        setSelected(newValue);
      }}
    />
  );
};
