import { translate } from "../../../lang/lang";
import { FormHookProp } from "../Form";
import { useInputForm } from "../Inputs.utils";

export const useCreateListInput = (
  props: FormHookProp<string[], "create-list">
) => {
  return useInputForm({
    ...props,
    test: (v) => {
      if (props.config.optional) {
        return true;
      }
      return !props.config.optional && v.length > 0;
    },
    errorFeedback: translate("app.inputs.requiredField"),
  });
};
