import type { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { SelectInputBase } from './SelectInput';
import { useSelectMultiInput } from './SelectInput.hook';

export interface SelectMultiInputProps
  extends CommonInputProps<string[], 'multi-select'> {}

export const SelectMultiInput = (props: SelectMultiInputProps) => {
  const {
    value,
    setValue,
    isValid,
    feedback,
    // error,
    allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    config,
  } = useSelectMultiInput(props);

  return (
    <SelectInputBase
      rowProps={props.rowProps}
      optional={optional}
      readonly={readonly}
      options={config.list}
      disabled={disabled}
      label={label}
      feedback={feedback}
      allowEmpty={allowEmpty}
      feedbackOnlyWithError={config.feedbackOnlyWithError}
      values={value}
      setValues={(v) => {
        setValue(v);
        isValid({ showFeedback: true });
      }}
      type="multi-select"
      isSearchable={config.isSearchable}
    />
  );
};
