import { View } from "../View";
import { FormBuilderImport } from "./Form/FormBuilder/FormBuilder.Import";
import { CommonInputProps } from "./Form/FormBuilder/FormBuilder.types";
import { FormInputContainer } from "./FormInputs/FormInput.Container";
import { useInputForm } from "./Inputs.utils";

export const BlankInput = (props: CommonInputProps<string, "blank">) => {
  const inputProps = useInputForm({
    ...props,
    initialValue: undefined,
    value: undefined,
  });
  if (inputProps.config.type !== props.config.type) {
    return (
      <View
        key={inputProps.conditionIndex + "-" + inputProps.config.type}
        my="2"
        flex={1}
        style={[!props.config.noFeedback && { minHeight: 94 }, props.style]}
      >
        <FormBuilderImport.FormBuildSelector
          {...inputProps}
          form={props.form}
          extensions={props.extensions}
        />
      </View>
    );
  }

  return (
    <View flex={1} style={[{ height: 0, opacity: 0, overflow: "hidden" }]}>
      <FormInputContainer
        label={props.config.label}
        noFeedback={props.config.noFeedback}
      >
        <View style={props.config.style} />
      </FormInputContainer>
    </View>
  );
};
