import {
    KeyVal,
    RoutePricePassengerV2,
    TripPricePassengerAccommodationV2,
} from '@naus-code/naus-client-types';
import { ClientBasketState } from '../types/clientState';
import { existsIn, generateRandomString } from '../utils/functions';
import {
    PassengerAddReq,
    PassengerGetListReq,
    PassengerListRes,
    PassengerSetReq,
} from '../types/providers';
import { createError } from '../errors';
import dayjs from 'dayjs';
import { BasketManagerVehiclesQuotes } from './bm.5.veh.1.quote';
export class BasketManagerPassengersQuotes extends BasketManagerVehiclesQuotes {
    //
    addPassenger = (options?: PassengerAddReq) => {
        const bmState = this.getBmState();
        //
        const passengerIndex = bmState.routePrice.passengers.length;
        const passengerLimit = bmState.basketDataProcessed.passengers.limit;

        if (passengerIndex + 1 > passengerLimit) {
            return;
        }

        const passengerData = this.getPassengerDataForAdd(options);

        bmState.routePrice.passengers.push(passengerData);

        //Refresh Accommodation Dictionaries for new number of passengers
        this.refreshDictionaries();

        const passengerReqPerTrip: KeyVal<TripPricePassengerAccommodationV2> = {};

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const dic = this.getTripDictionary(trip.key);
            const category = this.findPassengerCategory(dic, passengerData);
            const categoryKey = this.getPassengerCatKey(category.cat);
            const categoryDic = dic.passengerCatAccDic[categoryKey];
            const passengerReq: TripPricePassengerAccommodationV2 = {
                accCode: categoryDic.defaultAcc.code,
                passengerData: {
                    passengerId: passengerData.id,
                    catKey: categoryKey,
                },
                toggle: true,
            };
            passengerReqPerTrip[trip.key] = passengerReq;
            bmState.routePrice.trips[tripIndex].passengerAccReq.push(passengerReq);
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            this.dispatchPassenger(passengerData, {
                state,
                passengerReqPerTrip,
                selectiveUpdate: {
                    quoteOptions: true,
                    pricing: true,
                },
            });
        });
        this.dispatchManager();
    };

    private getPassengerDataForAdd = (options?: PassengerAddReq) => {
        const bmState = this.getBmState();
        //
        const passengerIndex = bmState.routePrice.passengers.length;
        const pasReq = options?.passenger;
        if (pasReq?.type === 'guest') {
            const categoryList = bmState.basketDataProcessed.passengers.categoryList;
            const category = categoryList.find(
                (item) => this.getPassengerCatKey(item) === pasReq.id,
            );

            if (!category) {
                throw createError({
                    code: 'CATEGORY_NOT_FOUND',
                    data: { options, categoryList },
                });
            }

            const newPassengerData: RoutePricePassengerV2 = {
                id: generateRandomString(),
                index: passengerIndex,
                name: '',
                lastName: '',
                birthDate: undefined,
                sex: undefined,
                minAge: category.minAge,
                maxAge: category.maxAge,
            };
            return newPassengerData;
            // return this.setPassengerData(newPassengerData, { state: options?.state });
        }

        //Set saved passenger
        if (pasReq?.type === 'saved') {
            const savedPassenger = this.passengerGetSavedDictionary(pasReq.id);

            const age = dayjs().diff(savedPassenger.birthDate, 'years');
            const newPassengerData: RoutePricePassengerV2 = {
                id: savedPassenger.id,
                index: passengerIndex,
                birthDate: savedPassenger.birthDate,
                name: savedPassenger.name,
                lastName: savedPassenger.lastName,
                sex: savedPassenger.sex,
                nationality: savedPassenger.nationality,
                minAge: age,
                maxAge: age,
                birthPlace: savedPassenger.birthPlace,
                identityDocType: savedPassenger.identityDocType,
                identityDocNum: savedPassenger.identityDocNum,
                identityDocExpire: savedPassenger.identityDocExpire,
                residentIdGr: savedPassenger.residentIdGr,
                studentNumber: savedPassenger.studentNumber,
                specialNeeds: savedPassenger.specialNeeds,
            };
            return newPassengerData;
            // return this.setPassengerData(newPassengerData, { state: options?.state });
        }

        const passengerData: RoutePricePassengerV2 = {
            id: generateRandomString(),
            index: passengerIndex,
            name: '',
            lastName: '',
            sex: undefined,
            birthDate: undefined,
            minAge: bmState.basketDataProcessed.passengers.defaultCategory.minAge,
            maxAge: bmState.basketDataProcessed.passengers.defaultCategory.maxAge,
        };
        return passengerData;
    };

    removePassenger = () => {
        const bmState = this.getBmState();
        if (bmState.routePrice.passengers.length < 2) {
            return;
        }
        const passengerIndex = bmState.routePrice.passengers.length - 1;
        const oldPassengerId = bmState.routePrice.passengers[passengerIndex].id + '';
        bmState.routePrice.passengers.splice(passengerIndex, 1);

        this.refreshDictionaries();

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            bmState.routePrice.trips[tripIndex].passengerAccReq.splice(passengerIndex, 1);
        }

        this.dispatchBasket((state) => {
            state.quoteOptions.passengers.list.splice(passengerIndex, 1);
            for (
                let tripIndex = 0;
                tripIndex < bmState.routePrice.trips.length;
                tripIndex++
            ) {
                state.pricing.trips[tripIndex].passengers.splice(passengerIndex, 1);
                state.details.passengers.splice(passengerIndex, 1);
            }
            //
            this.passengersRefresh({
                state,
            });

            this.checkIfPetPassengerIdNeedsToChange(
                oldPassengerId,
                bmState.routePrice.passengers[0],
                {
                    state,
                },
            );
        });
        this.dispatchManager();
    };

    getPassengerList = (options?: PassengerGetListReq): PassengerListRes => {
        const bmState = this.getBmState();
        const categoryList = bmState.basketDataProcessed.passengers.categoryList;
        const currentPassengerIds = bmState.routePrice.passengers.map((item) => item.id);
        const currentPassenger = options?.oldId
            ? this.passengerGetPasRoutePrice(options.oldId)
            : undefined;
        return {
            guest: categoryList.map((item) => ({
                id: this.getPassengerCatKey(item),
                type: 'guest',
                label: item.label,
                isSelected: currentPassenger
                    ? this.getPassengerCatKey(currentPassenger) ===
                      this.getPassengerCatKey(item)
                    : undefined,
            })),
            saved: bmState.savedPassengers.map((item) => ({
                id: item.id,
                type: 'saved',
                label: `${item.name} ${item.lastName}`,
                disabled: existsIn(currentPassengerIds, item.id),
                isSelected: options?.oldId ? item.id === options.oldId : undefined,
            })),
        };
    };

    setPassenger = (options: PassengerSetReq) => {
        const { oldId, type, newId } = options;
        // if (!guestKey && !savedId) {
        //     return;
        // }
        const bmState = this.getBmState();
        const passengerData = this.passengerGetPasRoutePrice(oldId);

        //Create new guest
        if (type === 'guest') {
            const categoryList = bmState.basketDataProcessed.passengers.categoryList;
            const category = categoryList.find(
                (item) => this.getPassengerCatKey(item) === newId,
            );
            if (!category) {
                throw createError({
                    code: 'CATEGORY_NOT_FOUND',
                    data: { options, categoryList },
                });
            }

            const newPassengerData: RoutePricePassengerV2 = {
                id: generateRandomString(),
                index: passengerData.index,
                name: '',
                lastName: '',
                birthDate: undefined,
                sex: undefined,
                minAge: category.minAge,
                maxAge: category.maxAge,
            };
            this.dispatchBasketWrapper(options.state, (state) => {
                this.checkIfPetPassengerIdNeedsToChange(oldId, newPassengerData, {
                    state,
                });
                return this.setPassengerData(newPassengerData, { state });
            });
        }

        //Set saved passenger
        if (type === 'saved') {
            const savedPassenger = this.passengerGetSavedDictionary(newId);

            const age = dayjs().diff(savedPassenger.birthDate, 'years');
            const newPassengerData: RoutePricePassengerV2 = {
                id: savedPassenger.id,
                index: passengerData.index,
                birthDate: savedPassenger.birthDate,
                name: savedPassenger.name,
                lastName: savedPassenger.lastName,
                sex: savedPassenger.sex,
                nationality: savedPassenger.nationality,
                minAge: age,
                maxAge: age,
                birthPlace: savedPassenger.birthPlace,
                identityDocType: savedPassenger.identityDocType,
                identityDocNum: savedPassenger.identityDocNum,
                identityDocExpire: savedPassenger.identityDocExpire,
                residentIdGr: savedPassenger.residentIdGr,
                studentNumber: savedPassenger.studentNumber,
                specialNeeds: savedPassenger.specialNeeds,
            };
            this.dispatchBasketWrapper(options.state, (state) => {
                this.checkIfPetPassengerIdNeedsToChange(oldId, newPassengerData, {
                    state,
                });
                return this.setPassengerData(newPassengerData, { state });
            });
        }
    };

    private checkIfPetPassengerIdNeedsToChange = (
        oldPassengerId: string,
        newPassengerData: RoutePricePassengerV2,
        stateOption?: { state: ClientBasketState },
    ) => {
        const { routePrice } = this.getBmState();

        for (let tripIndex = 0; tripIndex < routePrice.trips.length; tripIndex++) {
            const tripKey = routePrice.trips[tripIndex].key;
            //Check if any vehicles are affected
            for (let vIndex = 0; vIndex < routePrice.vehicles.length; vIndex++) {
                const vehicle = routePrice.vehicles[vIndex];
                const vehicleReq = routePrice.trips[tripIndex].vehicleAccReq[vIndex];
                if (vehicleReq.passengerId === oldPassengerId) {
                    this.dispatchVehicle(vehicle, {
                        state: stateOption?.state,
                        vehicleReqPerTrip: {
                            [tripKey]: {
                                ...vehicleReq,
                                passengerId: newPassengerData.id,
                            },
                        },
                    });
                }
            }
        }

        const petsAffected = routePrice.pets.filter(
            (pet) => pet.passengerId === oldPassengerId,
        );

        if (!petsAffected.length) {
            return;
        }

        this.dispatchBasketWrapper(stateOption?.state, (state) => {
            for (const pet of petsAffected) {
                pet.passengerId = newPassengerData.id;
                this.dispatchPet(pet, {
                    state,
                });
            }
        });
    };

    private setPassengerData = (
        passengerData: RoutePricePassengerV2,
        options?: { state?: ClientBasketState },
    ) => {
        const bmState = this.getBmState();
        const passengerReqPerTrip: KeyVal<TripPricePassengerAccommodationV2> = {};
        const savedPassenger = this.passengerGetSavedDictionaryNoThrow(passengerData.id);
        for (const trip of bmState.selectedTrips) {
            const dictionary = this.getTripDictionary(trip.key);
            const category = this.findPassengerCategory(dictionary, passengerData);
            const catKey = this.getPassengerCatKey(category.cat);
            const catDic = dictionary.passengerCatAccDic[catKey];
            const loyalty = savedPassenger?.loyalty?.find(
                (item) => item.id === trip.companyId,
            );
            const passengerReq: TripPricePassengerAccommodationV2 = {
                accCode: catDic.defaultAcc.code,
                toggle: true,
                passengerData: {
                    passengerId: passengerData.id,
                    catKey,
                    loyalty: loyalty?.number,
                },
            };
            passengerReqPerTrip[trip.key] = passengerReq;
        }
        return this.dispatchPassenger(passengerData, {
            passengerReqPerTrip,
            state: options?.state,
        });
    };
}
