import Toast, { ToastConfig } from 'react-native-toast-message';
import { useAppTheme } from '../../theme';
import type { ChildrenProp } from '../../types/components';
import { isNativeMobile, isWeb } from '../../utils/utils.helper';
import { IconButton } from '../Button/Button.Icon';
import { HStack, StackProps, VStack } from '../Stack';
import { Text } from '../Text';
import { View } from '../View';

const toastConfig = {
  default: ({ text1, text2, type }) => (
    <ToastOuterContainer>
      <ToastContainer bg="white" type={type}>
        {text1 ? <Text.Body2Medium>{text1}</Text.Body2Medium> : null}
        {text2 ? <Text.Body2Medium>{text2}</Text.Body2Medium> : null}
      </ToastContainer>
    </ToastOuterContainer>
  ),
  error: ({ text1, text2, type }) => (
    <ToastOuterContainer>
      <ToastContainer bg="white" type={type}>
        {text1 ? <Text.Body2Medium>{text1}</Text.Body2Medium> : null}
        {text2 ? <Text.Body2Medium>{text2}</Text.Body2Medium> : null}
      </ToastContainer>
    </ToastOuterContainer>
  ),
};

export type ToastType = keyof typeof toastConfig;

const ToastOuterContainer = (props: ChildrenProp) => {
  return (
    <VStack
      px="6"
      w="100%"
      justify="center"
      align="center"
      style={[
        isWeb && {
          // @ts-expect-error
          cursor: 'auto',
        },
      ]}
    >
      {props.children}
    </VStack>
  );
};

const ToastContainer = ({
  type,
  ...props
}: StackProps & { type: ToastType }) => {
  const { palette } = useAppTheme();
  return (
    <HStack
      {...props}
      br={6}
      justify="center"
      align="center"
      style={[
        isWeb
          ? {
              minWidth: 300,
            }
          : { width: '100%' },
        {
          height: 48,
          shadowOffset: { width: 2, height: 2 },
          shadowOpacity: 0.2,
          shadowRadius: 4,
          overflow: 'hidden',
        },
        isNativeMobile && {
          borderWidth: 1,
          borderColor:
            type === 'default' ? palette.primary.mid : palette.error.mid,
          borderRadius: 12,
        },
      ]}
      space="3"
    >
      <View
        style={{
          width: 8,
          height: 48,
          backgroundColor:
            type === 'default' ? palette.primary.mid : palette.error.mid,
        }}
      />
      <IconButton
        disabled
        icon="error-circle"
        color="error"
        iconStyle={{ opacity: 0 }}
      />
      <VStack px="6" justify="center" align="center" flex={1}>
        {props.children}
      </VStack>
      <IconButton
        variant="plain"
        icon="error-circle"
        color={type === 'error' ? 'error' : 'primary'}
        onPress={() => {
          Toast.hide();
        }}
      />

      <View
        style={{
          width: 5,
          height: 48,
        }}
      />
    </HStack>
  );
};

export const ToastProvider = () => {
  if (isWeb) {
    return (
      <View
        style={{
          position: 'fixed' as any,
          right: 0,
          left: 0,
          top: 0,
          bottom: 0,
          // @ts-expect-error
          cursor: 'none',
          pointerEvents: 'none',
          backgroundColor: 'transparent',
        }}
        align="center"
        flex={1}
      >
        <Toast config={toastConfig as ToastConfig} />
      </View>
    );
  }

  return <Toast config={toastConfig as ToastConfig} />;
};

export const showToast = (
  message: string,
  options?: {
    position?: 'bottom' | 'top';
    type?: ToastType;
  }
) => {
  Toast.show({
    text1: message,
    position: options?.position || 'bottom',
    visibilityTime: 6000,
    type: options?.type || 'default',
  });
};
