import React, { useEffect, useRef, useState } from 'react';
import { ViewStyle } from '../../types/components';
import { LoadingIndicator as Loader, LoadingIndicatorProps } from '../Loading';
import { View } from '../View';

export interface DelayRenderProps {
  children?: React.ReactNode;
  delay?: number;
  initiate?: boolean;
  isLoading?: boolean;
  LoadingIndicator?: React.ReactNode;
  containerStyle?: ViewStyle;
  loadingProps?: LoadingIndicatorProps;
  noDelay?: boolean;
  loadingSize?: number;
  minLoadingHeight?: number;
}

export const DelayRender = ({
  delay = 200,
  initiate,
  children,
  isLoading,
  containerStyle,
  loadingProps,
  noDelay,
  minLoadingHeight = 150,
  loadingSize = 24,
  LoadingIndicator = (
    <View
      flex={1}
      justify="center"
      style={[
        {
          minHeight: minLoadingHeight,
        },
        containerStyle,
      ]}
    >
      <Loader size={loadingSize} {...loadingProps} />
    </View>
  ),
}: DelayRenderProps) => {
  const [completed, setCompleted] = useState(false);
  const ref = useRef({ timer: 0 });

  useEffect(() => {
    if (completed) {
      return;
    }

    clearTimeout(ref.current.timer);

    if (!noDelay) {
      if (initiate || initiate === undefined) {
        ref.current.timer = window.setTimeout(() => {
          setCompleted(true);
        }, delay);
      }
    }

    return () => {
      clearTimeout(ref.current.timer);
    };
  }, [initiate]);

  if (noDelay) {
    return <>{children}</>;
  }

  return <>{!isLoading && completed ? children : LoadingIndicator}</>;
};
