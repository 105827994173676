import { StyleSheet } from "react-native";
import { AppViewProps, View } from "../View";
import * as Svg from "react-native-svg";
import { useState } from "react";
import { ColorKeys, useColorFromTheme } from "../../theme";
import { ViewStyle } from "../../types/components";

export type DiverProps = AppViewProps & {
  hairline?: boolean;
  thickness?: number;
  vertical?: boolean;
};

export const Divider = (props: DiverProps) => {
  const thickness = props.hairline
    ? StyleSheet.hairlineWidth
    : props.thickness ?? 1;

  return (
    <View
      bg="monochrome-extraLight"
      {...props}
      style={[
        props.vertical
          ? {
              width: thickness,
              height: "100%",
            }
          : {
              height: thickness,
              width: "100%",
            },
        props.style,
      ]}
    />
  );
};
export type DottedLineProps = {
  height?: number;
  width?: number | string;
  color?: ColorKeys;
  spacing?: number;
  style?: ViewStyle;
};

export const DottedLine = ({
  height = 1,
  width = "100%",
  color = "monochrome-light",
  spacing = 9,
  style,
}: DottedLineProps) => {
  const [viewWidth, setViewWidth] = useState(0);
  const strokeColor = useColorFromTheme(color);
  const dashes = new Array(Math.floor(viewWidth / spacing)).fill(null);
  return (
    <View
      flex={1}
      style={style}
      onLayout={(e) => {
        setViewWidth(e.nativeEvent.layout.width);
      }}
    >
      <Svg.Svg height={height} width={width}>
        <Svg.G>
          {dashes.map((_, index) => (
            <Svg.Rect
              key={index}
              x={height}
              y={height - 1}
              width="4"
              height="1"
              fill={strokeColor}
              translateX={spacing * index}
            />
          ))}
        </Svg.G>
      </Svg.Svg>
    </View>
  );
};

export const Separator = (props: DiverProps) => {
  return (
    <Divider
      {...props}
      bg="primary-base"
      br={5}
      thickness={props.thickness ?? 15}
    />
  );
};
