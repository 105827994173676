import { useContext } from "react";
import { ThemeContext } from "./Theme.Context";
import { FontSource, useFonts } from "expo-font";

export const useGlobalAppTheme = () => {
  return useContext(ThemeContext);
};

export const useAppTheme = () => {
  return useContext(ThemeContext).theme;
};

export type FontOption = {
  heading1?: FontSource;
  heading2?: FontSource;
  heading3?: FontSource;
  sectionTitle?: FontSource;
  body1SemiBold?: FontSource;
  body1Medium?: FontSource;
  body1Regular: FontSource;
  body2Medium?: FontSource;
  body2Regular?: FontSource;
  small?: FontSource;
  extraSmall?: FontSource;
  icons: any;
};

export const useAppFonts = (fonts: FontOption) => {
  const [fontsLoaded, error] = useFonts(fonts);
  return {
    error,
    fontsLoaded,
  };
};
