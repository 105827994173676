import React from "react";
import { TouchableOpacityProps, TouchableOpacity } from "react-native";
import * as ImagePicker from "expo-image-picker";

import mime from "mime";
// import { Image as ImageCompressor } from "react-native-compressor";
import { showToast } from "../../Toast/Toast";
import { translate } from "../../../lang/lang";
import { manipulateAsync } from "expo-image-manipulator";

export const fetchImageFromUri = async (uri: string) => {
  const response = await fetch(uri);
  return response.blob();
};

export const ImageUpload = (
  props: TouchableOpacityProps & {
    onImageBlob: (
      formData: FormData,
      imageBase65: string | undefined,
      blob: Blob
    ) => void;
    camera?: boolean;
  }
) => {
  const loadLocalImage = (blob: Blob): Promise<string | undefined> => {
    return new Promise((resolve) => {
      const fileReaderInstance = new FileReader();
      fileReaderInstance.readAsDataURL(blob);
      fileReaderInstance.onload = () => {
        resolve(fileReaderInstance.result as string);
      };

      fileReaderInstance.onerror = () => {
        resolve(undefined);
      };
    });
  };

  const pickImage = async () => {
    const options = {
      mediaTypes: ImagePicker.MediaTypeOptions.Images,
      allowsEditing: true,
      quality: 1,
    } as ImagePicker.ImagePickerOptions;
    const result = await (props.camera
      ? ImagePicker.launchCameraAsync(options)
      : ImagePicker.launchImageLibraryAsync({
          mediaTypes: ImagePicker.MediaTypeOptions.Images,
          allowsEditing: true,
          quality: 1,
        }));

    return result;
  };

  const getImageFromFile = async () => {
    const imageFileLocation = await pickImage();
    if (imageFileLocation.canceled) {
      return;
    }

    imageFileLocation.assets;

    if (!imageFileLocation?.assets?.length) {
      showToast(translate("app.inputs.errorImage"));
      return;
    }

    const imageItem = imageFileLocation.assets[0];
    if (imageItem?.uri) {
      const imageUri = imageItem.uri;
      const blob = await fetchImageFromUri(imageItem.uri);

      const manipResult = await manipulateAsync(imageUri, [], { compress: 1 });

      // const result =  await ImageCompressor.compress(imageUri, {
      //   compressionMethod: "auto",
      // });

      const formData = new FormData();
      // formData.append("file", {
      //   uri: result,
      //   type: mime.getType(result) || "",
      //   name: result,
      // } as any);

      formData.append("file", {
        uri: manipResult.uri,
        type: mime.getType(manipResult.uri) || "",
        name: manipResult.uri,
      } as any);

      const imageBase64 = await loadLocalImage(blob);
      props.onImageBlob(formData, imageBase64, blob);
    }
  };

  return <TouchableOpacity {...props} onPress={getImageFromFile} />;
};
