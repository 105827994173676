import {
    RoutePriceVehicleV2,
    RouteVehicleValidation,
} from '@naus-code/naus-client-types';
import { BasketManagerPetDetails } from './bm.7.pet.details';
import { VehicleUpdateDetailsReq } from '../types/providers';

export class BasketManagerVehicleDetails extends BasketManagerPetDetails {
    //
    updateVehicleDetails = (options: VehicleUpdateDetailsReq) => {
        this._log('updateVehicleDetails');
        const vehicleData = this.vehicleGetVehRoutePrice(options.vehicleId);
        const updatedVehicle: RoutePriceVehicleV2 = {
            ...vehicleData,
        };

        this.dispatchBasketWrapper(undefined, (state) => {
            for (const fieldData of options.fieldData) {
                const { value } = fieldData;
                const fieldId = fieldData.fieldId as any;
                //@ts-expect-error
                if (vehicleData.extraPriceData?.[fieldId]) {
                    continue;
                }
                //@ts-expect-error
                updatedVehicle[fieldId] = value;
            }
            this.dispatchVehicle(updatedVehicle, {
                disablePriceReset: true,
                state,
                selectiveUpdate: {
                    tripDetails: true,
                },
            });
        });
    };

    extraVehicleDetailsFields = (): (keyof RouteVehicleValidation)[] => {
        const bmState = this.getBmState();
        const additionalValidation = bmState.routePrice.vehicleExtraValidation || {};

        const listThatCanBeAdded: (keyof RouteVehicleValidation)[] = ['width'];

        const remainingKeys = listThatCanBeAdded.filter(
            (key) =>
                !bmState.basketDataProcessed.vehicles.validation[key] &&
                !additionalValidation[key],
        );
        return remainingKeys;
    };

    enableVehicleDetail = (vehicleValidation: keyof RouteVehicleValidation) => {
        const bmState = this.getBmState();
        bmState.routePrice.vehicleExtraValidation = {
            ...bmState.routePrice.vehicleExtraValidation,
            [vehicleValidation]: true,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let vehicleIndex = 0;
                vehicleIndex < bmState.routePrice.vehicles.length;
                vehicleIndex++
            ) {
                const vehicle = bmState.routePrice.vehicles[vehicleIndex];
                this.dispatchVehicle(vehicle, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };

    disableVehicleDetail = (vehicleValidation: keyof RouteVehicleValidation) => {
        if (!vehicleValidation) {
            return;
        }
        const bmState = this.getBmState();
        bmState.routePrice.vehicleExtraValidation = {
            ...bmState.routePrice.vehicleExtraValidation,
            [vehicleValidation]: false,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let vehicleIndex = 0;
                vehicleIndex < bmState.routePrice.vehicles.length;
                vehicleIndex++
            ) {
                const vehicle = bmState.routePrice.vehicles[vehicleIndex];
                this.dispatchVehicle(vehicle, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };
}
