import * as Clipboard from 'expo-clipboard';
import { useEffect } from 'react';
import { isWeb, onlyUnique } from '../../../utils/utils.helper';

export const useListenToImageClipboard = ({
  onImagePaste,
}: {
  onImagePaste: (_: Clipboard.ClipboardImage) => void;
}) => {
  useEffect(() => {
    const unsubscribe = Clipboard.addClipboardListener((event) => {
      console.log(event.contentTypes);
      event.contentTypes.filter(onlyUnique).forEach(async (contentItem) => {
        switch (contentItem) {
          case Clipboard.ContentType.IMAGE: {
            const image = await Clipboard.getImageAsync({ format: 'jpeg' });
            if (image) {
              onImagePaste(image);
            }
            break;
          }

          default:
            break;
        }
      });
    });

    return unsubscribe.remove;
  }, []);

  useEffect(() => {
    if (isWeb) {
      const listener = async () => {
        const image = await Clipboard.getImageAsync({ format: 'jpeg' });
        if (image) {
          onImagePaste(image);
        }
      };
      document.addEventListener('paste', listener);
      return () => {
        document.removeEventListener('paste', listener);
      };
    }
  }, []);
};
