import { FormGroupItem } from '@droplet-tech-code/core-common-types';
import { useAppTheme } from '../../../theme';
import type { ChildrenProp, ViewStyle } from '../../../types/components';
import { HStack, VStack } from '../../Stack';
import { Text } from '../../Text';

export const LabelAsterisk = () => {
  return <Text.Small color="error-dark">*</Text.Small>;
};

export const BLANK_LABEL = '@b';

export interface FormInputContainerProps extends ChildrenProp {
  label?: string;
  disabled?: boolean;
  error?: boolean;
  feedback?: string;
  focused?: boolean;
  optional?: boolean;
  readonly?: boolean;
  noFeedback?: boolean;
  feedbackOnlyWithError?: boolean;
  style?: ViewStyle;

  rowProps?: FormGroupItem['itemProps'];
}

export const FormInputContainer = (props: FormInputContainerProps) => {
  const {
    children,
    label,
    disabled,
    error,
    focused,
    optional,
    readonly,
    style,
    rowProps,
  } = props;
  const { palette, components } = useAppTheme();
  const textStyle = [
    {
      color: palette.monochrome.mid,
    },
    error &&
      !focused &&
      !readonly && {
        color: palette.error.dark,
      },
    disabled &&
      !readonly && {
        color: palette.monochrome.extraLight,
      },
  ];

  return (
    <VStack space="1" style={style}>
      {label && !rowProps?.hideLabel ? (
        <HStack>
          <Text.Body2Regular
            style={[
              components.formInputLabel,
              textStyle,
              label === BLANK_LABEL && { opacity: 0 },
            ]}
            numberOfLines={1}
          >
            {label}
          </Text.Body2Regular>
          {optional || disabled || readonly ? null : <LabelAsterisk />}
        </HStack>
      ) : null}
      {children}
      {rowProps?.hideFeedback ? null : (
        <FeedbackText {...props} textStyle={textStyle} />
      )}
    </VStack>
  );
};

const FeedbackText = ({
  noFeedback,
  feedback,
  readonly,
  disabled,
  textStyle,
  feedbackOnlyWithError,
}: FormInputContainerProps & {
  textStyle: (
    | false
    | {
        color: string;
      }
    | undefined
  )[];
}) => {
  if (noFeedback) {
    return null;
  }

  const showFeedback = feedback && !disabled && !readonly;

  if (showFeedback) {
    return <Text.Body2Regular style={textStyle}>{feedback}</Text.Body2Regular>;
  }

  if (feedbackOnlyWithError) {
    return null;
  }

  return <Text.Body2Regular style={textStyle}> </Text.Body2Regular>;
};
