import { Pressable } from 'react-native';
import { FormHookProp } from '../Form';
import { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { useCreateListInput } from './CreateList.hook';
import { Modal, useModal } from '../../Modal';
import { BaseInput, BaseInputWrapper } from '../BaseInputs';
import { Text } from '../../Text';
import { FormInputContainer } from '../FormInputs';
import { translate } from '../../../lang/lang';
import { useState } from 'react';
import { HStack, VStack } from '../../Stack';
import { Button, IconButton } from '../../Button';
import { DropdownMenuList } from '../../Dropdown';
import { showToast } from '../../Toast';
import { View } from '../../View';

export interface CreateListInputProps
  extends CommonInputProps<string[], 'create-list'> {}
const modalKey = 'CreateListInput';

export const CreateList = (props: FormHookProp<string[], 'create-list'>) => {
  const { openModal, closeModal } = useModal();

  const {
    value,
    setValue,
    // isValid,
    feedback,
    error,
    // allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    // config,
    noFeedback,
  } = useCreateListInput(props);

  return (
    <FormInputContainer
      noFeedback={noFeedback}
      disabled={disabled}
      error={error}
      focused={false}
      feedback={feedback}
      label={label}
      optional={optional}
      readonly={readonly}
      rowProps={props.rowProps}
    >
      <Pressable
        disabled={disabled}
        onPress={() => {
          openModal(
            <CreateListModal
              initialValue={value}
              onSubmit={(value) => {
                setValue(value);
                closeModal(modalKey);
              }}
            />,
            {
              key: modalKey,
              type: 'pop-up',
            }
          );
        }}
      >
        <BaseInputWrapper
          iconEnd="plus"
          editable
          error={error}
          disabled={disabled}
        >
          <Text.Body2Regular style={{ flex: 1 }}>
            {value ? `[${value.length} items]` : '  '}
          </Text.Body2Regular>
        </BaseInputWrapper>
      </Pressable>
    </FormInputContainer>
  );
};

const CreateListModal = ({
  initialValue,
  onSubmit,
}: {
  initialValue?: string[];
  onSubmit: (list: string[] | undefined) => void;
}) => {
  const { closeModal } = useModal();
  const [value, setValue] = useState('');
  const [list, setList] = useState(initialValue);

  return (
    <>
      <Modal.Header
        title={translate('app.inputs.createList')}
        onPress={() => closeModal(modalKey)}
        divider
      />
      <Modal.Body p="3">
        <VStack space="3">
          <HStack align="center" space="1.5">
            <View flex={1}>
              <BaseInput value={value} onChangeText={(txt) => setValue(txt)} />
            </View>
            <Button
              fitToContent
              disabled={!value.trim()}
              onPress={() => {
                const newValue = value.trim();

                if (!list) {
                  setList([newValue]);
                  setValue('');
                  return;
                }

                if (newValue) {
                  const exists = list.some((item) => newValue === item);
                  if (exists) {
                    return showToast(
                      translate('app.inputs.valueExistsAlready')
                    );
                  }
                  setValue('');
                  setList([...list, newValue]);
                }
              }}
            >
              {translate('app.inputs.add')}
            </Button>
          </HStack>

          <View>
            <DropdownMenuList
              isClearable
              data={list || []}
              value=""
              renderItem={(listItem, iy) => {
                return (
                  <HStack flex={1}>
                    <Text.Body2Regular numberOfLines={1} style={{ flex: 1 }}>
                      {listItem}
                    </Text.Body2Regular>
                    <IconButton
                      color="error"
                      icon="close"
                      onPress={() => {
                        if (list) {
                          setList(list.filter((_, ix) => ix !== iy));
                        }
                      }}
                    />
                  </HStack>
                );
              }}
            />
          </View>
        </VStack>
      </Modal.Body>
      <Modal.Footer
        onConfirm={() => {
          onSubmit(list);
        }}
      />
    </>
  );
};
