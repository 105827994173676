import type { CommonInputProps } from '../Form/FormBuilder/FormBuilder.types';
import { FormInputContainer } from '../FormInputs/FormInput.Container';
import { LangTextSelector } from './LangSelector';
import { LangTextInputValue, useLangSelectorForm } from './LangSelector.hook';

export interface LangSelectorInputProps
  extends CommonInputProps<LangTextInputValue, 'lang-text'> {}

export const LangSelectorInput = (props: LangSelectorInputProps) => {
  const {
    value,
    setValue,
    feedback,
    error,
    allowEmpty,
    disabled,
    label,
    optional,
    readonly,
    noFeedback,
  } = useLangSelectorForm(props);

  return (
    <FormInputContainer
      noFeedback={noFeedback}
      disabled={disabled}
      error={error}
      focused={false}
      feedback={feedback}
      label={label}
      optional={optional}
      readonly={readonly}
      rowProps={props.rowProps}
    >
      <LangTextSelector
        options={props.config.list}
        error={error}
        value={value}
        onSubmit={(v) => {
          if (allowEmpty && v === undefined) {
            return setValue({});
          }
          setValue(v);
        }}
      />
    </FormInputContainer>
  );
};
