import type { FormHookProp } from '../Form/Form.types';
import { useInputForm } from '../Inputs.utils';

export const useToggleInput = (props: FormHookProp<boolean, 'toggle'>) => {
  return useInputForm({
    ...props,
    config: {
      ...props.config,
      optional: true,
    },
    test: () => true,
  });
};
