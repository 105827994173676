import { useState } from 'react';
import { Platform } from 'react-native';
import * as Clipboard from 'expo-clipboard';
import { showToast } from '../ui/Toast';
import { translate } from '../lang/lang';
import { getScreenWidth } from './utils.ui';
import isPlainObject from 'lodash/isPlainObject';

export const isAndroid = Platform.OS === 'android';
export const isIOS = Platform.OS === 'ios';
export const isWeb = Platform.OS === 'web';
export const isNativeMobile = Platform.OS !== 'web';
export const isMobile = isNativeMobile || getScreenWidth() <= 720;

export function notEmpty<t>(value: t | null | undefined): value is t {
  return value !== null && value !== undefined;
}

export function getKeysOfObject<T>(obj: T): (keyof T)[] {
  return Object.keys(obj as any) as (keyof T)[];
}

export function onlyUnique(value: string, index: number, self: string[]) {
  return self.indexOf(value) === index;
}

export function isObject(obj: any) {
  return obj !== undefined && obj !== null && obj.constructor == Object;
}

export function useForceUpdate() {
  const [value, setValue] = useState(0); // integer state
  return [value, () => setValue((value) => value + 1)] as const; // update state to force render
  // A function that increment 👆🏻 the previous state like here
  // is better than directly setting `setValue(value + 1)`
}

export function isJSON(error: any): error is object {
  return error !== null && typeof error === 'object' && !Array.isArray(error);
}

export const copyToClipboard = async (value: string) => {
  await Clipboard.setStringAsync(value).then(() => {
    showToast(translate('app.feedback.copiedToClipboard'));
  });
};

export function generateRandomString(length = 5) {
  let randomString = '';
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  for (let i = 0; i < length; i++) {
    randomString += possible.charAt(
      Math.floor(Math.random() * possible.length)
    );
  }
  return randomString;
}

export const createPause = (ms: number) =>
  new Promise((resolve) => setTimeout(resolve, ms));

export const deepMerge = (newObj: any, srcObj: any) => {
  const simpleMergeObj = { ...srcObj, ...newObj };
  const keys = Object.keys(simpleMergeObj);
  for (const key of keys) {
    if (
      isPlainObject(simpleMergeObj[key]) &&
      isPlainObject(newObj[key]) &&
      isPlainObject(srcObj[key])
    ) {
      simpleMergeObj[key] = deepMerge(newObj[key], srcObj[key]);
    }
  }
  return simpleMergeObj;
};
