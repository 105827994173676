import { useEffect, useRef } from 'react';
import { translate } from '../../../lang/lang';
import { useAppTheme } from '../../../theme';
import { IconButton } from '../../Button/Button.Icon';
import { InputButton } from '../../Button/Button.Input';
import { HStack, VStack } from '../../Stack';
import { Text } from '../../Text';
import { View } from '../../View';
import {
  BLANK_LABEL,
  FormInputContainer,
} from '../FormInputs/FormInput.Container';
import { useNestedInputForm } from './NestedInput.hook';
import {
  AddNestedItemButton,
  NestedInputPreviewComponent,
} from './NestedInput.Preview';
import type { NestedInputProps } from './NestedInput.types';
import { nestedFormSubmit } from './NestedInput.utils';
import dayjs from 'dayjs';

export const NestedInput = (formInputProps: NestedInputProps) => {
  const isFormValidRef = useRef(() => true as boolean);

  const {
    value,
    feedback,
    setValue,
    isValid,
    error,
    disabled,
    label,
    optional,
    readonly,
    config,
    noFeedback,
  } = useNestedInputForm({
    ...formInputProps,
    isFormValid: isFormValidRef,
  });

  const modalKey = config.id + config.preview.map((it) => it.id).join('-');
  const { components, spacing } = useAppTheme();
  const valueRef = useRef(value);

  useEffect(() => {
    valueRef.current = value as any[];
  }, [value]);

  const listOfKeys = useRef<string[]>([]);
  if (Array.isArray(value)) {
    listOfKeys.current = value.map(
      (_, ix) => listOfKeys.current[ix] || dayjs().valueOf() + '' + ix
    );
  }

  return (
    <FormInputContainer
      noFeedback={noFeedback}
      label={label}
      feedback={feedback}
      error={error}
      disabled={disabled}
      optional={optional}
      readonly={readonly}
      rowProps={formInputProps.rowProps}
    >
      <View
        style={[
          config.bordered && {
            borderRadius: components.textInput?.borderRadius,
            borderColor: components.textInput?.borderColor,
            borderWidth: components.textInput?.borderWidth ?? 1,
            padding: spacing[3],
          },
        ]}
      >
        {!value ? (
          readonly ? (
            <InputButton readonly>
              <Text.Body2Regular color="monochrome-light" style={{ flex: 1 }}>
                {translate('app.inputs.empty')}
              </Text.Body2Regular>
            </InputButton>
          ) : (
            <AddNestedItemButton
              parentForm={formInputProps.form}
              config={config}
              value={value}
              extensions={formInputProps.extensions}
              error={error}
              onSubmit={(v) => {
                const newValue = nestedFormSubmit(v, config);
                if (config.valueType === 'list') {
                  setValue(newValue ? [newValue] : newValue);
                } else {
                  setValue(newValue || undefined);
                }
                isValid({ showFeedback: true });
              }}
              modalKey={modalKey}
            />
          )
        ) : Array.isArray(value) ? (
          <VStack space="3">
            {value.map((valueItem, ix) => {
              return (
                <HStack key={listOfKeys.current[ix]} space="1">
                  <NestedInputPreviewComponent
                    parentForm={formInputProps.form}
                    config={config}
                    value={valueItem}
                    extensions={formInputProps.extensions}
                    error={error}
                    onSubmit={(newValue) => {
                      listOfKeys.current = [];

                      if (Array.isArray(valueRef.current)) {
                        setValue(
                          nestedFormSubmit(
                            valueRef.current.map((item, iy) => {
                              return iy === ix ? newValue : item;
                            }),
                            config
                          )
                        );
                      }
                    }}
                    modalKey={modalKey}
                  />
                  {config.readonly ? null : config.preview.length ? (
                    <IconButton
                      icon="close"
                      onPress={() => {
                        const newList = [...value];
                        newList.splice(ix, 1);
                        listOfKeys.current.splice(ix, 1);
                        setValue(newList.length > 0 ? newList : undefined);
                      }}
                      color="monochrome"
                      variant="plain"
                    />
                  ) : (
                    <FormInputContainer
                      label={BLANK_LABEL}
                      noFeedback={config.noFeedback}
                      optional
                    >
                      <IconButton
                        icon="close"
                        onPress={() => {
                          const newList = [...value];
                          newList.splice(ix, 1);

                          listOfKeys.current.splice(ix, 1);
                          setValue(newList.length > 0 ? newList : undefined);
                        }}
                        color="monochrome"
                        variant="plain"
                      />
                    </FormInputContainer>
                  )}
                </HStack>
              );
            })}
            {config.readonly ? null : (
              <HStack>
                <AddNestedItemButton
                  parentForm={formInputProps.form}
                  config={config}
                  value={value}
                  extensions={formInputProps.extensions}
                  error={error}
                  onSubmit={(v) => {
                    setValue(nestedFormSubmit([...value, v], config));
                  }}
                  modalKey={modalKey}
                />
                {value.length === 0 ? null : config.preview.length ? (
                  <IconButton outerStyle={{ opacity: 0 }} icon="close" />
                ) : (
                  <FormInputContainer
                    label={BLANK_LABEL}
                    optional
                    noFeedback={config.noFeedback}
                  >
                    <IconButton outerStyle={{ opacity: 0 }} icon="close" />
                  </FormInputContainer>
                )}
              </HStack>
            )}
          </VStack>
        ) : (
          <NestedInputPreviewComponent
            isFormValidRef={isFormValidRef}
            parentForm={formInputProps.form}
            config={config}
            value={value}
            extensions={formInputProps.extensions}
            error={error}
            onSubmit={(v) => {
              const newValue = nestedFormSubmit(v, config);
              if (config.valueType === 'list') {
                setValue(newValue ? [newValue] : newValue);
              } else {
                setValue(newValue || undefined);
              }
            }}
            readonly={config.readonly}
            modalKey={modalKey}
          />
        )}
      </View>
    </FormInputContainer>
  );
};
