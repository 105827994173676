interface ErrorBase {
    code: string;
    log?: string;
    data?: any;
    stack?: string;
    meta?: { [key: string]: number | string | undefined };
    statusCode?: number;
}

import { ErrorCodes } from './error.list';
interface CreateErrorProps {
    code: ErrorCodes;
    log?: string;
    data?: any;
    error?: Error | ErrorBase;
    meta?: { [key: string]: any };
}

const identifier = '@errI';

function __isPreError(err: any): err is PreError {
    return typeof err === 'object' && err?.[identifier] === identifier;
}

interface PreError {
    [identifier]: '@errI';
    stack?: string;
    meta?: { [key: string]: any };
}

function isCreateError(err: any): err is ErrorBase {
    return (
        typeof err === 'object' &&
        err.hasOwnProperty('client') &&
        err.hasOwnProperty('code')
    );
}

export function createError(createErrorObj: CreateErrorProps): ErrorBase {
    const { code, log, data, error, meta } = createErrorObj;
    if (isCreateError(error)) {
        return error;
    }
    if (__isPreError(createErrorObj)) {
        return {
            code,
            log,
            data,
            stack: createErrorObj.stack,
            meta: {
                ...meta,
                ...createErrorObj.meta,
            },
        };
    }

    const errorObj = error === undefined ? new Error() : serializeError(error);

    const errorGenerated: ErrorBase = {
        code,
        log,
        data,
        stack: errorObj?.stack,
        meta: {
            ...meta,
            errorObj: {
                ...errorObj,
                stack: undefined,
            },
        },
    };
    return errorGenerated;
}
const toJsonWasCalled = Symbol('.toJSON was called');

const toJSON = (from: any) => {
    from[toJsonWasCalled] = true;
    const json = from.toJSON();
    delete from[toJsonWasCalled];
    return json;
};

const commonProperties = [
    {
        property: 'name',
        enumerable: false,
    },
    {
        property: 'message',
        enumerable: false,
    },
    {
        property: 'stack',
        enumerable: false,
    },
    {
        property: 'code',
        enumerable: true,
    },
];

const destroyCircular = ({ from, seen, to_, forceEnumerable, maxDepth, depth }: any) => {
    const to = to_ || (Array.isArray(from) ? [] : {});

    seen.push(from);

    if (depth >= maxDepth) {
        return to;
    }

    if (typeof from.toJSON === 'function' && from[toJsonWasCalled] !== true) {
        return toJSON(from);
    }

    for (const [key, value] of Object.entries(from)) {
        if (value == null) {
            to[key] = null;
            continue;
        }
        // if (typeof Buffer === 'function' && Buffer.isBuffer(value)) {
        //     to[key] = '[object Buffer]';
        //     continue;
        // }

        if (typeof value === 'object' && typeof (value as any).pipe === 'function') {
            to[key] = '[object Stream]';
            continue;
        }

        if (typeof value === 'function') {
            continue;
        }

        if (!value || typeof value !== 'object') {
            to[key] = value;
            continue;
        }

        if (!seen.includes(from[key])) {
            depth++;

            to[key] = destroyCircular({
                from: from[key],
                seen: [...seen],
                forceEnumerable,
                maxDepth,
                depth,
            });
            continue;
        }

        to[key] = '[Circular]';
    }

    for (const { property, enumerable } of commonProperties) {
        if (typeof from[property] === 'string') {
            Object.defineProperty(to, property, {
                value: from[property],
                enumerable: forceEnumerable ? true : enumerable,
                configurable: true,
                writable: true,
            });
        }
    }

    return to;
};
function serializeError(value: any, options: { maxDepth?: number } = {}) {
    const { maxDepth = Number.POSITIVE_INFINITY } = options;

    if (typeof value === 'object' && value !== null) {
        return destroyCircular({
            from: value,
            seen: [],
            forceEnumerable: true,
            maxDepth,
            depth: 0,
        });
    }

    // People sometimes throw things besides Error objects…
    if (typeof value === 'function') {
        // `JSON.stringify()` discards functions. We do too, unless a function is thrown directly.
        return `[Function: ${value.name || 'anonymous'}]`;
    }

    return value;
}
