import { useEffect, useRef, useState } from 'react';

import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { QueryDefinition } from '@reduxjs/toolkit/dist/query';

import { useAppTheme } from '../../theme';
import { getKeysOfObject } from '../../utils/utils.helper';
import { IconButton } from '../Button/Button.Icon';
import { BaseInput, BaseInputProps } from '../Inputs/BaseInputs/BaseInput';
import { AxiosBaseQuery } from '../../utils/network';
import { translate } from '../../lang/lang';
import { ViewStyle } from '../../types/components';

interface SearchProps<T> {
  keyExtractor?: (item: SearchListItem<T>) => string;
  searchTextExtractor?: (item: SearchListItem<T>) => string;
  onSearch: (filteredData: SearchList<T>, ids?: string[]) => void;
  itemsToIgnore?: (item: SearchListItem<T>) => boolean;
  data: SearchList<T>;
  keysToIgnore?: (keyof SearchListItem<T>)[];
  thresholdDelay?: number;
  searchChars?: { and: string; or: string };
  deps?: any[];
  isClearable?: boolean;
  focusOnMount?: boolean;
}

type SearchList<T> = T extends any[]
  ? T
  : T[keyof T] extends any[]
  ? T[keyof T]
  : any[];
type SearchListItem<T> = SearchList<T>[0];

type IsNotArray<T, Key extends string> = T extends any[]
  ? Key
  : GetPathsToList<T, Key>;

export type GetPathsToList<T, PastKey> = {
  [Key in keyof T]: IsNotArray<
    T[Key],
    Key extends string
      ? PastKey extends string
        ? `${PastKey}.${Key}`
        : Key
      : ''
  >;
}[keyof T];

// type GetLastItemInDotSplit<
//   S,
//   D extends string
// > = S extends `${infer T}${D}${infer U}` ? GetLastItemInDotSplit<U, D> : S;

// type ExtractValueFromKey<T, ExtractKey> = {
//   [Key in keyof T]: Key extends ExtractKey
//     ? T[Key]
//     : ExtractValueFromKey<T[Key], ExtractKey>;
// }[keyof T];

// type ExtractListFromValue<T, K> = Extract<ExtractValueFromKey<T, K>, any[]>;

const extractNestedData = <T,>(data: T, key: string) => {
  return key.split('.').reduce((memo, nextKey) => {
    return memo[nextKey];
  }, data);
};

export type IsKeyToListRequired<T> = T extends any[]
  ? {
      keyToList?: never;
    }
  : {
      keyToList: GetPathsToList<T, void>;
    };

export type SearchStateProps<T, U> = {
  arg?: U;
  hook: UseQuery<QueryDefinition<U | void, AxiosBaseQuery, any, T, 'rootApi'>>;
  style?: ViewStyle;
  options?: {
    pollingInterval?: number;
    skip?: boolean;
    refetchOnReconnect?: boolean;
    refetchOnFocus?: boolean;
    refetchOnMountOrArgChange?: boolean | number;
  };
};

export const useSearchState = <T, U>({
  hook,
  arg,
  options,
  keyToList,
  style,
  searchTextExtractor,
}: SearchStateProps<T, U> & {
  searchTextExtractor?: (item: any) => string;
} & IsKeyToListRequired<T>) => {
  const { data, isLoading, isFetching, error, isError, isSuccess, refetch } =
    hook(arg, options);
  const [stateData, setStateData] = useState<any[]>([]);

  if (data && !Array.isArray(data) && !keyToList) {
    throw new Error(
      'You must put a key to point to the list you want search for using "keyToList" in: ' +
        Object.keys(data).join(',')
    );
  }
  const searchData = data
    ? keyToList
      ? extractNestedData(data, keyToList as any)
      : data
    : [];

  const Search = (
    <SearchBar
      inputContainerStyle={[{ backgroundColor: 'white' }, style]}
      data={searchData}
      deps={[searchData.length !== (stateData as any).length, isFetching]}
      onSearch={(filteredData) => {
        setStateData(filteredData);
      }}
      searchTextExtractor={searchTextExtractor}
    />
  );

  return {
    data,
    list: stateData,
    isLoading,
    isFetching,
    error,
    refetch,
    isError,
    isSuccess,
    Search,
  };
};

export function SearchBar<T>({
  data,
  deps,
  keyExtractor,
  searchTextExtractor,
  onSearch,
  itemsToIgnore,
  keysToIgnore,
  thresholdDelay = 350,
  isClearable = true,
  searchChars = { and: ',', or: ';' },
  ...props
}: SearchProps<T> & BaseInputProps) {
  const [text, setText] = useState<string>('');
  const { spacing } = useAppTheme();
  const ref = useRef(0);
  const onChange = (newText: string) => {
    if (!newText) {
      return onSearch(data, keyExtractor ? data.map(keyExtractor) : undefined);
    }

    const filteredData = data.filter((item) => {
      if (itemsToIgnore && itemsToIgnore(item)) {
        return true;
      }

      const indexableText = searchDefault(
        item,
        keysToIgnore,
        searchTextExtractor
      );

      // ['123', '456;5785;3455', '1233', '456;345'] or ['456;5785;3455']
      const andTexts = newText
        .split(searchChars.and) // ','
        .map((txt) => txt.trim().toLocaleLowerCase())
        .filter((txt) => txt !== '');

      const andCount = andTexts.reduce(
        (memo, nextAndTextWhichMayHaveAnOrChar) => {
          const orTexts = nextAndTextWhichMayHaveAnOrChar
            .split(searchChars.or)
            .map((txt) => txt.trim())
            .filter((txt) => txt !== '');

          const isOrSatisfied = orTexts.some((orText) =>
            indexableText.includes(orText)
          );

          const addition = isOrSatisfied ? 1 : 0;
          return addition + memo;
        },
        0
      );

      return andCount === andTexts.length;
    }) as SearchList<T>;

    onSearch(
      filteredData,
      keyExtractor ? filteredData.map(keyExtractor) : undefined
    );
  };

  useEffect(() => {
    clearTimeout(ref.current);
    ref.current = window.setTimeout(() => {
      onChange(text);
    }, thresholdDelay);
  }, [text]);

  useEffect(() => {
    clearTimeout(ref.current);
    onChange(text);
  }, deps ?? [data]);

  return (
    <BaseInput
      {...props}
      placeholder={translate('app.inputs.search')}
      iconStart="search"
      iconEnd={
        text && isClearable ? (
          <IconButton
            variant="plain"
            icon="close"
            color="monochrome"
            outerStyle={{ marginRight: -spacing[3] }}
            onPress={() => setText('')}
          />
        ) : undefined
      }
      inputContainerStyle={[
        {
          backgroundColor: 'white',
          borderRadius: 0,
          borderWidth: 0,
        },
        props.inputContainerStyle,
      ]}
      value={text}
      onChangeText={(text) => {
        setText(text);
      }}
    />
  );
}

function searchDefault<T>(
  item: SearchListItem<T>,
  keysToIgnore: (keyof SearchListItem<T>)[] | undefined,
  searchTextExtractor: ((item: SearchListItem<T>) => string) | undefined
) {
  const keys = getKeysOfObject(item);
  return String(
    keys
      .map((key) => {
        if (keysToIgnore ? keysToIgnore.indexOf(key) !== -1 : false) return '';
        return JSON.stringify(item[key]);
      })
      .join('') + (searchTextExtractor ? searchTextExtractor(item) : '')
  ).toLowerCase();
}
