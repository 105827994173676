import { translate } from '../../lang/lang';
import { useAppTheme } from '../../theme';
import { ChildrenProp } from '../../types/components';
import { isMobile, isWeb } from '../../utils/utils.helper';
import { Button, ButtonProps, IconButton } from '../Button';
import { Divider } from '../Divider/Divider';
import { HStack, StackProps, VStack } from '../Stack';
import { Text } from '../Text';
import { AppScrollViewProps, AppViewProps, ScrollView, View } from '../View';
import { ModalHeaderContainer, ModalHeaderProps } from './Modal.Header';

export interface ModalFooterProps extends ChildrenProp {
  onConfirm?: () => void;
  confirm?: string;
  cancel?: string;
  onCancel?: () => void;
  divider?: boolean;
  isLoading?: boolean;
  confirmProps?: Partial<Omit<ButtonProps, 'text'>>;
  cancelProps?: Partial<Omit<ButtonProps, 'text'>>;
}

const Modal = (props: AppViewProps) => <View {...props} />;

Modal.Container = (props: StackProps) => {
  const { spacing } = useAppTheme();
  return (
    <VStack
      {...props}
      style={[
        isWeb && {
          maxHeight: window.innerHeight - spacing[6],
          maxWidth: window.innerWidth - spacing[6],
        },
        isMobile && {
          flexGrow: 1,
        },
        props.style,
      ]}
    />
  );
};

Modal.Header = ({
  onPress,
  title,
  subtitle,
  children,
  divider,
}: ModalHeaderProps) => {
  const modalHeaderProps = useAppTheme().components.modalHeader;
  return (
    <>
      <ModalHeaderContainer>
        <IconButton icon="close" color="monochrome" onPress={onPress} />
        <VStack space="1" flex={1}>
          <Text.Body1Medium {...modalHeaderProps.title}>
            {title}
          </Text.Body1Medium>
          {subtitle ? (
            <Text.Body2Regular {...modalHeaderProps.subtitle} numberOfLines={1}>
              {subtitle}
            </Text.Body2Regular>
          ) : null}
        </VStack>
        {children}
      </ModalHeaderContainer>
      {divider ? <Divider /> : <View />}
    </>
  );
};

const ModalBody = (props: StackProps) => {
  const { modalBody } = useAppTheme().components;
  return (
    <VStack
      {...modalBody}
      {...props}
      bg={props.bg ?? modalBody?.bg ?? 'white'}
      style={[isMobile && { flexGrow: 1 }, modalBody?.style, props.style]}
    />
  );
};

Modal.Body = (
  props: StackProps & { scroll?: boolean; scrollProps?: AppScrollViewProps }
) => {
  if (props.scroll) {
    return (
      <ModalBody flex={1} {...props}>
        <ScrollView {...props.scrollProps}>{props.children}</ScrollView>
      </ModalBody>
    );
  }

  return <ModalBody {...props} />;
};

Modal.Footer = ({
  children,
  divider,
  onConfirm,
  confirm,
  confirmProps,
  cancelProps,
  cancel,
  onCancel,
  isLoading = false,
}: ModalFooterProps) => {
  const modalFooterProps = useAppTheme().components.modalFooter;
  return (
    <>
      {divider ? <Divider /> : <View />}
      <HStack {...modalFooterProps?.container}>
        <HStack flex={1}>{children}</HStack>
        {onCancel && !isLoading ? (
          <Button
            {...modalFooterProps?.cancel}
            {...cancelProps}
            onPress={onCancel}
          >
            {cancel ?? translate('app.inputs.cancel')}
          </Button>
        ) : null}
        {onConfirm ? (
          <Button
            isLoading={isLoading}
            {...modalFooterProps?.confirm}
            {...confirmProps}
            onPress={onConfirm}
          >
            {confirm ?? translate('app.inputs.confirm')}
          </Button>
        ) : null}
      </HStack>
    </>
  );
};

export { Modal };
