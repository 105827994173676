import { ErrorObject } from ".";
import { translate } from "../../lang/lang";

export const getFirebaseError = (error: any): ErrorObject => {
  switch (error?.code) {
    case "auth/weak-password": {
      return {
        code: error.code,
        message: error?.getReason?.() || translate("app.inputs.weakPassword"),
      };
    }
    case "auth/email-already-in-use": {
      return {
        code: error.code,
        message: translate("app.inputs.emailInUse"),
      };
    }

    case "auth/invalid-email": {
      return {
        code: error.code,
        message: translate("app.inputs.invalidEmail"),
      };
    }
    case "auth/user-not-found": {
      return {
        code: error.code,
        message: translate("app.inputs.invalidUser"),
      };
    }

    case "auth/user-disabled": {
      return {
        code: error.code,
        message: translate("app.inputs.disable"),
      };
    }

    case "auth/wrong-password": {
      return {
        code: error.code,
        message: translate("app.inputs.invalidPassword"),
      };
    }

    case "auth/invalid-verification-code": {
      return {
        code: error.code,
        message: translate("app.inputs.invalidVerificationCode"),
      };
    }
    case "auth/invalid-verification-id":
    case "auth/credential-already-in-use":
    case "auth/invalid-credential":
    case "auth/provider-already-linked": {
      return {
        code: error.code,
        message: translate("app.inputs.errorLogSupport"),
      };
    }

    default: {
      return error;
    }
  }
};
