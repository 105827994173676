import { translate } from "../../lang/lang";
import { Text } from "../Text";
import { Modal } from "./Modal.Components";

import { useModal } from "./Modal.Provider";

export const useModalVerify = () => {
  const ModalKey = new Date().valueOf() + "verify";
  const { openModal, closeModal } = useModal();

  const refCurrent = {
    resolve: (() => {}) as (value: boolean | PromiseLike<boolean>) => void,
  };

  return {
    verifyAction: async (props?: {
      title?: string;
      description?: string;
    }): Promise<boolean> => {
      return new Promise((res) => {
        refCurrent.resolve = res;
        openModal(
          <Modal.Container>
            <Modal.Header
              title={props?.title ?? translate("app.modal.verify")}
              divider
              onPress={() => {
                refCurrent.resolve(false);
                closeModal(ModalKey);
              }}
            />
            <Modal.Body
              style={{ minHeight: 100 }}
              justify="center"
              align="center"
              p="3"
            >
              <Text.Body1Regular align="center">
                {props?.description ?? translate("app.modal.areYouSure")}
              </Text.Body1Regular>
            </Modal.Body>
            <Modal.Footer
              divider
              onConfirm={() => {
                refCurrent.resolve(true);
                closeModal(ModalKey);
              }}
              onCancel={() => {
                refCurrent.resolve(false);
                closeModal(ModalKey);
              }}
            />
          </Modal.Container>,
          { key: ModalKey, type: "pop-up" }
        );
      });
    },
    cancelVerify: () => {
      refCurrent.resolve(false);
      closeModal(ModalKey);
    },
  };
};
