import * as Clipboard from "expo-clipboard";

import { getFileLink, getPublicFileLink } from "./UploadFile.View";
import { HStack } from "../Stack";
import { IconButton } from "../Button";
import { showToast } from "../Toast";
import { translate } from "../../lang/lang";
import { CreateFileProps } from "./UploadFiles.utils";

export const ShareButton = ({
  fileId,
  token,
  baseUrl,
  createFileHook,
}: {
  baseUrl: string;
  fileId: string;
  token?: string;
} & CreateFileProps) => {
  const [createFileToken, { isLoading }] = createFileHook();
  return (
    <HStack justify="flex-end">
      <IconButton
        isLoading={isLoading}
        icon="link"
        onPress={async () => {
          if (token) {
            await Clipboard.setStringAsync(getFileLink(baseUrl, token)).then(
              () => {
                showToast(translate("app.feedback.copiedToClipboard"));
              }
            );
            return;
          }

          const link = await getPublicFileLink(baseUrl, () => {
            return createFileToken(fileId);
          });

          if (!link) {
            return;
          }

          await Clipboard.setStringAsync(link).then(() => {
            showToast(translate("app.feedback.copiedToClipboard"));
          });
        }}
        color="monochrome"
      />
    </HStack>
  );
};
