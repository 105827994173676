import { useEffect, useState } from "react";
import { KeyboardAvoidingView, Modal, Pressable } from "react-native";
import { useAppTheme } from "../../theme";
import type { ChildrenProp, ViewStyle } from "../../types/components";
import { backHardwareListener } from "../../utils/utils.hardware";
import { isIOS, isNativeMobile, isWeb } from "../../utils/utils.helper";
import { getScreenHeight, getScreenWidth } from "../../utils/utils.ui";
import { IconButton } from "../Button";

import { View } from "../View";
import { DelayRender } from "../Delay";

export const DropdownMenu = (
  props: {
    visible: boolean;
    modalKey: string;
    onClose: () => void;
    height?: number;
    width?: number;
    fixedWidth?: number;
    style?: ViewStyle;
  } & ChildrenProp
) => {
  const { components, spacing, shading } = useAppTheme();
  const height = props.height || 300;
  const [dimensions, setDimensions] = useState({
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    width: props.width || 300,
  });

  useEffect(() => {
    backHardwareListener.subscribe({
      key: props.modalKey,
      oneTimeUse: true,
      handler: () => {
        props.onClose();
      },
      mode: "stack",
    });
  }, []);

  return (
    <View
      style={{
        height: 0,
        width: "100%",
        flex: 1,
        opacity: 0,
        backgroundColor: "transparent",
        zIndex: -1,
        position: "absolute",
        top: 0,
      }}
      ref={(ev) => {
        if (ev) {
          if (isWeb) {
            const boundingRec = (ev as any as Element).getBoundingClientRect();
            const offsetTop =
              (Number(components.textInput?.height) || 0) + spacing[1];
            const isWithin =
              getScreenHeight() - (boundingRec.top + height + 50) > 0;

            const bottomSide = boundingRec.top;
            const topSide = boundingRec.top - spacing[1] - height - offsetTop;
            const boundingTop = isWithin ? bottomSide : topSide;

            if (
              dimensions.top !== boundingTop ||
              dimensions.left !== boundingRec.left
            ) {
              setDimensions({
                top: boundingTop,
                left: boundingRec.left,
                bottom: boundingRec.bottom,
                right: boundingRec.right,
                width: boundingRec.width,
              });
            }
          }
        }
      }}
    >
      <Modal
        visible={props.visible}
        transparent
        statusBarTranslucent
        animationType={isWeb ? "none" : "slide"}
      >
        <Pressable
          style={{
            flex: 1,
            backgroundColor: "transparent",
          }}
          onPress={() => {
            props.onClose();
          }}
        />

        <View
          bg="white"
          br={components.buttonInput?.borderRadius}
          b={1}
          bc="monochrome-extraLight"
          style={[
            {
              position: "absolute",
              zIndex: 900,
              height,
              width: dimensions.width,
              overflow: "hidden",
            },
            isWeb && dimensions,
            isWeb && shading.light,
            isNativeMobile && {
              width: getScreenWidth(),
              height: "100%",
              borderRadius: 0,
              borderWidth: 0,
            },
            props.fixedWidth ? { width: props.fixedWidth } : undefined,
            props.style,
          ]}
        >
          <KeyboardAvoidingView
            style={{ flex: 1 }}
            behavior={isIOS ? "padding" : "height"}
          >
            {isNativeMobile ? (
              <View align="flex-end" px="3" pt="6">
                <IconButton
                  size="large"
                  icon="chevron-down"
                  color="monochrome"
                  onPress={() => props.onClose()}
                />
              </View>
            ) : null}
            <DelayRender noDelay={isWeb}>{props.children}</DelayRender>
          </KeyboardAvoidingView>
        </View>
      </Modal>
    </View>
  );
};
