import {
    KeyVal,
    Optional,
    RoutePriceVehicleDataV2,
    RoutePriceVehicleV2,
    RouteVehicleValidation,
    TripPriceVehicleAccommodationV2,
} from '@naus-code/naus-client-types';
import { BasketManagerPetTickets } from './bm.6.pet.2.pricing';
import {
    VehSelectDiscountReq,
    VehSelectDriverReq,
    VehicleDetailsUpdatePriceReq,
} from '../types/providers';

export class BasketManagerVehicleTickets extends BasketManagerPetTickets {
    //
    //---------------DRIVER---------------//
    selectVehicleDriver = (options: VehSelectDriverReq) => {
        const { routePrice } = this.getBmState();
        const vehicleData = this.vehicleGetVehRoutePrice(options.vehicleId);

        const vehicleReqPerTrip: KeyVal<TripPriceVehicleAccommodationV2> = {};

        for (let tripIndex = 0; tripIndex < routePrice.trips.length; tripIndex++) {
            const trip = routePrice.trips[tripIndex];
            const vehicleDataReq =
                routePrice.trips[tripIndex].vehicleAccReq[vehicleData.index];
            // const discountCode = vehicleDataReq.vehicleData.accDiscountCode;
            // const shouldKeepDiscount = discountCode
            //     ? !!this.managerState.tripDictionaries[trip.key].vehicleCatAccDic[
            //           vehicleDataReq.vehicleData.categoryId
            //       ].discountsDic[discountCode]
            //     : false;
            vehicleReqPerTrip[trip.key] = {
                ...vehicleDataReq,
                passengerId: options.passengerId,
                // vehicleData: {
                //     ...vehicleDataReq.vehicleData,
                // },
            };
        }

        this.dispatchVehicle(vehicleData, {
            vehicleReqPerTrip,
            selectiveUpdate: {
                pricing: true,
            },
        });
        this.dispatchPrices();
    };

    //---------------DISCOUNTS---------------//
    /**
     * Function to choose a discount for a vehicle.
     * If extra information is required such as the vehicle's loyalty number or other
     * discount data, the functions passed to callbacks will be triggered.
     *
     * @options_callbacks_onLoyaltyDataRequired props include verifyLoyalty which fetches server
     * for loyalty data, use it once user enters vehicle's loyalty number and then return the response
     * as the response for onLoyaltyDataRequired
     */
    selectVehicleDiscount = async (options: VehSelectDiscountReq) => {
        const bmState = this.getBmState();
        const vehicleData = this.vehicleGetVehRoutePrice(options.vehicleId);
        const tripDic = this.getTripDictionary(options.tripKey);

        let vehicleAccReq =
            bmState.routePrice.trips[tripDic.index].vehicleAccReq[vehicleData.index];
        const catAccDic = tripDic.vehicleCatAccDic[vehicleAccReq.vehicleData.category];

        const driverData = this.passengerGetPasRoutePrice(vehicleAccReq.passengerId);

        let driverAccReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[driverData.index];

        if (options.discountCode === this.STANDARD_FARE) {
            const vehicleReqPerTrip: KeyVal<TripPriceVehicleAccommodationV2> = {
                [options.tripKey]: {
                    ...vehicleAccReq,
                    vehicleData: {
                        ...vehicleAccReq.vehicleData,
                        discountCode: undefined,
                    },
                },
            };
            this.dispatchVehicle(vehicleData, {
                vehicleReqPerTrip,
                selectiveUpdate: {
                    pricing: true,
                },
            });
            this.dispatchPrices();
            return;
        }

        const discountDic = catAccDic.discountsDic[options.discountCode];
        if (!discountDic) {
            //TODO Discount not found
            console.log('DISCOUNT NOT FOUND');
            return;
        }

        //1.Check if requires loyalty
        const requiresLoyalty =
            discountDic.requiresLoyalty && !driverAccReq.passengerData.loyalty;

        if (requiresLoyalty) {
            const loyaltyRes = await options.callbacks.onLoyaltyDataRequired({
                verifyLoyalty: async (loyaltyNumber) =>
                    this.selectPassengerLoyalty({
                        passengerId: driverData.id,
                        tripKey: options.tripKey,
                        loyaltyNumber,
                    }),
            });
            this._log('loyaltyRes', loyaltyRes);
            if (!loyaltyRes || loyaltyRes.error) {
                return;
            }
            //Check if loyalty is now in bmstate
            driverAccReq =
                bmState.routePrice.trips[tripDic.index].passengerAccReq[driverData.index];
            const hasLoyalty = !!driverAccReq.passengerData.loyalty;
            if (!hasLoyalty) {
                return;
            }
            //Continue to setting discount
        }

        //2.Check if requires discount data
        const savedDiscountData =
            vehicleData.discountCode?.[tripDic.companyId]?.[discountDic.companyCode];
        const requiresDiscountData =
            discountDic.requiresData &&
            !discountDic.requiresLoyalty &&
            !savedDiscountData?.number;

        let discountData: string | undefined;
        if (requiresDiscountData) {
            discountData = await options.callbacks.onDiscountDataRequired();
            if (!discountData) {
                return;
            }
        }

        const vehicleReqPerTrip: KeyVal<TripPriceVehicleAccommodationV2> = {
            [options.tripKey]: {
                ...vehicleAccReq,
                vehicleData: {
                    ...vehicleAccReq.vehicleData,
                    discountCode: options.discountCode,
                    accDiscountData: discountData,
                },
            },
        };

        this.dispatchVehicle(vehicleData, {
            vehicleReqPerTrip,
            selectiveUpdate: {
                pricing: true,
            },
        });
        this.dispatchPrices();
    };

    // removeVehicleDiscount = (options: { vehicleId: string; tripKey: string }) => {
    //     const bmState = this.getBmState();
    //     const vehicleData = this.vehicleGetVehRoutePrice(options.vehicleId);
    //     const tripDic = this.getTripDictionary(options.tripKey);

    //     const vehicleAccReq =
    //         bmState.routePrice.trips[tripDic.index].vehicleAccReq[vehicleData.index];

    //     const vehicleReq: KeyVal<TripPriceVehicleAccommodationV2> = {
    //         [options.tripKey]: {
    //             ...vehicleAccReq,
    //             vehicleData: {
    //                 ...vehicleAccReq.vehicleData,
    //                 discountCode: undefined,
    //                 accDiscountData: undefined,
    //             },
    //         },
    //     };

    //     this.dispatchVehicle(vehicleData, {
    //         tripKeys: [options.tripKey],
    //         vehicleReqPerTrip: vehicleReq,
    //         selectiveUpdate: {
    //             pricing: true,
    //         },
    //     });
    //     this.dispatchPrices();
    // };

    //---------------DETAILS_PRICE---------------//

    private getRequiredVehicleExtraFields = (
        vehicleData: RoutePriceVehicleV2,
        validation: RouteVehicleValidation | undefined,
    ) => {
        const additionalInfo = this.vehicleGetAdditionalDetails(vehicleData, validation);
        return additionalInfo.fieldOptionsArray.filter((item) => item.required);
    };

    getVehicleDetailsForPrice = (vehicleId: string) => {
        const bmState = this.getBmState();
        const vehicleData = this.vehicleGetVehRoutePrice(vehicleId);

        let extraValidation: RouteVehicleValidation = {};

        for (const trip of bmState.routePrice.trips) {
            const vehicleReq = trip.vehicleAccReq[vehicleData.index];
            vehicleReq.vehicleData.category;
            const ticketAcc =
                bmState.tripDictionaries[trip.key].vehicleCatAccDic[
                    vehicleReq.vehicleData.category
                ].accommodationsDic[vehicleReq.accCode];
            if (!ticketAcc.extraVehValidation) {
                continue;
            }
            extraValidation = {
                ...extraValidation,
                ...ticketAcc.extraVehValidation,
            };
        }
        const additionalInfo = this.getRequiredVehicleExtraFields(
            vehicleData,
            extraValidation,
        );
        if (additionalInfo.length === 0) {
            return;
        }
        return {
            fieldInputs: additionalInfo,
        };
    };

    updateVehicleDetailsForPrice = (options: VehicleDetailsUpdatePriceReq) => {
        this._log('updateVehicleDetailsForPrice');
        const vehicleData = this.vehicleGetVehRoutePrice(options.vehicleId);

        const newExtraPriceData: Optional<RoutePriceVehicleDataV2> = {
            ...vehicleData.extraPriceData,
        };

        for (const fieldData of options.data) {
            (newExtraPriceData as any)[fieldData.fieldId] = fieldData.value;
        }

        const updatedVehicle: RoutePriceVehicleV2 = {
            ...vehicleData,
            extraPriceData: newExtraPriceData,
        };

        this.dispatchVehicle(updatedVehicle, {
            disablePriceReset: true,
            selectiveUpdate: {
                pricing: true,
            },
        });
    };
}
