import dayjs from 'dayjs';
import {
    CustomerClientPassenger,
    KeyVal,
    Optional,
    RoutePassengerAccommodationCabinClient,
    RoutePassengerAccommodationClient,
    RoutePassengerAccommodationSeatCategory,
    RoutePassengerAccommodationSeatClient,
    RoutePassengerValidation,
    RoutePricePassengerLoyaltyData,
    RoutePricePassengerV2,
    RouteTripDictionaryClient,
    TripPricePassengerAccommodationV2,
} from '@naus-code/naus-client-types';
import {
    AvailableDiscount,
    AvailableExtra,
    ClientBasketState,
    ClientStatePassengerTripDetail,
    PassengerListItem,
    SelectTicketEditPassengerCabinItem,
    SelectTicketEditPassengerItem,
    SelectTicketEditPassengerSeatingItem,
    SelectTicketPassengerItem,
    SelectedExtra,
    SelectedTicket,
    SelectedTicketEditPassengerLoyalty,
} from '../types/clientState';
import {
    existsIn,
    getKeysOfObject,
    mapArrayToKeyValue,
    mapKeyValueToArray,
    sortSmallestFirst,
} from '../utils/functions';
import { BasketManagerCommon } from './bm.2.common';
import { DispatchPassengerOptions } from '../types/providerCommon';
import {
    FieldInput,
    PassengerFieldNames,
} from '../types/clientState/2.tipDetails/clientState.fieldInputs';
import { createError } from '../errors';

export class BasketManagerPassengersDispatch extends BasketManagerCommon {
    //
    passengerGetSavedDictionaryNoThrow = (
        passengerId: string,
    ): CustomerClientPassenger | undefined => {
        const bmState = this.getBmState();
        return bmState.savedPassengersDic[passengerId];
    };

    passengerGetSavedDictionary = (passengerId: string): CustomerClientPassenger => {
        const bmState = this.getBmState();
        const passenger = bmState.savedPassengersDic[passengerId];
        if (!passenger) {
            throw createError({ code: 'SAVED_PASSENGER_NOT_FOUND' });
        }
        return passenger;
    };

    private getDispatchPassengerReqPerTrip = (
        passengerData: RoutePricePassengerV2,
        options?: DispatchPassengerOptions,
    ): KeyVal<TripPricePassengerAccommodationV2> => {
        const bmState = this.getBmState();
        return (
            options?.passengerReqPerTrip ??
            mapArrayToKeyValue(
                bmState.routePrice.trips,
                (trip) => trip.key,
                (trip) => trip.passengerAccReq[passengerData.index],
            )
        );
    };

    dispatchPassenger = (
        passengerData: RoutePricePassengerV2,
        options?: DispatchPassengerOptions,
    ) => {
        const bmState = this.getBmState();
        const passengerIndex = passengerData.index;

        const passengerReqPerTrip = this.getDispatchPassengerReqPerTrip(
            passengerData,
            options,
        );

        bmState.routePrice.passengers[passengerIndex] = passengerData;
        bmState.routePrice.trips.forEach((trip) => {
            const includeTrip = !!passengerReqPerTrip?.[trip.key];
            if (includeTrip) {
                trip.passengerAccReq[passengerIndex] = passengerReqPerTrip[trip.key];
            }
        });

        if (!options?.disablePriceReset) {
            this.resetRoutePriceManagerState();
        }

        if (!options?.disableErrorReset) {
            this.resetRoutePriceErrorState();
        }

        if (options?.resetExtraData) {
            const requiresExtraData = this.checkPassengerRequiresExtraData(passengerData);
            if (requiresExtraData.length === 0) {
                passengerData.extraPriceData = undefined;
            }
        }

        this.dispatchBasketWrapper(options?.state, (state) => {
            if (!options?.disablePriceReset) {
                this.resetPrices({ state });
            }
            this.setPasQuoteOptions(state, passengerData, options);
            this.setPasPricing(state, passengerData, options);
            this.setPasDetails(state, passengerData, options);
            this.validateState(state);
        });
        this.dispatchManager();
        return passengerData;
    };

    passengersRefresh = (options?: {
        passengerIdsToOmit?: string[];
        state?: ClientBasketState;
    }) => {
        const bmState = this.getBmState();
        const { routePrice } = bmState;
        this.dispatchBasketWrapper(options?.state, (state) => {
            for (
                let passengerIndex = 0;
                passengerIndex < routePrice.passengers.length;
                passengerIndex++
            ) {
                const passenger = routePrice.passengers[passengerIndex];
                if (existsIn(options?.passengerIdsToOmit, passenger.id)) {
                    continue;
                }
                const passengerData = routePrice.passengers[passengerIndex];
                const passengerReqPerTrip =
                    this.getDispatchPassengerReqPerTrip(passenger);

                for (
                    let tripIndex = 0;
                    tripIndex < routePrice.trips.length;
                    tripIndex++
                ) {
                    const trip = routePrice.trips[tripIndex];
                    const dictionary = this.getTripDictionary(trip.key);
                    const accReq = passengerReqPerTrip[trip.key];
                    const ticketAcc = dictionary.passengerAccDic[accReq.accCode];

                    //Check if accommodation was part of a whole cabin
                    const wholeCabin = ticketAcc.type === 'cabin' && ticketAcc.wholeUse;
                    const samePassengerTickets =
                        dictionary.tripValidation.samePassengerTickets;
                    const refreshAccommodations = wholeCabin || samePassengerTickets;
                    if (refreshAccommodations) {
                        const categoryKey = accReq.passengerData.catKey;
                        const category = dictionary.passengerCatAccDic[categoryKey];
                        passengerReqPerTrip[trip.key].accCode = category.defaultAcc.code;
                    }

                    //TODO Manage Extras for specific accommodations
                    // for (
                    //     let extraIndex = 0;
                    //     extraIndex < (accReq.extras || []).length;
                    //     extraIndex++
                    // ) {
                    //     const extra = accReq.extras![extraIndex];
                    //     const extraAcc =
                    //         tripDictionaries[trip.key].extraAccDic[extra.code];
                    //     if (
                    //         extraAcc.compatiblePassengerAccIds &&
                    //         !existsIn(extraAcc.compatiblePassengerAccIds, accReq.accCode)
                    //     ) {
                    //         passengerReqPerTrip[trip.key].extras!.splice(extraIndex, 1);
                    //     }
                    // }
                }
                this.dispatchPassenger(passengerData, {
                    passengerReqPerTrip,
                    state,
                });
            }
        });
    };

    //---------------QUOTE_OPTIONS---------------//

    private setPasQuoteOptions = (
        state: ClientBasketState,
        passengerData: RoutePricePassengerV2,
        options?: DispatchPassengerOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'quoteOptions')) {
            return;
        }
        const bmState = this.getBmState();
        const passengerItem = this.getQuotePassengerItem(passengerData);
        state.quoteOptions.passengers.list[passengerData.index] = passengerItem;
        state.quoteOptions.passengers.canAddPassenger =
            bmState.routePrice.passengers.length <
            bmState.basketDataProcessed.passengers.limit;
        state.quoteOptions.passengers.limit =
            bmState.basketDataProcessed.passengers.limit;
        //Vehicles
        //TODO add validation linked to vehicles
    };

    private getQuotePassengerItem = (
        passengerData: RoutePricePassengerV2,
        // options?: DispatchPassengerOptions,
    ): PassengerListItem => {
        return {
            passengerId: passengerData.id,
            index: passengerData.index,
            //
            label: this.passengerGetLabelOrAgeGroup(passengerData),
        };
    };

    //---------------PRICING---------------//

    private setPasPricing = (
        state: ClientBasketState,
        passengerData: RoutePricePassengerV2,
        options?: DispatchPassengerOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'pricing')) {
            return;
        }
        const bmState = this.getBmState();

        for (let tripIndex = 0; tripIndex < bmState.selectedTrips.length; tripIndex++) {
            const trip = bmState.selectedTrips[tripIndex];
            const tripDic = this.getTripDictionary(trip.key);
            const skipTrip =
                options?.passengerReqPerTrip && !options.passengerReqPerTrip?.[trip.key];
            if (skipTrip) {
                continue;
            }
            //Error
            state.pricing.trips[tripIndex].validation.error =
                bmState.routePrice.trips[tripIndex].error;

            const passengerItem = this.getPricingPassengerItem(
                passengerData,
                options,
                tripDic,
            );
            state.pricing.trips[tripIndex].passengers[passengerData.index] =
                passengerItem;
        }
    };

    private getPricingPassengerItem = (
        passengerData: RoutePricePassengerV2,
        options: DispatchPassengerOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ): SelectTicketPassengerItem => {
        const bmState = this.getBmState();
        const { selectedTicket, availableTickets } = this.getPricingPassengerTicket(
            passengerData,
            options,
            tripDic,
        );
        const { selectedDiscount, availableDiscounts } = this.getDiscounts(
            passengerData,
            tripDic,
        );

        const { selectedExtras, availableExtras } = this.getExtras(
            passengerData,
            tripDic,
        );
        const category = this.findPassengerCategory(tripDic, passengerData);
        const passengerItem: SelectTicketPassengerItem = {
            passengerId: passengerData.id,
            index: passengerData.index,
            category: {
                max: category.cat.maxAge,
                min: category.cat.minAge,
            },
            //
            tripIndex: tripDic.index,
            tripKey: tripDic.key,
            //
            label: this.passengerGetIndex(passengerData),
            ageLabel: this.passengerGetAgeGroupLabel(passengerData),
            description: this.passengerGetLabelOrAgeGroup(passengerData),
            //
            selectedTicket,
            availableTickets,
            //
            selectedDiscount,
            availableDiscounts,
            //
            loyalty: this.getLoyalty(passengerData, tripDic),
            //
            selectedExtras: selectedExtras,
            availableExtras: availableExtras,
            //
            hasExtraData: !!Object.keys(passengerData.extraPriceData || {}).length,
            requiresExtraData: false,
            //
            value: this.formatPrice(
                bmState.routePrice.trips[tripDic.index].accPrices[passengerData.id]
                    ?.total,
            ),
        };
        return passengerItem;
    };

    findPassengerCategory = (
        tripDic: RouteTripDictionaryClient,
        passengerData: RoutePricePassengerV2,
    ) => {
        //TODO convert age to time of travel age
        const passenger = this.passengerExtractData(passengerData);
        const minAge = passenger.minAge;
        const maxAge = passenger.maxAge;
        const tripCategory = tripDic.passengerCatAcc.find(
            (cat) => cat.cat.minAge <= minAge && maxAge <= cat.cat.maxAge,
        );
        if (!tripCategory) {
            throw createError({
                code: 'PASSENGER_TRIP_CAT_NOT_FOUND',
                data: {
                    passengerData,
                },
            });
        }
        return tripCategory;
    };

    passengerExtractData = (
        passengerData: RoutePricePassengerV2,
    ): RoutePricePassengerV2 => {
        return {
            ...passengerData,
            ...passengerData.extraPriceData,
            ...passengerData.loyaltyData,
            ...passengerData.residentData,
        };
    };

    private getPricingPassengerTicket = (
        passengerData: RoutePricePassengerV2,
        options: DispatchPassengerOptions | undefined,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();

        const passengerReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

        const accDic = tripDic.passengerAccDic[passengerReq.accCode];

        const selectedTicket: SelectedTicket = {
            code: accDic.code,
            //
            label: accDic.name,
            description: accDic.description,
            //
            customCancelPolicy: accDic.cancelPolicy,
            customModifyPolicy: accDic.modifyPolicy,
            nonRefundable: accDic.nonRefundable,
            nonAmendable: accDic.nonAmendable,
            //
            applyToOthers: this.passengerGetAppliesToOther(passengerData, tripDic),
        };

        const availableTickets = this.getPricingPassengerAvailableTickets(
            passengerData,
            options,
            tripDic,
            selectedTicket,
        );

        return { selectedTicket, availableTickets };
    };

    private getPricingPassengerAvailableTickets = (
        passengerData: RoutePricePassengerV2,
        _options: DispatchPassengerOptions | undefined,
        tripDic: RouteTripDictionaryClient,
        selectedTicket: SelectedTicket,
    ) => {
        const bmState = this.getBmState();
        const passengerReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];
        const availableTickets: SelectTicketEditPassengerItem[] = [];
        const accommodations =
            tripDic.passengerCatAccDic[passengerReq.passengerData.catKey].filteredAcc;

        const { seatCategories, cabinCapacities } = this.getAccGroups(
            accommodations,
            selectedTicket,
        );

        for (const seatGroup of seatCategories) {
            availableTickets.push({
                type: 'break',
                category: seatGroup.category,
                capacity: 1,
            });
            availableTickets.push(...seatGroup.seats);
        }

        for (const cabinGroup of cabinCapacities) {
            availableTickets.push({
                type: 'break',
                category: 'cabin',
                capacity: cabinGroup.capacity,
            });
            availableTickets.push(...cabinGroup.cabins);
        }
        return availableTickets;
    };

    private getAccGroups = (
        accommodations: RoutePassengerAccommodationClient[],
        selectedTicket: SelectedTicket,
    ) => {
        const seatCategoryMap: KeyVal<{
            category: RoutePassengerAccommodationSeatCategory;
            seats: SelectTicketEditPassengerSeatingItem[];
        }> = {};

        const cabinCapacityMap: KeyVal<{
            capacity: number;
            cabins: SelectTicketEditPassengerCabinItem[];
        }> = {};

        for (const acc of accommodations) {
            if (acc.type === 'seat') {
                if (!seatCategoryMap[acc.category]) {
                    seatCategoryMap[acc.category] = { category: acc.category, seats: [] };
                }
                const mappedAcc = this.mapSeatAccAvailableTicket(acc);
                if (mappedAcc.code === selectedTicket.code) {
                    mappedAcc.isSelected = true;
                    mappedAcc.showApplyToOtherPassengers = selectedTicket.applyToOthers;
                }
                seatCategoryMap[acc.category].seats.push(mappedAcc);
                continue;
            }
            if (!cabinCapacityMap[acc.capacity]) {
                cabinCapacityMap[acc.capacity] = { capacity: acc.capacity, cabins: [] };
            }
            const mappedAcc = this.passengerMapCabinAccAvailableTicket(acc);
            if (mappedAcc.code === selectedTicket.code) {
                mappedAcc.isSelected = true;
            }
            cabinCapacityMap[acc.capacity].cabins.push(mappedAcc);
        }
        return {
            seatCategories: mapKeyValueToArray(seatCategoryMap),
            cabinCapacities: mapKeyValueToArray(cabinCapacityMap).sort(
                sortSmallestFirst((item) => item.capacity),
            ),
        };
    };

    private mapSeatAccAvailableTicket = (
        acc: RoutePassengerAccommodationSeatClient,
    ): SelectTicketEditPassengerSeatingItem => {
        return {
            type: 'seat',
            code: acc.code,
            //
            isSelected: false,
            //
            label: acc.name,
            description: acc.description,
            //
            value: acc.price,
            //
            limitedAvailability: acc.avail < 20 ? acc.avail : undefined,
            //
            customCancelPolicy: acc.cancelPolicy,
            customModifyPolicy: acc.modifyPolicy,
            nonAmendable: acc.nonAmendable,
            nonRefundable: acc.nonRefundable,
            //
        };
    };

    passengerMapCabinAccAvailableTicket = (
        acc: RoutePassengerAccommodationCabinClient,
    ): SelectTicketEditPassengerCabinItem => {
        return {
            type: 'cabin',
            code: acc.code,
            isSelected: false,
            label: acc.name,
            description: acc.description,
            value: acc.price,
            limitedAvailability: acc.avail < 20 ? acc.avail : undefined,
            //
            cabinOptions: {
                wholeUse: acc.wholeUse,
                capacity: acc.capacity,
                specialNeeds: acc.specialNeeds,
                luxury: acc.luxury,
                window: acc.window,
                balcony: acc.balcony,
                facilities: acc.facilities,
                pet: acc.pet,
                pure: acc.pure,
                wc: acc.wc,
                basin: acc.basin,
                lateCheckout: acc.lateCheckout,
                bunk: acc.bunk,
            },
            //
            customCancelPolicy: acc.cancelPolicy,
            customModifyPolicy: acc.modifyPolicy,
            nonAmendable: acc.nonAmendable,
            nonRefundable: acc.nonRefundable,
        };
    };

    passengerGetAppliesToOther = (
        passengerData: RoutePricePassengerV2,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();

        if (bmState.routePrice.passengers.length < 2) {
            return false;
        }

        const canApply = bmState.routePrice.trips[tripDic.index].passengerAccReq.every(
            (pasReq, pIndex) => {
                if (pasReq.passengerData.passengerId === passengerData.id) {
                    return !pasReq.toggle;
                }

                if (!pasReq.toggle) {
                    return false;
                }

                const requiresExtraData =
                    tripDic.passengerAccDic[pasReq.accCode].extraPasValidation;
                if (!requiresExtraData) {
                    return true;
                }

                const otherPassengerData = bmState.routePrice.passengers[pIndex];
                const additionalFields = this.passengerGetAdditionalDetails(
                    otherPassengerData,
                    requiresExtraData,
                    {
                        isPricing: true,
                    },
                );
                const valid = this.validatePassengerDetails(
                    additionalFields.fieldOptionsArray,
                );
                if (!valid) {
                    return false;
                }
                return true;
            },
        );
        return canApply;
    };

    private checkPassengerRequiresExtraData = (passengerData: RoutePricePassengerV2) => {
        const bmState = this.getBmState();
        let extraValidation: RoutePassengerValidation = {};
        for (const trip of bmState.routePrice.trips) {
            const dictionary = this.getTripDictionary(trip.key);
            const passengerReq = trip.passengerAccReq[passengerData.index];
            const accDic = dictionary.passengerAccDic[passengerReq.accCode];
            if (accDic.extraPasValidation) {
                extraValidation = {
                    ...extraValidation,
                    ...accDic.extraPasValidation,
                };
            }
        }
        return getKeysOfObject(extraValidation);
    };

    private getDiscounts = (
        passengerData: RoutePricePassengerV2,
        tripDic: RouteTripDictionaryClient,
    ): {
        selectedDiscount?: AvailableDiscount;
        availableDiscounts: AvailableDiscount[];
    } => {
        const bmState = this.getBmState();
        const passengerReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

        const discounts =
            tripDic.passengerCatAccDic[passengerReq.passengerData.catKey].discounts;

        let selectedDiscount: AvailableDiscount | undefined = undefined;
        const availableDiscounts: AvailableDiscount[] = [];
        const defaultDiscount: AvailableDiscount = {
            code: this.STANDARD_FARE,
            //
            label: this.trns('app.ticketSelection.standard'),
            //
            isSelected: !passengerReq.passengerData.discountCode,
            isDisabled: false,
            isStandard: true,
        };
        if (defaultDiscount.isSelected) {
            selectedDiscount = defaultDiscount;
        }
        availableDiscounts.push(defaultDiscount);
        for (const discount of discounts) {
            const isSelected =
                passengerReq.passengerData.discountCode === discount.companyCode;
            const mappedDiscount: AvailableDiscount = {
                code: discount.companyCode,
                //
                label: discount.name,
                description: discount.description,
                //
                discountDataRequired: discount.requiresData,
                loyaltyDataRequired: discount.requiresLoyalty,
                //
                isSelected,
                isDisabled: false,
            };
            if (isSelected) {
                selectedDiscount = mappedDiscount;
            }
            availableDiscounts.push(mappedDiscount);
        }

        return { selectedDiscount, availableDiscounts };
    };

    private getLoyalty = (
        passengerData: RoutePricePassengerV2,
        tripDic: RouteTripDictionaryClient,
    ): SelectedTicketEditPassengerLoyalty | undefined => {
        const bmState = this.getBmState();
        const passengerReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];

        if (!tripDic.loyalty) {
            return undefined;
        }

        return {
            loyaltyImage: tripDic.loyalty.img,
            loyaltyName: tripDic.loyalty.scheme,
            loyaltyNumber: passengerReq.passengerData.loyalty,
        };
    };

    private getExtras = (
        passengerData: RoutePricePassengerV2,
        tripDic: RouteTripDictionaryClient,
    ) => {
        const bmState = this.getBmState();
        const passengerReq =
            bmState.routePrice.trips[tripDic.index].passengerAccReq[passengerData.index];
        const extras =
            tripDic.passengerCatAccDic[passengerReq.passengerData.catKey].extras ||
            tripDic.extraAcc;

        let selectedExtras: SelectedExtra[] = [];
        const availableExtras: AvailableExtra[] = [];

        for (const extra of extras) {
            //
            const extraReq = passengerReq.extras?.[extra.code];
            //
            const applyToOtherPassengers = extra.mode === 'multi' ? false : false;

            const currentQty = extraReq?.qty || 0;
            const mappedExtra: AvailableExtra = {
                code: extra.code,
                //
                label: extra.name,
                description: extra.description,
                mode: extra.mode,
                category: extra.category,
                //
                canAdd: extra.limit < currentQty,
                canRemove: currentQty > 0,
                qty: currentQty,
                //
                value: extra.value,
                //
                // isAcceptedByDefault?: boolean;
                // isUnsupported?: boolean;
                applyToOtherPassengers: applyToOtherPassengers,
                //
                isDisabled: false,
            };

            if (extraReq?.qty) {
                selectedExtras.push(mappedExtra);
            }

            availableExtras.push(mappedExtra);
        }
        return { selectedExtras, availableExtras };
    };

    //---------------TRIP_DETAILS---------------//

    private setPasDetails = (
        state: ClientBasketState,
        passengerData: RoutePricePassengerV2,
        options?: DispatchPassengerOptions,
    ) => {
        if (this.skipSelectiveUpdate(options?.selectiveUpdate, 'tripDetails')) {
            return;
        }
        const bmState = this.getBmState();
        const savedPassenger = this.passengerGetSavedDictionaryNoThrow(passengerData.id);

        const fullPassengerData = this.passengerExtractData(passengerData);

        const passengerDetail: ClientStatePassengerTripDetail = {
            passengerId: passengerData.id,
            details: {
                title: this.trns('app.passengerTicketSelection.passenger', {
                    count: passengerData.index + 1,
                }),
                subTitle: savedPassenger
                    ? this.passengerGetNameLabel(savedPassenger)!
                    : this.passengerGetAgeGroupLabel(fullPassengerData),
                complete: false,
            },
            name: this.generatePassengerFieldInput(passengerData, 'name', {}),
            lastName: this.generatePassengerFieldInput(passengerData, 'lastName', {}),
            sex: this.generatePassengerFieldInput(passengerData, 'sex', {}),
            //
        };

        const validation: RoutePassengerValidation = {
            ...bmState.basketDataProcessed.passengers.validation,
            ...bmState.routePrice.passengerExtraValidation,
        };

        const additional = this.passengerGetAdditionalDetails(passengerData, validation);

        const newPassengerDetails: ClientStatePassengerTripDetail = {
            ...passengerDetail,
            ...additional.passengerDetails,
        };

        if (validation.residentIdGr) {
            newPassengerDetails.residentIdGr = this.generatePassengerFieldInput(
                passengerData,
                'residentIdGr',
                {},
            );
        }

        const valid = this.validatePassengerDetails(additional.fieldOptionsArray);

        if (valid) {
            newPassengerDetails.details.complete = true;
        }

        state.details.passengers[passengerData.index] = newPassengerDetails;
    };

    private validatePassengerDetails = (fieldInputs: FieldInput[]) => {
        return fieldInputs.every((item) => !item.required);
    };

    // private getAgeRangeTextForPassenger = (
    //     passengerData: RoutePricePassengerV2,
    //     options?: {
    //         withoutSuffix?: boolean;
    //     },
    // ) => {
    //     if (passengerData.minAge === passengerData.maxAge) {
    //         return this.trns('app.passengerTicketSelection.yearsOld', {
    //             age: passengerData.minAge,
    //         });
    //     }

    //     const allAges =
    //         passengerData.maxAge === this.PAS_MAX_AGE && passengerData.minAge === 0;

    //     if (allAges) {
    //         if (options?.withoutSuffix) {
    //             return '0+';
    //         }

    //         return this.trns('app.passengerTicketSelection.allAges');
    //     }

    //     if (passengerData.maxAge === this.PAS_MAX_AGE) {
    //         if (options?.withoutSuffix) {
    //             return passengerData.minAge + '+';
    //         }

    //         return this.trns('app.passengerTicketSelection.yearsOld', {
    //             age: passengerData.minAge + '+',
    //         });
    //     }

    //     if (options?.withoutSuffix) {
    //         return `${passengerData.minAge} - ${passengerData.maxAge}`;
    //     }

    //     return this.trns('app.passengerTicketSelection.yearsOldRange', {
    //         minAge: passengerData.minAge,
    //         maxAge: passengerData.maxAge,
    //     });
    // };

    private getDataFromLoyalty = ({ loyaltyData }: RoutePricePassengerV2) => {
        if (!loyaltyData) {
            return undefined;
        }

        const loyaltyCompanies = Object.keys(loyaltyData);

        let overallLoyaltyData: RoutePricePassengerLoyaltyData = {};

        for (const company of loyaltyCompanies) {
            const companyLoyaltyData = loyaltyData[company];
            overallLoyaltyData = {
                ...overallLoyaltyData,
                ...companyLoyaltyData,
            };
        }
        return overallLoyaltyData;
    };

    private generatePassengerFieldInput = (
        passengerData: RoutePricePassengerV2,
        fieldId: PassengerFieldNames,
        options: {
            required?: boolean;
            canBeRemoved?: boolean;
            validation?: keyof RoutePassengerValidation;
            isPricing?: boolean;
        },
    ): FieldInput => {
        const savedPassenger = this.passengerGetSavedDictionaryNoThrow(passengerData.id);
        const extraPassengerData = passengerData.extraPriceData;
        const { required, validation, canBeRemoved, isPricing } = options;
        const { residentData } = passengerData;
        const loyaltyData = this.getDataFromLoyalty(passengerData);

        switch (fieldId) {
            //Passenger
            case 'name': {
                const value =
                    loyaltyData?.name ??
                    residentData?.name ??
                    extraPassengerData?.name ??
                    savedPassenger?.name ??
                    passengerData.name;
                return {
                    fieldId: 'name',
                    type: 'text',
                    label: this.trns('app.basketManager.firstName'),
                    value,
                    disabled:
                        !!extraPassengerData?.name ||
                        !!savedPassenger?.name ||
                        !!loyaltyData?.name,
                    valueFromLoyalty: !!loyaltyData?.name,
                    valueFromResident: !!residentData?.name,
                    valueFromSaved: !!savedPassenger?.name,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: !value,
                };
            }
            case 'lastName': {
                const value =
                    loyaltyData?.lastName ||
                    residentData?.lastName ||
                    extraPassengerData?.lastName ||
                    savedPassenger?.lastName ||
                    passengerData.lastName;
                return {
                    fieldId: 'lastName',
                    type: 'text',
                    label: this.trns('app.basketManager.lastName'),
                    value,
                    disabled: !!savedPassenger?.lastName || !!loyaltyData?.lastName,
                    valueFromLoyalty: !!loyaltyData?.lastName,
                    valueFromResident: !!residentData?.lastName,
                    valueFromSaved: !!savedPassenger?.lastName,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: !value,
                };
            }
            case 'sex': {
                const value =
                    loyaltyData?.sex ||
                    extraPassengerData?.sex ||
                    savedPassenger?.sex ||
                    passengerData.sex;
                return {
                    fieldId: 'sex',
                    type: 'list',
                    label: this.trns('app.basketManager.biologicalSex'),
                    labelShort: this.trns('app.basketManager.biologicalSex'),
                    labelHint: this.trns(
                        'app.passengerTicketSelection.biologicalSexInfo',
                    ),
                    value,
                    disabled:
                        (!!extraPassengerData?.sex && !isPricing) ||
                        !!savedPassenger?.sex ||
                        !!loyaltyData?.sex,
                    valueFromLoyalty: !!loyaltyData?.sex,
                    valueFromSaved: !!savedPassenger?.sex,
                    valueFromPricing:
                        !!extraPassengerData?.sex && !isPricing && !savedPassenger?.sex,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: !value,
                    listSelect: [
                        { id: 'M', text: this.trns('app.basketManager.male') },
                        { id: 'F', text: this.trns('app.basketManager.female') },
                    ],
                };
            }
            case 'nationality': {
                const value =
                    loyaltyData?.nationality ||
                    extraPassengerData?.nationality ||
                    savedPassenger?.nationality ||
                    passengerData.nationality;

                return {
                    fieldId: 'nationality',
                    type: 'list',
                    label: this.trns('app.basketManager.nationality'),
                    placeholder: this.trns('app.basketManager.selectCountry'),
                    value,
                    disabled:
                        (!!extraPassengerData?.nationality && !isPricing) ||
                        !!savedPassenger?.nationality ||
                        !!loyaltyData?.nationality,
                    valueFromLoyalty: !!loyaltyData?.nationality,
                    valueFromSaved: !!savedPassenger?.nationality,
                    valueFromPricing:
                        !!extraPassengerData?.nationality &&
                        !isPricing &&
                        !savedPassenger?.nationality,
                    //TODO Country list
                    listSelect: [],
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'birthPlace': {
                const value =
                    extraPassengerData?.birthPlace ||
                    savedPassenger?.birthPlace ||
                    passengerData.birthPlace;
                return {
                    fieldId: 'birthPlace',
                    type: 'list',
                    label: this.trns('app.basketManager.placeOfBirth'),
                    value,
                    disabled:
                        (!!extraPassengerData?.birthPlace && !isPricing) ||
                        !!savedPassenger?.birthPlace,
                    valueFromSaved: !!savedPassenger?.birthPlace,
                    valueFromPricing: !!extraPassengerData?.birthPlace && !isPricing,
                    //TODO Country list
                    listSelect: [],
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }

            case 'birthDate': {
                const value =
                    loyaltyData?.birthDate ||
                    extraPassengerData?.birthDate ||
                    savedPassenger?.birthDate ||
                    passengerData.birthDate;

                return {
                    fieldId: 'birthDate',
                    type: 'date',
                    label: this.trns('app.basketManager.birthDate', {
                        format: this.config.DATE_FORMAT_CLIENT,
                    }),
                    value,
                    clientDateFormat: this.config.DATE_FORMAT_CLIENT,
                    serverDateFormat: this.config.DATE_FORMAT_SERVER,
                    disabled:
                        (!!extraPassengerData?.birthDate && !isPricing) ||
                        !!savedPassenger?.birthDate ||
                        !!loyaltyData?.birthDate,
                    valueFromLoyalty: !!loyaltyData?.birthDate,
                    minDate:
                        passengerData.maxAge === this.PAS_MAX_AGE
                            ? undefined
                            : dayjs()
                                  .subtract(passengerData.maxAge + 1, 'years')
                                  .toJSON(),
                    maxDate: dayjs().subtract(passengerData.minAge, 'years').toJSON(),
                    minAge: passengerData.minAge,
                    maxAge:
                        passengerData.maxAge === this.PAS_MAX_AGE
                            ? undefined
                            : passengerData.maxAge,
                    valueFromSaved: !!savedPassenger?.birthDate,
                    valueFromPricing:
                        !!extraPassengerData?.birthDate &&
                        !isPricing &&
                        !savedPassenger?.birthDate,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'identityDocType': {
                const value =
                    extraPassengerData?.identityDocType ||
                    savedPassenger?.identityDocType ||
                    passengerData.identityDocType;
                return {
                    fieldId: 'identityDocType',
                    type: 'list',
                    label: this.trns('app.basketManager.documentType'),
                    value,
                    disabled:
                        (!!extraPassengerData?.identityDocType && !isPricing) ||
                        !!savedPassenger?.identityDocType,
                    valueFromSaved: !!savedPassenger?.identityDocType,
                    valueFromPricing:
                        !!extraPassengerData?.identityDocType &&
                        !isPricing &&
                        !savedPassenger?.identityDocType,
                    listSelect: [
                        {
                            id: 'passport',
                            text: this.trns('app.basketManager.passport'),
                        },
                        {
                            id: 'id',
                            text: this.trns('app.basketManager.id'),
                        },
                    ],
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'identityDocNum': {
                const value =
                    extraPassengerData?.identityDocNum ||
                    savedPassenger?.identityDocNum ||
                    passengerData.identityDocNum;
                return {
                    fieldId: 'identityDocNum',
                    type: 'text',
                    label: this.trns('app.basketManager.documentNumber'),
                    value,
                    disabled:
                        (!!extraPassengerData?.identityDocNum && !isPricing) ||
                        !!savedPassenger?.identityDocNum,
                    valueFromSaved: !!savedPassenger?.identityDocNum,
                    valueFromPricing:
                        !!extraPassengerData?.identityDocNum &&
                        !isPricing &&
                        !savedPassenger?.identityDocNum,

                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'identityDocExpire': {
                const value =
                    extraPassengerData?.identityDocExpire ||
                    savedPassenger?.identityDocExpire ||
                    passengerData.identityDocExpire;

                const clientDateFormat = this.config.DATE_FORMAT_CLIENT;
                const serverDateFormat = this.config.DATE_FORMAT_SERVER;

                return {
                    fieldId: 'identityDocExpire',
                    type: 'date',
                    label:
                        this.trns('app.basketManager.documentExpiryDate') +
                        ` (${clientDateFormat})`,
                    value,
                    disabled:
                        (!!extraPassengerData?.identityDocExpire && !isPricing) ||
                        !!savedPassenger?.identityDocExpire,
                    valueFromSaved: !!savedPassenger?.identityDocExpire,
                    valueFromPricing:
                        !!extraPassengerData?.identityDocExpire &&
                        !isPricing &&
                        !savedPassenger?.identityDocExpire,

                    clientDateFormat,
                    serverDateFormat,
                    minDate: undefined,
                    maxDate: undefined,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    required: required && !value,
                };
            }
            case 'residentIdGr': {
                const value =
                    extraPassengerData?.residentIdGr ||
                    savedPassenger?.residentIdGr ||
                    passengerData.residentIdGr;
                return {
                    fieldId: 'residentIdGr',
                    type: 'text',
                    label: this.trns('app.basketManager.greekResidentId'),
                    value,
                    disabled:
                        (!!extraPassengerData?.residentIdGr && !isPricing) ||
                        !!savedPassenger?.residentIdGr,
                    valueFromSaved: !!savedPassenger?.residentIdGr,
                    valueFromPricing:
                        !!extraPassengerData?.residentIdGr &&
                        !isPricing &&
                        !savedPassenger?.residentIdGr,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    optional: true,
                };
            }
            case 'specialNeeds': {
                const value =
                    loyaltyData?.specialNeeds ||
                    residentData?.specialNeeds ||
                    extraPassengerData?.specialNeeds ||
                    savedPassenger?.specialNeeds === undefined
                        ? undefined
                        : savedPassenger.specialNeeds;
                return {
                    fieldId: 'specialNeeds',
                    type: 'toggle',
                    label: this.trns('app.basketManager.specialNeeds'),
                    value,
                    disabled:
                        extraPassengerData?.specialNeeds !== undefined
                            ? extraPassengerData?.specialNeeds
                            : savedPassenger?.specialNeeds === undefined
                            ? false
                            : true,
                    valueFromLoyalty: !!loyaltyData?.specialNeeds,
                    valueFromResident: !!residentData?.specialNeeds,
                    valueFromSaved:
                        savedPassenger?.specialNeeds === undefined ? false : true,
                    valueFromPricing:
                        !!extraPassengerData?.specialNeeds &&
                        !savedPassenger?.specialNeeds,
                    passengerValidation: canBeRemoved ? validation : undefined,
                    canBeRemoved,
                    optional: true,
                };
            }
            default:
                throw '';
        }
    };

    passengerGetAdditionalDetails = (
        passengerData: RoutePricePassengerV2,
        validation: RoutePassengerValidation | undefined,
        options?: { isPricing?: boolean },
    ) => {
        if (!validation) {
            return {
                passengerDetails: {},
                fieldOptionsArray: [],
            };
        }
        const bmState = this.getBmState();
        const passengerExtraValidation =
            bmState.routePrice.passengerExtraValidation || {};

        const passengerDetails: Optional<ClientStatePassengerTripDetail> = {};

        const fieldOptionsArray: FieldInput[] = [];

        if (validation.gender) {
            const fieldInput = this.generatePassengerFieldInput(passengerData, 'sex', {
                required: true,
                canBeRemoved: passengerExtraValidation['gender'],
                ...options,
            });
            passengerDetails.sex = fieldInput;
            fieldOptionsArray.push(fieldInput);
        }

        if (validation.nationality) {
            const fieldInput = this.generatePassengerFieldInput(
                passengerData,
                'nationality',
                {
                    required: true,
                    canBeRemoved: passengerExtraValidation['nationality'],
                    ...options,
                },
            );
            passengerDetails.nationality = fieldInput;
            fieldOptionsArray.push(fieldInput);
        }
        if (validation.birthPlace) {
            const fieldInput = this.generatePassengerFieldInput(
                passengerData,
                'birthPlace',
                {
                    required: true,
                    canBeRemoved: passengerExtraValidation['birthPlace'],
                    ...options,
                },
            );
            passengerDetails.birthPlace = fieldInput;
            fieldOptionsArray.push(fieldInput);
        }
        if (validation.birthDate) {
            const fieldInput = this.generatePassengerFieldInput(
                passengerData,
                'birthDate',
                {
                    canBeRemoved: passengerExtraValidation['birthDate'],
                    required: true,
                    ...options,
                },
            );
            passengerDetails.birthDate = fieldInput;
            fieldOptionsArray.push(fieldInput);
        }
        if (validation.idOrPassportDoc) {
            const identityDocTypeFieldInput = this.generatePassengerFieldInput(
                passengerData,
                'identityDocType',
                {
                    canBeRemoved: passengerExtraValidation['idOrPassportDoc'],
                    required: true,
                    ...options,
                },
            );
            passengerDetails.identityDocType = identityDocTypeFieldInput;
            fieldOptionsArray.push(identityDocTypeFieldInput);
            const identityDocNumFieldInput = this.generatePassengerFieldInput(
                passengerData,
                'identityDocNum',
                {
                    canBeRemoved: passengerExtraValidation['idOrPassportDoc'],
                    required: true,
                    ...options,
                },
            );
            passengerDetails.identityDocNum = identityDocNumFieldInput;
            fieldOptionsArray.push(identityDocNumFieldInput);
            const identityDocExpireFieldInput = this.generatePassengerFieldInput(
                passengerData,
                'identityDocExpire',
                {
                    canBeRemoved: passengerExtraValidation['idOrPassportDoc'],
                    required: true,
                    ...options,
                },
            );
            passengerDetails.identityDocExpire = identityDocExpireFieldInput;
            fieldOptionsArray.push(identityDocExpireFieldInput);
        }
        if (validation.residentIdGr) {
            const fieldInput = this.generatePassengerFieldInput(
                passengerData,
                'residentIdGr',
                {
                    canBeRemoved: passengerExtraValidation['residentIdGr'],
                    required: true,
                    ...options,
                },
            );
            passengerDetails.residentIdGr = fieldInput;
            fieldOptionsArray.push(fieldInput);
        }
        return {
            passengerDetails,
            fieldOptionsArray,
        };
    };
}
