import { RoutePetValidation, RoutePricePetV2 } from '@naus-code/naus-client-types';
import { BasketManagerPassengerDetails } from './bm.7.pas.details';
import { PetUpdateDetailsReq } from '../types/providers';

export class BasketManagerPetDetails extends BasketManagerPassengerDetails {
    //

    updatePetDetails = (options: PetUpdateDetailsReq) => {
        this._log('updatePetDetails');
        const petData = this.petGetPetRoutePrice(options.petId);
        const updatedPet: RoutePricePetV2 = {
            ...petData,
        };

        this.dispatchBasketWrapper(undefined, (state) => {
            for (const fieldData of options.fieldData) {
                const { value } = fieldData;
                const fieldId = fieldData.fieldId as any;
                //@ts-expect-error
                if (petData.extraPriceData?.[fieldId]) {
                    continue;
                }
                //@ts-expect-error
                updatedPet[fieldId] = value;
            }
            this.dispatchPet(updatedPet, {
                disablePriceReset: true,
                state,
                selectiveUpdate: {
                    tripDetails: true,
                },
            });
        });
    };

    extraPetDetailsFields = (): (keyof RoutePetValidation)[] => {
        const bmState = this.getBmState();
        const additionalValidation = bmState.routePrice.petExtraValidation || {};

        const listThatCanBeAdded: (keyof RoutePetValidation)[] = [
            'passportOrMedicalDoc',
            'weight',
            'country',
        ];

        const remainingKeys = listThatCanBeAdded.filter(
            (key) =>
                !bmState.basketDataProcessed.pets.validation[key] &&
                !additionalValidation[key],
        );
        return remainingKeys;
    };

    enablePetDetail = (petValidation: keyof RoutePetValidation) => {
        const bmState = this.getBmState();
        bmState.routePrice.petExtraValidation = {
            ...bmState.routePrice.petExtraValidation,
            [petValidation]: true,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let petIndex = 0;
                petIndex < bmState.routePrice.pets.length;
                petIndex++
            ) {
                const pet = bmState.routePrice.pets[petIndex];
                this.dispatchPet(pet, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };

    disablePetDetail = (petValidation: keyof RoutePetValidation) => {
        if (!petValidation) {
            return;
        }
        const bmState = this.getBmState();
        bmState.routePrice.petExtraValidation = {
            ...bmState.routePrice.petExtraValidation,
            [petValidation]: false,
        };
        this.dispatchBasketWrapper(undefined, (state) => {
            for (
                let petIndex = 0;
                petIndex < bmState.routePrice.pets.length;
                petIndex++
            ) {
                const pet = bmState.routePrice.pets[petIndex];
                this.dispatchPet(pet, {
                    disablePriceReset: true,
                    state,
                    selectiveUpdate: {
                        tripDetails: true,
                    },
                });
            }
        });
    };
}
