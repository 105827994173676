import { useEffect, useRef, useState } from "react";
import { StyleSheet, TextInput, TextInputProps, TextStyle } from "react-native";
import { AppTheme, useAppTheme } from "../../../theme";
import type { ViewStyle } from "../../../types/components";
import { IconsId } from "../../Icon/Icon.map";
import { BaseInputWrapper } from "./BaseInput.Wrapper";

export interface BaseInputProps extends TextInputProps {
  iconStart?: IconsId | Omit<React.ReactNode, string>;
  iconEnd?: IconsId | Omit<React.ReactNode, string>;
  disabled?: boolean;
  error?: boolean;
  inputStyle?: TextStyle;
  inputContainerStyle?: ViewStyle;
  focusOnMount?: boolean;
}

const styles = StyleSheet.create<any>({
  focusWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.primary.dark,
  }),
  errorWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.error.dark,
  }),
  disabledWrapper: (theme: AppTheme) => ({
    borderColor: theme.palette.monochrome.extraLight,
    backgroundColor: theme.palette.monochrome.base,
  }),

  input: (theme: AppTheme) => ({
    ...theme.typography.body2Regular,
    flex: 1,
    color: theme.palette.black,
  }),
  // errorInput: (theme: AppTheme) => ({
  //   color: theme.palette.error.dark,
  // }),

  disableInput: (theme: AppTheme) => ({
    color: theme.palette.monochrome.extraLight,
  }),

  iconStartStyle: {
    marginRight: 10,
  },
  iconEndStyle: {
    marginLeft: 10,
  },
});

export const BaseInput = ({
  disabled = false,
  editable = true,
  inputStyle,
  inputContainerStyle,
  iconStart,
  iconEnd,
  error,
  focusOnMount,
  onBlur,
  onFocus,
  ...textProps
}: BaseInputProps) => {
  const theme = useAppTheme();
  const {
    palette,
    spacing,
    components: { textInput },
  } = theme;
  const inputRef = useRef<TextInput | null>();
  const [focused, setFocused] = useState(false);
  const disabledColor = palette.monochrome.extraLight;
  const placeholderColor = palette.monochrome.mid;

  useEffect(() => {
    if (focusOnMount) {
      inputRef?.current?.focus();
    }
  }, [focusOnMount]);

  return (
    <BaseInputWrapper
      focused={focused}
      editable={editable}
      error={error}
      iconEnd={iconEnd}
      iconStart={iconStart}
      inputContainerStyle={[
        inputContainerStyle,
        textProps.multiline &&
          typeof textProps.numberOfLines === "number" && {
            height: "auto",
            paddingVertical:
              textInput?.padding || textInput?.paddingVertical || spacing[4],
          },
      ]}
    >
      <TextInput
        {...textProps}
        ref={(e) => {
          inputRef.current = e;
        }}
        onFocus={(ev) => {
          setFocused(true);
          onFocus?.(ev);
        }}
        onBlur={(ev) => {
          setFocused(false);
          onBlur?.(ev);
        }}
        value={textProps.value || ""}
        style={[
          styles.input(theme),
          inputStyle,
          // error && styles.errorInput(theme),
          disabled && styles.disableInput(theme),
        ]}
        placeholderTextColor={
          disabled
            ? disabledColor
            : textProps.placeholderTextColor || placeholderColor
        }
        editable={disabled === false && editable === true}
      />
    </BaseInputWrapper>
  );
};
