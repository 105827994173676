import React, {
  createContext,
  ReactNode,
  useCallback,
  useContext,
  useRef,
  useState,
} from 'react';
import { backHardwareListener } from '../../utils/utils.hardware';
import { modalSelector } from './Modal.selector';
import type { ModalCreateOptions } from './Modal.types';

export const ModalContext = createContext({
  openModal: (_modal: ReactNode, _options: ModalCreateOptions) => {}, // adds modal to modal stack
  closeModal: (_key: string) => {}, // closes the topmost modal
  closeAll: () => {},
  isModalOpen: (_key: string) => false as boolean,
  getModals: (() => {}) as () => Modals,
  modals: [] as { key: string; modal: ReactNode }[],
});

type Modals = { key: string; modal: ReactNode }[];

export const ModalProvider = ({ children }: { children: ReactNode }) => {
  const [modals, setStateModals] = useState<Modals>([]);
  const modalRefs = useRef<Modals>([]);
  const setModals = useCallback((mds: Modals) => {
    modalRefs.current = mds;
    setStateModals(mds);
  }, []);

  const closeModal = useCallback((key?: string) => {
    if (key) {
      setModals(modalRefs.current.filter((item) => item.key !== key));
      return;
    }
    setModals(modalRefs.current.slice(0, -1));
  }, []);

  return (
    <ModalContext.Provider
      value={{
        openModal: (modalChild, options) => {
          const modal = modalSelector.selectModalBasedOnOptions(
            modalChild,
            closeModal,
            options
          );
          const exists = modalRefs.current.some(
            (item) => item.key === options.key
          );
          if (exists) {
            setModals(
              modalRefs.current.map((it) => {
                if (it.key === options.key) {
                  return { modal: modalChild, key: options.key };
                }
                return it;
              })
            );

            return;
          }
          backHardwareListener.subscribe({
            key: options.key,
            oneTimeUse: true,
            handler: () => {
              closeModal(options.key);
            },
            code: options?.onBackOption?.key,
            mode: options?.onBackOption?.mode || 'stack',
          });
          setModals([...modalRefs.current, { modal, key: options.key }]);
        },
        modals,
        getModals: () => {
          return modals;
        },
        isModalOpen: (key: string) =>
          modalRefs.current.some((modal) => modal.key === key),
        closeAll: () => {
          setModals([]);
        },
        closeModal,
      }}
    >
      {children}
      {modals.map(({ modal, key }) => (
        <React.Fragment key={key}>{modal}</React.Fragment>
      ))}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  return useContext(ModalContext);
};
