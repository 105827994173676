import { useEffect, useState } from "react";
import { translate } from "../../lang/lang";
import { isAndroid, isNativeMobile, isWeb } from "../../utils/utils.helper";
import { Modal, useModal } from "../Modal";
import { VStack } from "../Stack";
import { ShareButton } from "./UploadFile.Common";
import { Button } from "../Button";
import { isRTKErrorResponse } from "../../utils/error";
import {
  CreateFileProps,
  FileItemStored,
  getFileUrlPublic,
} from "./UploadFiles.utils";
import { FileViewerBody } from "../FileViewer/FileViewer";
import { UseQuery } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import { QueryDefinition } from "@reduxjs/toolkit/dist/query";
import { AxiosBaseQuery } from "../../utils/network";
import { ContentFeedbackHandler } from "../Feedback/Feedback.Handler";

const ModalKeyFile = "ModalKey-File";
const ModalKeyDownload = "ModalKey-Download";

export const getFileLink = (baseUrl: string, token: string) => {
  return `${baseUrl}${getFileUrlPublic(token)}`;
};

export const getPublicFileLink = async (
  baseUrl: string,
  action: () => Promise<
    | {
        data: {
          token: string;
        };
      }
    | {
        error: unknown;
      }
  >
): Promise<string | undefined> => {
  const response = await action();
  if (isRTKErrorResponse(response)) {
    return;
  }
  return getFileLink(baseUrl, response.data.token);
};

const FileDownLoader = ({
  file,
  url,
  baseUrl,
  createFileHook,
}: {
  file: FileItemStored;
  url: string;
  baseUrl: string;
} & CreateFileProps) => {
  const { closeModal } = useModal();

  return (
    <VStack>
      <Modal.Header
        title={file.name}
        onPress={() => {
          closeModal(ModalKeyDownload);
          closeModal(ModalKeyFile);
        }}
        divider
      >
        <ShareButton
          createFileHook={createFileHook}
          baseUrl={baseUrl}
          fileId={file.id}
        />
      </Modal.Header>
      <Modal.Body p="3">
        <Button
          iconEnd="arrow-down"
          onPress={() => {
            if (isWeb) {
              const a = document.createElement("a");
              document.body.appendChild(a);
              (a as any).style = "display: none";
              a.href = url;
              a.download = `${file.name}`;
              a.click();
              window.URL.revokeObjectURL(url);
            }

            closeModal(ModalKeyDownload);
            closeModal(ModalKeyFile);
          }}
        >
          {translate("app.feedback.download")}
        </Button>
      </Modal.Body>
    </VStack>
  );
};

export interface FileViewProps {
  file: FileItemStored;
  baseUrl: string;
  pdfLink: string;
  contentType: string;
}

export interface FileViewWithHookProps<T> extends CreateFileProps {
  file: FileItemStored;
  baseUrl: string;
  contentType?: string;
  pdfLink?: string;

  // getFileHook: (id: string) => ({ data: any, isLoading: boolean });
  getFileHook: UseQuery<
    QueryDefinition<string, AxiosBaseQuery, any, T, "rootApi">
  >;
}

const FileView = (props: FileViewProps) => {
  const { closeModal } = useModal();

  const [iFrameSource, setIframeSource] = useState<{
    type: "html" | "uri";
    value: string;
  }>({ type: "uri", value: "" });

  useEffect(() => {
    (async () => {
      if (isNativeMobile) {
        setIframeSource({ type: "uri", value: props.pdfLink });

        return;
      }

      // const buffer = Buffer.from(dataFile[0]);
      // const blob = new Blob([buffer], { type: props.contentType });
      // const url = window.URL.createObjectURL(blob);

      switch (props.contentType) {
        case "application/pdf": {
          const pdfUrl = isAndroid
            ? `http://docs.google.com/gview?embedded=true&url=${props.pdfLink}`
            : props.pdfLink;
          setIframeSource({ type: "uri", value: pdfUrl });
          break;
        }
        case "image/jpeg":
        case "image/jpg":
        case "image/svg+xml": {
          setIframeSource({
            type: "html",
            value: `
            <html style="height: 100%;"><head><meta name="viewport" content="width=device-width, minimum-scale=0.1"></head><body style="margin: auto;height: 100%;display: flex;align-items:center;justify-content: center;"><img style="display: block;-webkit-user-select: none;" src="${props.pdfLink}"></body></html>
            `,
          });
          break;
        }

        default: {
          break;
        }
      }
    })();
  }, [props.pdfLink]);

  return (
    <VStack flex={1}>
      <Modal.Header
        title="File"
        onPress={() => {
          closeModal(ModalKeyFile);
        }}
        divider
      />

      {iFrameSource ? (
        <FileViewerBody
          uri={iFrameSource.value}
          html={iFrameSource.type === "html" ? iFrameSource.value : undefined}
        />
      ) : null}
    </VStack>
  );
};

const FileViewWithGettingUrlHook = <T,>({
  file,
  baseUrl,
  getFileHook,
  createFileHook,
}: FileViewWithHookProps<T>) => {
  const { data: dataFile, isLoading } = getFileHook(file.id);
  const { closeModal, openModal } = useModal();
  const [createFileToken] = createFileHook();

  const [iFrameSource, setIframeSource] = useState<{
    type: "html" | "uri";
    value: string;
  }>({ type: "uri", value: "" });

  useEffect(() => {
    (async () => {
      if (dataFile) {
        if (isNativeMobile) {
          const link = await getPublicFileLink(baseUrl, () => {
            return createFileToken(file.id);
          });

          if (link) {
            setIframeSource({ type: "uri", value: link });
          }
          return;
        }

        const buffer = Buffer.from(dataFile[0]);
        const blob = new Blob([buffer], { type: file.contentType });
        const url = window.URL.createObjectURL(blob);

        switch (file.contentType) {
          case "application/pdf": {
            const pdfUrl = isAndroid
              ? `http://docs.google.com/gview?embedded=true&url=${url}`
              : url;
            setIframeSource({ type: "uri", value: pdfUrl });
            break;
          }
          case "image/jpeg":
          case "image/jpg":
          case "image/svg+xml": {
            setIframeSource({
              type: "html",
              value: `
              <html style="height: 100%;"><head><meta name="viewport" content="width=device-width, minimum-scale=0.1"></head><body style="margin: auto;height: 100%;display: flex;align-items:center;justify-content: center;"><img style="display: block;-webkit-user-select: none;" src="${url}"></body></html>
              `,
            });
            break;
          }

          default: {
            openModal(
              <FileDownLoader
                baseUrl={baseUrl}
                createFileHook={createFileHook}
                file={file}
                url={url}
              />,
              {
                key: ModalKeyDownload,
              }
            );
            break;
          }
        }
      } else {
        setIframeSource({ type: "uri", value: "" });
      }
    })();
  }, [dataFile, file.id]);

  return (
    <VStack flex={1}>
      <Modal.Header
        title={file.name}
        onPress={() => {
          closeModal(ModalKeyFile);
        }}
        divider
      >
        <ShareButton
          baseUrl={baseUrl}
          createFileHook={createFileHook}
          fileId={file.id}
        />
      </Modal.Header>

      {iFrameSource ? (
        <ContentFeedbackHandler
          data={dataFile}
          dataEmpty={!iFrameSource || !dataFile}
          isLoading={isLoading}
          feedback={translate("app.feedback.noFiles")}
        >
          <FileViewerBody
            uri={iFrameSource.value}
            html={iFrameSource.type === "html" ? iFrameSource.value : undefined}
          />
        </ContentFeedbackHandler>
      ) : null}
    </VStack>
  );
};

export const useFileViewer = () => {
  const { openModal, closeModal } = useModal();

  return {
    openModal: <T,>(props: FileViewWithHookProps<T> | FileViewProps) => {
      openModal(
        props.pdfLink ? (
          <FileView {...(props as FileViewProps)} />
        ) : (
          <FileViewWithGettingUrlHook
            {...(props as FileViewWithHookProps<T>)}
          />
        ),
        {
          key: ModalKeyFile,
          type: "action-sheet-full",
        }
      );
    },

    closeModal: () => {
      closeModal(ModalKeyFile);
    },
  };
};
