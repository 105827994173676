import { createError } from '../errors';
import { TariffSelectReq } from '../types/providers';
import { BasketManagerVehicleTickets } from './bm.6.veh.2.pricing';

export class BasketManagerTariffTickets extends BasketManagerVehicleTickets {
    //
    private getTripTariff = (options: TariffSelectReq) => {
        const tripDic = this.getTripDictionary(options.tripKey);
        const ticketAcc = tripDic.tripTariffsDic[options.tariffCode];
        if (!ticketAcc) {
            throw createError({ code: 'TARIFF_NOT_FOUND' });
        }
        return ticketAcc;
    };

    selectTariff = (options: TariffSelectReq) => {
        const bmState = this.getBmState();
        const tripDic = this.getTripDictionary(options.tripKey);

        this.getTripTariff(options);

        const tariffReq = bmState.routePrice.trips[tripDic.index].tariffReq;
        if (!tariffReq) {
            return;
        }

        this.dispatchTariff({
            tariffReqPerTrip: {
                [options.tripKey]: {
                    ...tariffReq,
                    tariffCode: options.tariffCode,
                },
            },
        });
        this.dispatchPrices();
    };
}
